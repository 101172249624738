import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { Box, Grid, Tooltip, Typography } from "@mui/material"
import "./scss/MissionDetails.scss"
import _ from "lodash"
import { MissionCardProgressBar } from "./MissionProgressBar"
import MissionTimeline from "./MissionTimeline"
import {
  capitalizeWords,
  colorMissionState,
  departArriveLogic,
  missionIcons,
  missionScheduleStatus,
  truncateText,
} from "./Utils"

const MissionDepartureDetails = ({ mission, taskcount, details = false, actions = [] }) => {
  return (
    <Box data-testid={`mission-card-${mission.load_id}`} className="mission-departure-details-v2">
      <div className="mission-departure-details__section-a">
        <Box
          data-testid="mission-icons"
          sx={{ display: "flex", alignItems: "center", gap: "5px", minHeight: !details ? "15px" : "0px" }}
        >
          {!details && missionIcons(mission)}
        </Box>
      </div>

      <div className="mission-departure-details__section-b">
        <Grid
          container
          direction="row"
          spacing={1}
          sx={{ justifyContent: "space-between", alignItems: "center", paddingTop: !details ? "0px" : "10px" }}
        >
          <Grid item data-testid="load_id">
            {details ? (
              <Tooltip title={mission?.load_id}>
                <Typography className="font-16">
                  <strong>{truncateText(mission?.load_id, 18)}</strong>
                </Typography>
              </Tooltip>
            ) : (
              <Tooltip title={mission?.load_id}>
                <Typography className="font-16">
                  <strong>{truncateText(mission?.load_id, 11)}</strong>
                </Typography>
              </Tooltip>
            )}
          </Grid>

          <Grid item>
            {!details && (
              <Box
                data-testid="completed-tasks"
                color=" #ffffff"
                sx={{
                  backgroundColor: "#a7a7a7",
                  height: 16,
                  width: 55,
                  borderRadius: 1,
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                <Tooltip title={mission?.vehicle_id}>
                  <strong>{truncateText(mission?.vehicle_id, 6)}</strong>
                </Tooltip>
              </Box>
            )}
          </Grid>

          <Grid item>
            <Typography className="font-16" data-testid="mission-state">
              {colorMissionState(mission?.state, !details)}
            </Typography>
          </Grid>

          <Grid item xs />

          <Grid item>
            <Typography data-testid="depart-arrive-label">{departArriveLogic(mission)}</Typography>
          </Grid>
        </Grid>
      </div>

      <div className="mission-departure-details__section-c">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ paddingRight: !details ? " 0px" : "9px", paddingBottom: !details ? "5px" : "0px" }}
        >
          <Grid item>
            <Typography className="font-16" sx={{ display: "flex", alignItems: "center" }}>
              {mission?.start_display ? capitalizeWords(mission.start_display) : `N/A`}
              <ArrowRightAltIcon fontSize="medium" />
              {mission?.destination_display ? capitalizeWords(mission.destination_display) : `N/A`}
            </Typography>
          </Grid>

          <Grid item xs />

          <Grid item>
            <Typography data-testid="mission-schedule-status">{missionScheduleStatus(mission)}</Typography>
          </Grid>
        </Grid>
      </div>
      <div className="mission-departure-details__section-d" data-testid="mission-progress-bar">
        {details && mission?.state != "ARRIVED" && (
          <Typography sx={{ fontSize: "11px", color: "#7f7f7f", paddingLeft: "10px" }}>
            {taskcount >= actions?.length ? "Completed" : "In Progress"}
          </Typography>
        )}
        {mission?.state != "ARRIVED" && mission?.state != "SCHEDULED" && (
          <Box
            data-testid="mission-timeline"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: ".1rem",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingBottom: ".2rem",
            }}
          >
            {!_.isEmpty(mission) && mission?.state != "IN_PROGRESS" && mission?.state != "ARRIVED" && (
              <MissionTimeline height={!details ? 8 : 10} borderRadius={8} details={details} actions={actions} />
            )}
            {!_.isEmpty(mission) && mission?.state == "IN_PROGRESS" && (
              <MissionCardProgressBar
                data-testid="mission-progress-bar"
                mission={mission}
                height={!details ? 8 : 10}
                details={details}
              />
            )}
          </Box>
        )}
      </div>
    </Box>
  )
}

export default MissionDepartureDetails
