import { useGetMissionByLoadId, useGetMissionByUuid } from "@/hooks/missionHooks"
import useGetActionsForMissionQuery from "@/hooks/query/actions/useGetActionsForMissionQuery"
import { useGetVehicle } from "@/hooks/vehiclesV2Hooks"
import {
  Box,
  Drawer,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material"
import _ from "lodash"
import { useMemo, useState } from "react"
import ActionDashboard from "./ActionDashboard"
import "./DepartureTasks.scss"
import useGetMeQuery from "@/hooks/query/user/useGetMeQuery"
import { canReviewTasks, displayRole } from "@/hooks/UserHooks"
import MissionVehicleAssignedBanner from "../../missions/MissionVehicleAssignedBanner"
import Task from "./Task"

/**
 *
 * @param {{
 *    activeMission?: import("@torc-robotics/mm-missions-client").Mission,
 *    drawerView?: boolean
 * }} props
 * @returns
 */
export default function DepartureTasks({ activeMission }) {
  const supportedActions = ["form", "review", "request", "response", "report"]
  const { data: userData } = useGetMeQuery()
  const mission = useGetMissionByLoadId() ?? activeMission
  const { data, isLoading, refetch } = useGetActionsForMissionQuery(mission?.uuid)
  const selectedVehicle = useGetVehicle()
  const vehicleCurrentMission = useGetMissionByUuid(selectedVehicle?.current_mission)?.load_id

  const inProgressTasks = useMemo(() => {
    if (!data?.actions) {
      return []
    } else if (!canReviewTasks(userData)) {
      return data?.actions?.filter(
        (action) => action?.state.toLowerCase() !== "completed" && action?.subtype.toLowerCase() !== "review",
      )
    } else {
      return data?.actions?.filter((action) => action?.state.toLowerCase() !== "completed")
    }
  }, [data])

  const completedTasks = useMemo(() => {
    if (!data?.actions) {
      return []
    } else {
      const completedActions = data?.actions?.filter((action) => action?.state.toLowerCase() === "completed")
      // threw error when only one completed action was returned
      if (completedActions.length === 1) return completedActions
      return completedActions.sort(function (a, b) {
        return new Date(b.last_updated) - new Date(a.last_updated)
      })
    }
  }, [data])

  const [taskFocus, setTaskFocus] = useState(null)

  const onClose = () => {
    refetch()
    setTaskFocus(null)
  }

  /**
   * Handler for clicking on an action, will open the action form if the action is a note.
   * @param {object} action - action object
   */
  const handleActionClick = (action) => {
    let actionType = _.get(action, "type", null)

    if (_.includes(supportedActions, actionType)) {
      setTaskFocus(action)
    } else {
      console.error(`Action type "${actionType}" not supported`)
    }
  }

  const getUserOrRole = (action) => {
    if (action?.state.toLowerCase() === "completed") {
      return _.get(action, "completed_by")
    }

    if (action?.state.toLowerCase() === "incomplete") {
      return _.get(action, "assigned_to")
    }

    return getActionRoles(action, userData)
  }

  const getActionRoles = (action, userData) => {
    return action?.roles?.map((r) => displayRole(r, userData))?.join(" ")
  }

  const isSmall = useMediaQuery((theme) => theme?.breakpoints?.down("md"))
  const isMedium = useMediaQuery((theme) => theme?.breakpoints?.down("lg"))

  const isVehicleOnOtherMission =
    vehicleCurrentMission && mission?.load_id && vehicleCurrentMission !== mission?.load_id

  return (
    <Box className="table__container" sx={{ border: "1px solid #d7d7d7" }}>
      {isVehicleOnOtherMission && (
        <MissionVehicleAssignedBanner
          vehicleId={selectedVehicle?.vehicle_id}
          loadId={vehicleCurrentMission}
          isV2={true}
        />
      )}
      {taskFocus && (
        <Drawer
          anchor="right"
          open={taskFocus}
          onClose={onClose}
          sx={{ zIndex: 1201 }}
          PaperProps={{ sx: { width: isSmall ? 1 : isMedium ? 0.75 : 0.5 } }}
          data-testid="task-drawer"
        >
          <ActionDashboard task={taskFocus} onClose={onClose} />
        </Drawer>
      )}
      {isLoading && <LinearProgress />}
      {!isLoading &&
        [inProgressTasks, completedTasks].map((actionGroup, idx) => (
          <div key={idx}>
            <Typography data-testid="tasks-table-label" variant="caption" className="table__label">
              {idx === 0 ? "" : "Completed Tasks"}
            </Typography>
            <Paper style={{ boxShadow: "none" }}>
              <Table
                data-testid={"tasks-table-" + (idx === 1 ? "complete" : "in_progress")}
                className={"table table--" + (idx === 1 ? "complete" : "in_progress")}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {actionGroup.length ? (
                      <></>
                    ) : (
                      <TableCell className="table__cell--empty">
                        <div className="table__label--empty">
                          <Typography fontSize={"12px"}>
                            There are no {idx === 1 ? "completed" : "pending"} actions.
                          </Typography>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionGroup.map((action) => {
                    return (
                      <Box className="box-row" data-testid={`tasks-table-row-${action.title}`} key={action.uuid}>
                        <Link
                          data-testid={`Open ${action.title}`}
                          name={`Open ${action.title}`}
                          onClick={() => handleActionClick(action)}
                          sx={{ textDecoration: "none" }}
                        >
                          <Task assignee={getUserOrRole(action)} title={action.title} state={action.state} />
                        </Link>
                      </Box>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
        ))}
    </Box>
  )
}
