import ClearIcon from "@mui/icons-material/Clear"
import InfoIcon from "@mui/icons-material/Info"
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import { useCallback, useState } from "react"
import "./MissionSearch.scss"
import useSearchStore from "@/store/useSearchStore"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import { BsFilter } from "react-icons/bs"

/**
 *
 * @param {object} props
 * @param {Record<string, import("@/store/useSearchStore").FilterGroup>} props.filterOptions
 * @param {({filter}: Record<'filter', Record<string, import("@/store/useSearchStore").FilterGroup>>)} props.updateFilter
 * @param {Record<string, string>} props.additionalFilters
 * @param {JSX.Element} props.children
 * @returns
 */
const MissionSearchFilters = ({
  anchorRef,
  filterOptions = {},
  updateFilter = () => {},
  additionalFilters = [],
  children,
}) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null)

  const updateMissionParams = useSearchStore((state) => state.updateMissionParams)

  const { date } = useSearchStore((state) => state.mission)

  const showFilterOptions = Boolean(filterAnchorEl)
  const handleFilterClick = () => {
    setFilterAnchorEl(anchorRef.current)
  }
  const handleFilterClose = () => {
    setFilterAnchorEl(null)
  }

  const handleClearFilters = useCallback(() => {
    // this is kinda janky, reset fn probably should be moved into the store or something
    updateMissionParams({ date: null })
    updateFilter({
      filter: Object.keys(filterOptions).reduce((groupAcc, groupLabel) => {
        groupAcc[groupLabel] = {
          filters: Object.keys(filterOptions[groupLabel].filters).reduce((filterAcc, filterLabel) => {
            filterAcc[filterLabel] = {
              checked: false,
              children: filterOptions[groupLabel].filters[filterLabel].children
                ? Object.keys(filterOptions[groupLabel].filters[filterLabel].children).reduce(
                    (childAcc, childLabel) => {
                      childAcc[childLabel] = { checked: false }
                      return childAcc
                    },
                    {},
                  )
                : null,
            }
            return filterAcc
          }, {}),
        }
        return groupAcc
      }, {}),
    })
  }, [filterOptions, updateFilter])

  const handleUncheckFilter = useCallback((data) => {
    if (data.childlabel) {
      const updatedFilter = {
        filter: {
          [data.groupLabel]: {
            filters: {
              [data.label]: {
                checked: false,
                children: {
                  [data.childlabel]: {
                    checked: false,
                  },
                },
              },
            },
          },
        },
      }
      updateFilter(updatedFilter)
    } else {
      const updatedFilter = {
        filter: {
          [data.groupLabel]: {
            filters: {
              [data.label]: {
                checked: false,
                children: {},
              },
            },
          },
        },
      }
      if (data.filter && data.filter.children) {
        Object.values(data.filter.children || {}).forEach((child) => {
          updatedFilter.filter[data.groupLabel].filters[data.label].children[child.label] = {
            checked: false,
          }
        })
      }
      updateFilter(updatedFilter)
    }
    // this is kinda janky, uncheck fn probably should be moved into the store or something
  }, [])

  const handleFilterChange = ({ checked, groupLabel, filter }) => {
    const updatedFilter = {
      filter: {
        [groupLabel]: {
          filters: {
            [filter.label]: {
              checked,
              children: {},
            },
          },
        },
      },
    }
    if (filter.children) {
      Object.values(filter.children || {}).forEach((child) => {
        updatedFilter.filter[groupLabel].filters[filter.label].children[child.label] = {
          checked,
        }
      })
    }
    updateFilter(updatedFilter)
  }

  const areAllChildrenChecked = (filter) => {
    if (filter.checked) return true

    if (filter.children && Object.keys(filter.children).length > 0) {
      return Object.values(filter.children).every((child) => child.checked)
    }
    return false
  }

  const handleChildFilterChange = ({ checked, label, groupLabel, filtername }) => {
    updateFilter({
      filter: {
        [groupLabel]: {
          filters: {
            [filtername]: {
              checked: false,
              children: {
                [label]: { checked },
              },
            },
          },
        },
      },
    })
  }
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const isMissionsV2Path = location.pathname === "/missions/v2"

  const chipsToDisplay = isMissionsV2Path ? 0 : isSmall ? 0 : isMedium ? 1 : 5

  const filtersArray = [
    ...(isMedium
      ? Object.values(additionalFilters).map((filter) => <Chip key={filter} label={filter} size="small" />)
      : []),
    ...Object.values(filterOptions).reduce((acc, filterOption) => {
      return acc.concat(
        Object.values(filterOption.filters)
          .flatMap((filter) => {
            if (filter.checked) {
              // Return the chip for the checked filter
              return (
                <Chip
                  key={filter.label}
                  label={filter.label}
                  size="small"
                  onDelete={() =>
                    handleUncheckFilter({
                      filter: filter,
                      label: filter.label,
                      groupLabel: filterOption.label,
                    })
                  }
                />
              )
            } else if (filter.children) {
              return Object.values(filter.children)
                .filter((child) => child.checked)
                .map((child) => (
                  <Chip
                    key={child.label}
                    label={child.label}
                    size="small"
                    onDelete={() =>
                      handleUncheckFilter({
                        childlabel: child.label,
                        label: filter.label,
                        groupLabel: filterOption.label,
                      })
                    }
                  />
                ))
            } else {
              return null
            }
          })
          .filter(Boolean),
      )
    }, []),
  ]
  const formattedDate = format(new Date(), "MM/dd/yyyy")

  return (
    <>
      {!isMedium && children}
      <Divider orientation="vertical" sx={{ height: "30px" }} />
      <div ref={anchorRef} className="searchbar__search-filter-chips">
        {filtersArray.slice(0, chipsToDisplay)}
        {filtersArray.length > 4 && !isMissionsV2Path ? (
          <Chip
            label={`+${filtersArray.length - 4}`}
            size="small"
            onClick={handleFilterClick}
            style={{ cursor: "pointer" }}
          />
        ) : (
          filtersArray.length > 0 && (
            <Chip
              label={`+${filtersArray.length}`}
              size="small"
              onClick={handleFilterClick}
              style={{ cursor: "pointer" }}
            />
          )
        )}
        {filtersArray.length > Object.values(additionalFilters).length && !isMissionsV2Path && (
          <IconButton size="small" onClick={handleClearFilters}>
            <ClearIcon />
          </IconButton>
        )}
      </div>
      <Button
        size="small"
        startIcon={<BsFilter />}
        disableElevation
        className="searchbar__filter-button"
        data-testid="filter-button"
        data-dd-action-name="filter-button"
        aria-controls="filter-menu"
        aria-haspopup="true"
        aria-expanded={showFilterOptions ? "true" : undefined}
        onClick={handleFilterClick}
      >
        Filters
      </Button>
      <Popover id="filter-menuv2" data-testid="filter-menu" open={showFilterOptions} onClose={handleFilterClose}>
        <div className="searchv2-filter-menu">
          {isMedium && children && <div className="searchv2-filter-menu__header--overflow">{children}</div>}

          {Object.keys(filterOptions).length ? (
            <>
              <div className="searchv2-filter-menu__header">
                <div className="searchv2-filter-menu__chips">{filtersArray}</div>
                <Button
                  size="small"
                  startIcon={<ClearIcon />}
                  onClick={handleClearFilters}
                  data-testid="clear-filters-button"
                  className="searchv2-filter-menu__clear-btn"
                >
                  clear all
                </Button>
              </div>
              <div className="searchv2-date__body">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="DATE"
                    shrink={true}
                    inputFormat="MM/dd/yyyy"
                    className="custom-component"
                    value={date && format(date, "MM/dd/yyyy")}
                    onChange={(newDate) => updateMissionParams({ date: newDate })}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        size="small"
                        variant="standard"
                        sx={{ width: "145px" }}
                        InputLabelProps={{ shrink: true }}
                        value={date && format(date, "MM/dd/yyyy") !== formattedDate ? format(date, "MM/dd/yyyy") : ""}
                        InputProps={{
                          ...props.InputProps,
                          endAdornment: (
                            <>
                              {date && format(date, "MM/dd/yyyy") !== formattedDate && (
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    aria-label="clear date"
                                    onClick={() => updateMissionParams({ date: null })}
                                    style={{ marginRight: "-15px" }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              {props.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <Box className="searchv2-filter-menu__body" sx={{ height: "30rem" }}>
                {Object.values(filterOptions).map((filterOption) => (
                  <div key={filterOption.label} className="searchv2-filter-menu__section">
                    <div>
                      {filterOption.label === "My Missions" ? (
                        <></>
                      ) : (
                        <Typography className="searchv2-filter-menu__section_header" variant="overline">
                          {filterOption.label}
                        </Typography>
                      )}
                      <span>
                        {filterOption.label === "Mission Status" && (
                          <Tooltip title="The default view does not show recently completed missions unless selected.">
                            <InfoIcon className="info-icon" fontSize="80" />
                          </Tooltip>
                        )}
                      </span>
                    </div>
                    {Object.values(filterOption.filters).map((filter) => (
                      <div key={filter.label}>
                        <FormControlLabel
                          className="searchv2-filter-menu__section_body"
                          data-testid={`${filterOption.label}-${filter.label}`}
                          key={filter.label}
                          onChange={(e) =>
                            handleFilterChange({
                              checked: e.target.checked,
                              value: filter.value,
                              label: filter.label,
                              groupLabel: filterOption.label,
                              filter: filter,
                            })
                          }
                          control={<Checkbox value={filter.value} />}
                          label={filter.label}
                          value={filter.value}
                          checked={areAllChildrenChecked(filter)}
                        />
                        {filter.children && (
                          <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                            {Object.values(filter.children).map((child) => (
                              <div key={child.label}>
                                <FormControlLabel
                                  className="searchv2-filter-menu__section_body"
                                  data-testid={`${filterOption.label}-${child.label}`}
                                  key={child.label}
                                  onChange={(e) =>
                                    handleChildFilterChange({
                                      checked: e.target.checked,
                                      value: child.value,
                                      label: child.label,
                                      groupLabel: filterOption.label,
                                      filtername: filter.label,
                                    })
                                  }
                                  control={<Checkbox value={child.value} />}
                                  label={child.label}
                                  checked={child.checked}
                                />
                              </div>
                            ))}
                          </Box>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
      </Popover>
    </>
  )
}

export default MissionSearchFilters
