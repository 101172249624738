import getToken from "@/framework/utils/api/getToken"
import { NotesApiClient } from "@torc-robotics/mm-notes-client"

/**
 * @deprecated Use `NotesApiV2` instead.
 */
export const NotesApi = new NotesApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_NOTES_API_URL,
  TOKEN: getToken,
}).v1

export const NotesApiV2 = new NotesApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_NOTES_API_URL,
  TOKEN: getToken,
}).v2
