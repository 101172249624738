import ErrorIcon from "@mui/icons-material/Error"
import { Typography } from "@mui/material"
import { getDepartureDate } from "../../../framework/utils/MissionDisplay"
import "./scss/MissionDetails.scss"
import { useGetVehicleConnectivity } from "@/hooks/vehiclesV2Hooks"
import { DateTime } from "luxon"
import ArrivingIcon from "../icons/ArrivingIcon"
import { IconTooltip } from "../icons/IconUtils"
import ScheduleStatusLabel from "../icons/ScheduleStatusIcon"

export function getScheduledArrival(mission) {
  var scheduled_arrive = DateTime.fromISO(mission?.tms_schedule_arrival_time)
  if (mission?.estimated_time) {
    scheduled_arrive = DateTime.fromISO(mission.estimated_time)
  }

  if (!scheduled_arrive.isValid) {
    return ""
  }

  return scheduled_arrive
}

export const missionIcons = (mission) => {
  const vehicleConnectivity = useGetVehicleConnectivity(mission?.vehicle_id)

  const iconStyles = (color, fontSize) => ({
    color,
    fontSize: fontSize || "1.3em",
    marginTop: "0.125em",
  })

  const alertConfigs = [
    {
      condition: () => vehicleConnectivity?.status == "disconnected",
      icon: (
        <IconTooltip title="Vehicle Disconnected">
          <ErrorIcon fontSize="medium" data-testid="error-icon" sx={iconStyles("#fa3751")} />
        </IconTooltip>
      ),
    },
    {
      condition: () => mission?.health == "ERROR",
      icon: (
        <IconTooltip title="Mission Health Error">
          <ErrorIcon fontSize="small" data-testid="mission-error-icon" sx={iconStyles("#fa3751")} />
        </IconTooltip>
      ),
    },
    {
      condition: () => vehicleConnectivity?.status == "partially_connected",
      icon: (
        <IconTooltip title="Vehicle Partially Connected">
          <ErrorIcon fontSize="small" data-testid="warn-icon" sx={iconStyles("#fa9a37")} />
        </IconTooltip>
      ),
    },
    {
      condition: () => mission?.health == "WARN",
      title: "Mission Health Warning",
      icon: (
        <IconTooltip placement="top" title="Mission Health Warning">
          <ErrorIcon fontSize="small" data-testid="mission-warn-icon" sx={iconStyles("#fa9a37")} />
        </IconTooltip>
      ),
    },

    {
      condition: () =>
        mission?.state === "IN_PROGRESS" &&
        getScheduledArrival(mission) &&
        getScheduledArrival(mission).diffNow("minutes").minutes < 30,
      title: "Arriving Icon",
      icon: (
        <ArrivingIcon
          width="17.5px"
          iconHeight="14px"
          data-testid="arriving-soon-icon"
          backgroundColor="#009afa"
          flagColor="#ffffff"
        />
      ),
    },
  ]

  return (
    <>
      {alertConfigs.map((config) => {
        if (config.condition()) {
          return config.icon
        }
        return null
      })}
    </>
  )
}

export const truncateText = (text, charsToTruncate = 12) => {
  if (text.length <= charsToTruncate) {
    return text
  }
  const firstPart = text.slice(0, charsToTruncate - 3)

  return `${firstPart}...`
}

export const departArriveLogic = (mission) => {
  // departure - ISO format
  var departure = getDepartureDate(mission, "")
  const scheduled_depart = DateTime.fromISO(departure)
  if (!scheduled_depart.isValid) {
    return ""
  }
  const minutes_till_depart = scheduled_depart.diffNow("minutes").minutes

  const scheduled_arrive = getScheduledArrival(mission)
  if (!scheduled_arrive) {
    return ""
  }
  const minutes_till_arrive = scheduled_arrive.diffNow("minutes").minutes

  if (minutes_till_depart > 30) {
    return `departs at ${scheduled_depart.toFormat("hh:mm ZZZZ")}`
  } else if (minutes_till_depart <= 30 && minutes_till_depart >= 0) {
    return `departs in ${scheduled_depart.toFormat("mm")}m`
  } else if (minutes_till_depart < 0 && minutes_till_arrive >= 0) {
    if (minutes_till_arrive > 30) {
      return `arrives at ${scheduled_arrive.toFormat("hh:mm ZZZZ")}`
    } else if (minutes_till_arrive >= 0 && minutes_till_arrive <= 30) {
      return `arrives in ${scheduled_arrive.toFormat("mm")}m`
    }
  } else if (mission?.state == "IN_PROGRESS" && minutes_till_arrive < 0) {
    // Still in progress but arrival time has passed
    return `estimated ${scheduled_arrive.toFormat("hh:mm ZZZZ")}`
  }

  return ""
}

export const missionScheduleStatus = (mission) => {
  ;["ARRIVED", "COMPLETED"].includes(mission?.state)
  if (
    !["ARRIVED", "COMPLETED"].includes(mission?.state) &&
    mission?.schedule_status &&
    mission?.schedule_status != "SCHEDULED"
  ) {
    switch (mission?.schedule_status) {
      case "ON_TIME":
        return (
          <Typography className="font-13" data-testid="on-time" color="#71bf5e" paddingRight="0.65vw">
            On time
          </Typography>
        )

      case "DELAYED":
        return <ScheduleStatusLabel data-testid="delayed-label" fillColor=" #fa9a37" text="Delayed" />

      case "AT_RISK":
        return <ScheduleStatusLabel data-testid="at-risk-label" fillColor=" #fa9a37" text="At-Risk" />

      case "STAGED":
        return <ScheduleStatusLabel data-testid="ready-label" fillColor=" #009afa" text="Ready" />

      default:
        return ""
    }
  } else {
    return ""
  }
}

export const colorMissionState = (state) => {
  switch (state) {
    case "SCHEDULED" || "PRE-DEPARTURE" || "STAGED" || "PENDING" || "PREPARING" || "REVIEWING" || "WAIT_ACCEPT":
      return <span style={{ color: "#aaaaaa" }}> {state}</span>

    case "IN_PROGRESS":
      return <span style={{ color: "#009afa" }}> IN-TRANSIT</span>

    case "ARRIVED" || "COMPLETED":
      return <span style={{ color: "#009afa" }}> {state}</span>

    case "CANCELLED":
      return <span style={{ color: "#009afa" }}> {state}</span>

    case "CLEARED":
      return <span style={{ color: "#71bf5e" }}> {state}</span>

    default:
      return ""
  }
}

export function capitalizeWords(text) {
  if (!text) return "N/A"

  return text
    .toLowerCase()
    .split(", ")
    .map((part, index) =>
      index === 0
        ? part
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : part.toUpperCase(),
    )
    .join(", ")
}

export function capitalizeFirst(text) {
  return String(text).charAt(0).toUpperCase() + String(text).slice(1)
}

export function emailToName(assignee) {
  if (!assignee) return ""

  const [namePart] = assignee.split("@")
  const [firstName, lastName] = namePart.split(".")

  let out = ""
  if (firstName) {
    out += capitalizeFirst(firstName)
  }
  if (lastName) {
    out += ` ${capitalizeFirst(lastName)}`
  }

  return out
}
