import { useGetDepartureRequestsWarning } from "@/hooks/missionHooks"
import useGetV2VehicleStatus from "@/hooks/query/vehicle/useGetV2VehicleStatus"
import { Box, Grid } from "@mui/material"
import _ from "lodash"
import { degToCompass, displayEta, normalizedDegrees } from "../../framework/Utils"
import { AutonomousIndicator } from "../vehicles/AutonomousIndicator"
import MissionAlert from "./MissionAlert"
import MissionInfoBox from "./MissionInfoBox"
import MissionProgressBar from "./MissionProgressBar"
import MissionTimeline from "./MissionTimeline"

const MissionVehicleDetailGrid = ({ mission, vehicle }) => {
  const departureRequests = useGetDepartureRequestsWarning(mission)
  const vehicleId = vehicle?.vehicle_id
  const { data: vehicleStatus } = useGetV2VehicleStatus(vehicleId)

  const odometer = Math.round(vehicleStatus?.odometer * 0.000621371)
  const fuel = Math.round(vehicleStatus?.fuel)
  const range = Math.round((fuel / 100) * 300 * 6)
  const speed = parseFloat(vehicleStatus?.mps * 2.23694).toFixed(0)
  const heading = vehicleStatus?.heading

  return (
    <>
      <div className="mission-details-grid__container">
        <Grid container direction="column" justifyContent="space-between" spacing={2}>
          <Grid
            container
            item
            xs={2}
            justifyContent="space-between"
            direction="row"
            alignItems="stretch"
            spacing={2}
            columns={12}
          >
            <Grid item xs={4}>
              <MissionInfoBox
                title="Heading"
                detail={degToCompass(heading)}
                subtext={!isNaN(heading) ? `${Math.round(normalizedDegrees(heading))}°` : "---"}
              />
            </Grid>
            <Grid item xs={4}>
              <MissionInfoBox title="Speed" detail={!isNaN(speed) ? speed : "---"} subtext="mph" />
            </Grid>
            <Grid item xs={4}>
              <MissionInfoBox
                title="Fuel/Range"
                detail={fuel ? fuel + "%" : "---"}
                subtext={!isNaN(range) ? `${range} miles` : "--- miles"}
              />
            </Grid>
            <Grid item xs={4}>
              <MissionInfoBox title="Odometer" detail={!isNaN(odometer) ? odometer : "---"} subtext="miles" />
            </Grid>
            <Grid item xs={4}>
              <MissionInfoBox
                className="mission-details-grid"
                title="ETA"
                detail={displayEta(mission) ? displayEta(mission) : "N/A"}
              />
            </Grid>
            <Grid item xs={4}>
              <MissionInfoBox
                className="mission-ads-mode"
                title="ADS Mode"
                detail={<AutonomousIndicator vehicleStatus={vehicleStatus} />}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ paddingBottom: "0" }}>
            {mission?.state != "ARRIVED" ? (
              <Box
                borderRadius={1.5}
                sx={{ backgroundColor: "white", display: "flex", overflowX: "auto", p: "1rem", gap: ".25rem" }}
              >
                {!_.isEmpty(mission) && mission?.state != "IN_PROGRESS" && mission?.state != "ARRIVED" ? (
                  <MissionTimeline hasAction={mission?.state == "WAIT_ACCEPT" ? departureRequests.hasAction : false} />
                ) : null}
                {!_.isEmpty(mission) && mission?.state == "IN_PROGRESS" ? (
                  <MissionProgressBar mission={mission} />
                ) : null}
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </div>
      <MissionAlert mission={mission} />
    </>
  )
}

export default MissionVehicleDetailGrid
