import React from "react"

const ScheduleStatusLabel = ({
  fillColor = "rgba(250, 154, 55, 1)",
  strokeColor = "rgba(121, 121, 121, 1)",
  text = "",
  ...props
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="69px"
    height="20px"
    viewBox="0 0 69 20"
    {...props}
  >
    <defs>
      <pattern id="BGPattern" patternUnits="userSpaceOnUse" />
      <mask fill="white" id="Clip654">
        <path
          d="M 10.999999999999998 10  L 0 0  L 69 0  L 69 20  L 0.09092646303844276 19.9999840755481  L 10.999999999999998 10  Z"
          fillRule="evenodd"
        />
      </mask>
    </defs>
    <g transform="matrix(1 0 0 1 -403 -179)">
      <path
        d="M 10.999999999999998 10  L 0 0  L 69 0  L 69 20  L 0.09092646303844276 19.9999840755481  L 10.999999999999998 10  Z"
        fillRule="nonzero"
        fill={fillColor}
        stroke="none"
        transform="matrix(1 0 0 1 403 179)"
        className="fill"
      />
      <path
        d="M 10.999999999999998 10  L 0 0  L 69 0  L 69 20  L 0.09092646303844276 19.9999840755481  L 10.999999999999998 10  Z"
        strokeWidth="0"
        strokeDasharray="0"
        stroke={strokeColor}
        fill="none"
        transform="matrix(1 0 0 1 403 179)"
        className="stroke"
        mask="url(#Clip654)"
      />
    </g>
    <text
      x="55%"
      y="55%"
      dominantBaseline="middle"
      textAnchor="middle"
      fill="rgba(255, 255, 255, 1)"
      fontSize="12"
      fontFamily="Roboto-regular, sans-serif"
    >
      {text}
    </text>
  </svg>
)

export default ScheduleStatusLabel
