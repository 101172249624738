export default function ConnectivityBanner({ connectivity, numMutations }) {
  if (connectivity === "offline") {
    return (
      <div className="connection-banner" data-testid="connection-banner">
        The device is having trouble connecting to your network. The application is currently in offline mode.
      </div>
    )
  } else {
    if (connectivity === "syncing") {
      return (
        <div className="connection-banner" data-testid="sync-banner">
          You are online again. Syncing {numMutations} offline changes.
        </div>
      )
    } else {
      return null
    }
  }
}
