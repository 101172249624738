/* istanbul ignore file */

import config from "@/config"
import useCenteredVehicle from "@/hooks/map/useCenteredVehicle"
import { useGetVehicleId } from "@/hooks/vehiclesV2Hooks"
import { Signer } from "@aws-amplify/core"
import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import _ from "lodash"
import maplibregl from "maplibre-gl"
import { useEffect, useState } from "react"
import Map, { FullscreenControl, Layer, NavigationControl, Source, useControl } from "react-map-gl"
import LoadScreen from "../../components/LoadScreen"
import { useGeoCreds } from "../../hooks/UserHooks"
import useMapStore from "../../store/useMapStore"
import HoverWrapper from "./hover/HoverWrapper"
import {
  dataLayerActual,
  dataLayerExpectedInside,
  dataLayerExpectedOuter,
  uvaldeFillStyle,
  uvaldeLineStyle,
} from "./mapStyle"
import "./scss/TrafficMap.scss"
import useVehiclesLayer from "@/hooks/map/layers/useVehiclesLayer"
import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import useGetMissionLocations from "@/hooks/query/routes/useGetMissionLocationsQuery"
import useGetMissionRouteLatLons from "@/hooks/query/routes/useGetMissionRouteLatLonsQuery"
import useVehicleStore from "@/store/useVehicleStore"
import { lineString } from "@turf/turf"
import useRouteEndLayer from "../tasks/reports/routes/useRouteEndLayer"
import useRouteStartLayer from "../tasks/reports/routes/useRouteStartLayer"
import uvaldeGeoJson from "./uvalde.geo.json"

// Sign AWS Request -- this is a copy until HERE Map tiles are put in place to ensure that the base map has some method of base tiles
const transformRequest = (creds) => (url, resourceType) => {
  if (resourceType === "Style" && url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1) {
    // resolve to an AWS URL for getting a style descriptor
    url = `${config.AWS_MAP_URL}`
  }
  // only sign AWS requests (with the signature as part of the query string)
  if (url.includes("amazonaws.com")) {
    return {
      url: Signer.signUrl(url, {
        access_key: creds.accessKeyId,
        secret_key: creds.secretAccessKey,
        session_token: creds.sessionToken,
      }),
    }
  }

  // don't sign non-AWS requests
  return { url: url || "" }
}

const initViewstate = {
  longitude: -98.5795,
  latitude: 39.8283,
  zoom: 4,
}

const TrafficMapVector = () => {
  const geoCreds = useGeoCreds()

  const mapRef = useMapStore((state) => state.mapRef)
  const hoverInfo = useMapStore((state) => state.hoverInfo)
  const breakCenter = useMapStore((state) => () => state.setIsCentered(false))

  const vehiclesLayer = useVehiclesLayer()
  const vehicleId = useGetVehicleId()
  const vehicle = useVehicleStore((state) =>
    Object.values(state.vehicles.entities)
      .flatMap((v) => v)
      .find((vehicle) => vehicle.vehicle_id === vehicleId),
  )

  const [locationsSinceLastMissionProgress, setLocationsSinceLastMissionProgress] = useState([])

  useMapResizeListener()
  useCenteredVehicle()

  const [mapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    if (mapRef.current && !vehicle?.data?.coordinates?.longitude && !vehicle?.data?.coordinates?.latitude) {
      mapRef.current.jumpTo({ ...initViewstate })
    }

    if (mapRef.current && !!vehicle?.data?.coordinates?.longitude && !!vehicle?.data?.coordinates?.latitude) {
      mapRef.current.jumpTo({
        center: [vehicle?.data?.coordinates?.longitude, vehicle?.data?.coordinates?.latitude],
        zoom: 14,
      })
    }
  }, [mapLoaded, vehicleId])

  const missionInFocus = useGetMissionByLoadId()
  const { data: missionProgressLatLons = [] } = useGetMissionLocations(missionInFocus?.uuid)
  const { data: expectedLatLons = [] } = useGetMissionRouteLatLons(missionInFocus?.uuid)

  useEffect(() => {
    setLocationsSinceLastMissionProgress((prev) =>
      missionInFocus?.state === "IN_PROGRESS"
        ? [
            [vehicle?.data?.coordinates?.longitude, vehicle?.data?.coordinates?.latitude, vehicle?.data?.timestamp],
            ...prev,
          ].filter(([lon, lat, timestamp]) => lon && lat && timestamp > missionProgressLatLons?.[0]?.timestamp)
        : prev,
    )
  }, [vehicle?.data?.coordinates?.longitude, vehicle?.data?.coordinates?.latitude, missionInFocus?.state])

  const actualRoute = [
    ...locationsSinceLastMissionProgress,
    ...missionProgressLatLons.map((coords) => [coords.lon, coords.lat]),
  ]
  const expectedRoute = [...expectedLatLons.map((coords) => [coords.lon, coords.lat])]

  const actualPath = _.size(actualRoute) >= 2 ? lineString(actualRoute) : null
  const expectedPath = _.size(expectedRoute) >= 2 ? lineString(expectedRoute) : null

  const startIndicatorLayer = _.size(expectedRoute) >= 2 ? useRouteStartLayer(expectedRoute?.[0]) : null
  const endIndicatorLayer =
    _.size(expectedRoute) >= 2 ? useRouteEndLayer(expectedRoute?.[expectedRoute.length - 1]) : null

  return geoCreds ? (
    <>
      <Map
        ref={mapRef}
        id="map"
        style={{
          transition: "opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
          opacity: mapLoaded ? 1 : 0,
          filter: mapLoaded ? "blur(0px)" : "blur(100px)",
          pointerEvents: mapLoaded ? "auto" : "none",
        }}
        dragRotate={true}
        touchRotate={false}
        initialViewState={initViewstate}
        mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
        mapLib={maplibregl}
        transformRequest={transformRequest(geoCreds)}
        onDrag={breakCenter}
        onLoad={() => {
          setMapLoaded(true)
        }}
      >
        <>
          <div className="map-options">
            <FullscreenControl className="map-nav-btn" data-testid="map-fullscreen-button" />
            <NavigationControl className="map-nav-btn" showCompass={false} data-testid="map-controls-button" />
          </div>
          <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
            <Layer {...uvaldeFillStyle} />
            <Layer {...uvaldeLineStyle} />
          </Source>
          <Source id="expectedPath" type="geojson" data={expectedPath}>
            <Layer {...dataLayerExpectedOuter} />
            <Layer {...dataLayerExpectedInside} />
          </Source>
          ,
          <Source id="actualPath" type="geojson" data={actualPath}>
            <Layer {...dataLayerActual} />
          </Source>
          ,
          <DeckGLOverlay layers={[startIndicatorLayer, endIndicatorLayer]} />
        </>

        <DeckGLOverlay layers={[vehiclesLayer]} />
        {hoverInfo?.object && <HoverWrapper hoverInfo={hoverInfo} />}
      </Map>
    </>
  ) : (
    <LoadScreen />
  )
}

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

/**
 * useMapResizeListener - listens to window resize events and updates the map container size
 */
const useMapResizeListener = () => {
  const mapRef = useMapStore((state) => state.mapRef)
  const setContainerSize = useMapStore((state) => state.setContainerSize)

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize()
        const { width, height } = mapRef.current.getMap().getContainer().getBoundingClientRect()
        setContainerSize({ width, height })
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    screen.orientation.addEventListener("change", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      screen.orientation.removeEventListener("change", handleResize)
    }
  }, [mapRef.current])
}

export default TrafficMapVector

export { DeckGLOverlay, initViewstate }
