import { NotesApiV2 } from "@/framework/utils/api/NotesAPI"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"

const putNoteMutationFn = async ({ noteUuid, noteRequest }) => {
  return await NotesApiV2.putNoteV2NotesNoteUuidPut({ noteUuid: noteUuid, requestBody: noteRequest }).catch((error) => {
    throw error
  })
}

const putNoteOnSuccess = ({ missionUuid, queryClient }) => {
  toast.success("Successfully updated mission note.", { toastId: "success-put-note" })
  return queryClient.invalidateQueries({ queryKey: ["mission", missionUuid, "notes"] })
}

const putNoteOnError = () => {
  toast.error(
    "An error occurred when attempting to update the mission note. Please try again, or contact support if the error persists.",
    {
      toastId: "error-put-note",
    },
  )
}

const usePutNoteMutation = () => {
  const queryClient = useQueryClient()

  const putNoteMutation = useMutation({
    mutationKey: ["mission", "notes-put"],
    mutationFn: ({ noteUuid, noteRequest }) => putNoteMutationFn({ noteUuid: noteUuid, noteRequest: noteRequest }),
    scope: {
      id: "edit_note",
    },
    onSuccess: (data) => putNoteOnSuccess({ missionUuid: data.mission_uuid, queryClient: queryClient }),
    onError: () => putNoteOnError(),
  })

  return putNoteMutation
}

export { usePutNoteMutation, putNoteOnError, putNoteOnSuccess, putNoteMutationFn }
