import useGetMissionRouteProgressQuery from "@/hooks/query/routes/useGetMissionRouteProgressQuery"
import useGetMissionRouteQuery from "@/hooks/query/routes/useGetMissionRouteQuery"
import { Box, Grid, Typography } from "@mui/material"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import { formatTimeLeft } from "../../framework/Utils"

const SliderProgress = styled(Slider)(() => ({
  height: 14,
  position: "initial",
  padding: "0 0 0 0 ",
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    borderRadius: 15,
    backgroundColor: "#009afa",
  },
  "& .MuiSlider-track": {
    opacity: 1,
    height: 13,
    borderRadius: 15,
    color: "#70cbff",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    height: 14,
    borderRadius: 15,
    backgroundColor: "#d7d7d7",
  },
}))

const MissionProgressBar = ({ mission }) => {
  const { data: missionRouteData } = useGetMissionRouteQuery(mission?.uuid)
  const { data: missionProgressData } = useGetMissionRouteProgressQuery(
    mission?.uuid,
    missionRouteData && missionRouteData[0]?.uuid,
  )

  return (
    <Grid container direction="column" justifyContent="space-evenly" spacing={0} px={0}>
      <Grid container item xs={2} justifyContent="space-between" direction="row">
        <Grid item textAlign="left">
          <Typography variant="subtitle2">Trip Progress</Typography>
        </Grid>
        <Grid item textAlign="right">
          <Typography variant="subtitle2">
            {formatTimeLeft(missionProgressData?.eta_remaining_seconds)} hr left
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Box position="relative" border={1} borderRadius={15} height={16} borderColor="#A7A7A7" sx={{ flexGrow: 1 }}>
          <SliderProgress disabled value={Number(missionProgressData?.percent_complete)} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default MissionProgressBar
