import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"

const useGetVehiclesConnectivitySummaryV2Query = () => {
  const cursor = null
  const count = "1000"
  return useQuery({
    queryKey: ["getVehiclesConnectivitySummaryV2", cursor, count],
    queryFn: async () =>
      await VehiclesApiV2.getVehiclesConnectivitySummaryV2VehiclesConnectivitySummaryGet({ cursor, count }),
  })
}

export default useGetVehiclesConnectivitySummaryV2Query
