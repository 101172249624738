import { Component } from "react"
import { toast } from "react-toastify"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError !== prevState.hasError && this.state.hasError == true) {
      toast.error("Error caught by ErrorBoundary")
      // this.context.resetAll()
      this.setState({ hasError: false })
    }
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
