/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  type GetVehicleResponse as Vehicle,
  type GetVehicleConnectivityResponse as VehicleConnectivity,
  type VehicleConnectivitySummary,
  type GetVehicleStatusResponse as VehicleStatus,
} from "@torc-robotics/mm-vehicles-client"
import { useMemo } from "react"
import { matchRoutes, useLocation } from "react-router-dom"
import useSearchStore from "../store/useSearchStore"
import { useGetMissionByLoadId } from "./missionHooks"
import useGetV2VehicleStatus from "./query/vehicle/useGetV2VehicleStatus"
import useGetVehicleConnectivityV2Query from "./query/vehicle/useGetVehicleConnectivityV2Query"
import useGetVehiclesConnectivitySummaryV2Query from "./query/vehicle/useGetVehiclesConnectivitySummaryV2Query"
import useGetVehiclesV2Query from "./query/vehicle/useGetVehiclesV2Query"
import useGetVehicleV2Query from "./query/vehicle/useGetVehicleV2Query"

interface Vehicles {
  vehicles: Vehicle[]
  filteredVehicles: Vehicle[]
  isGetVehiclesLoading: boolean
}

interface VehiclesConnectionSummary {
  vehiclesConnectionSummary: VehicleConnectivitySummary[]
  filteredVehiclesConnectionSummary: VehicleConnectivitySummary[]
  isGetVehiclesConnectionSummaryLoading: boolean
}

const useGetVehicle = (lookupVehicleId?: string): Vehicle | null => {
  const vehicleId = useGetVehicleId()
  const findVehicleId = lookupVehicleId ?? vehicleId

  const vehicleFromFilter =
    useGetVehiclesV2Query()?.data?.find((vehicle) => vehicle.vehicle_id === findVehicleId) ?? null
  const vehicle = useGetVehicleV2Query(findVehicleId ?? "")?.data ?? null

  return vehicle ?? vehicleFromFilter ?? null
}

const useGetVehicleId = (): string | null => {
  // TODO - instead of manually checking routes here, we should be using createBrowserRouter, it can provide the whole routing tree
  const location = useLocation()
  const routes = [{ path: "/missions/:load_id/*" }, { path: "/vehicles/:vehicle_id/*" }, { path: "*" }]
  const routeMatches = matchRoutes(routes, location)

  const mission = useGetMissionByLoadId()
  const vehicleId = useMemo<string | null | undefined>(() => {
    if (mission !== undefined) {
      return mission.vehicle_id
    } else if (routeMatches?.[0].route.path === "/vehicles/:vehicle_id/*") {
      return routeMatches?.[0].params?.vehicle_id
    }
    return null
  }, [location, mission])

  return vehicleId ?? null
}

const useGetVehiclesFiltered = (): Vehicles => {
  const { data: vehicles, isLoading: isGetVehiclesLoading } = useGetVehiclesV2Query()
  const { filter, sort, search } = useSearchStore((state) => state.vehicle)
  const {
    "Connectivity Status": {
      filters: {
        Active: { checked: ActiveChecked },
        Inactive: { checked: InactiveChecked },
      },
    },
  } = filter

  const filteredVehicles = useMemo(() => {
    if (!vehicles) return []
    const criteria = search?.toLowerCase() ?? ""

    let filtered: Vehicle[] = []
    if (criteria !== "") {
      return vehicles?.filter(
        (vehicle) =>
          vehicle?.vehicle_id?.toLowerCase().includes(criteria) || vehicle?.name?.toLowerCase()?.includes(criteria),
      )
    }

    if (!ActiveChecked && !InactiveChecked) {
      filtered = vehicles
    } else {
      vehicles?.forEach((vehicle) => {
        if (ActiveChecked && vehicle?.connected) {
          filtered.push(vehicle)
          return
        }
        if (InactiveChecked && !vehicle?.connected) {
          filtered.push(vehicle)
        }
      })
    }
    filtered = filtered.sort((a, b) => {
      switch (sort.field) {
        case "vehicle-name":
          if (sort.order === "asc") {
            return !!a?.name > !!b?.name ? 1 : -1
          }
          return !!a?.name < !!b?.name ? 1 : -1
        case "vehicle-id":
          if (sort.order === "asc") {
            return a.vehicle_id > b.vehicle_id ? 1 : -1
          }
          return a.vehicle_id < b.vehicle_id ? 1 : -1
        case "active-inactive":
        default:
          if (sort.order === "asc") {
            return !!a?.connected < !!b?.connected ? 1 : -1
          }
          return !!a?.connected > !!b?.connected ? 1 : -1
      }
    })
    return filtered
  }, [vehicles, ActiveChecked, InactiveChecked, sort.order, sort.field, search])

  return {
    vehicles: vehicles ?? [],
    filteredVehicles: filteredVehicles ?? [],
    isGetVehiclesLoading,
  }
}

const useGetVehicleStatus = (lookupVehicleId?: string): VehicleStatus | null => {
  const vehicleId = useGetVehicleId()
  const findVehicleId = lookupVehicleId ?? vehicleId

  const vehicleStatus = useGetV2VehicleStatus(findVehicleId ?? "")?.data ?? null

  return vehicleStatus ?? null
}

const useGetVehicleConnectivity = (lookupVehicleId?: string): VehicleConnectivity | null => {
  const vehicleId = useGetVehicleId()
  const findVehicleId = lookupVehicleId ?? vehicleId

  const vehicleConnectivity = useGetVehicleConnectivityV2Query(findVehicleId ?? "")?.data ?? null

  return vehicleConnectivity ?? null
}

const useGetVehiclesConnectivitySummaryFiltered = (): VehiclesConnectionSummary => {
  const { data: vehiclesConnectionSummary, isLoading: isGetVehiclesConnectionSummaryLoading } =
    useGetVehiclesConnectivitySummaryV2Query()
  const { filter, sort, search } = useSearchStore((state) => state.vehicle)

  const {
    "Connectivity Status": {
      filters: {
        Active: { checked: ActiveChecked },
        Inactive: { checked: InactiveChecked },
      },
    },
  } = filter

  const filteredVehiclesConnectionSummary = useMemo(() => {
    if (!vehiclesConnectionSummary) return []
    const criteria = search?.toLowerCase() ?? ""

    let filtered: VehicleConnectivitySummary[] = []
    if (criteria !== "") {
      return vehiclesConnectionSummary?.filter((vehicleConnection) =>
        vehicleConnection?.vehicle_id?.toLowerCase().includes(criteria),
      )
    }

    if (!ActiveChecked && !InactiveChecked) {
      filtered = vehiclesConnectionSummary
    } else {
      vehiclesConnectionSummary?.forEach((vehicleConnection) => {
        if (
          ActiveChecked &&
          (vehicleConnection?.connectivity_status === "connected" ||
            vehicleConnection?.connectivity_status === "partially_connected")
        ) {
          filtered.push(vehicleConnection)
          return
        }
        if (InactiveChecked && vehicleConnection?.connectivity_status === "disconnected") {
          filtered.push(vehicleConnection)
        }
      })
    }

    filtered = filtered.sort((a, b) => {
      const statusOrder = {
        connected: 0,
        partially_connected: 1,
        disconnected: 2,
      }

      switch (sort.field) {
        case "vehicle-id":
          if (sort.order === "asc") {
            return a.vehicle_id > b.vehicle_id ? 1 : -1
          }
          return a.vehicle_id < b.vehicle_id ? 1 : -1
        case "active-inactive":
        default:
          if (sort.order === "asc") {
            return statusOrder[a?.connectivity_status] - statusOrder[b?.connectivity_status]
          }
          return statusOrder[b?.connectivity_status] - statusOrder[a?.connectivity_status]
      }
    })
    return filtered
  }, [vehiclesConnectionSummary, ActiveChecked, InactiveChecked, sort.order, sort.field, search])

  return {
    vehiclesConnectionSummary: vehiclesConnectionSummary ?? [],
    filteredVehiclesConnectionSummary: filteredVehiclesConnectionSummary ?? [],
    isGetVehiclesConnectionSummaryLoading,
  }
}

export {
  useGetVehiclesFiltered,
  useGetVehicle,
  useGetVehicleStatus,
  useGetVehicleId,
  useGetVehicleConnectivity,
  useGetVehiclesConnectivitySummaryFiltered,
}
