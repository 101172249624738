import { getJwtToken } from "@/framework/Api"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { isEmpty } from "lodash"

const ROUTE_ADVISOR_API_URL = String(import.meta.env.VITE_ROUTE_ADVISOR_API_URL)

const useGetRouteAdvisorViasQuery = (arrivalLocation: { lat: string; lon: string }, mapVersion: string) => {
  const destinationStr = `${arrivalLocation?.lat}, ${arrivalLocation.lon}`
  /* istanbul ignore next */
  const useGetRouteAdvisorVias = useQuery({
    queryKey: ["route-advisor-vias", mapVersion, destinationStr],
    queryFn: async () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (
        await axios.get(
          `${ROUTE_ADVISOR_API_URL}/v1/vias?destination_latlon=${destinationStr}&map_version=${mapVersion}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getJwtToken()}`,
            },
          },
        )
      ).data,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: !isEmpty(arrivalLocation) && !isEmpty(mapVersion),
  })

  return useGetRouteAdvisorVias
}

export default useGetRouteAdvisorViasQuery
