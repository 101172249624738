import { NotesApiV2 } from "@/framework/utils/api/NotesAPI"
import { useQuery } from "@tanstack/react-query"

const useGetNoteTagsQuery = (tagFilter: string) => {
  const getNoteTagsQuery = useQuery({
    queryKey: ["note-tags", tagFilter],
    queryFn: async () => await NotesApiV2.getNotesTagsV2({ tagFilter }),
  })

  return getNoteTagsQuery
}

export default useGetNoteTagsQuery
