import MainLayout from "@/features/layouts/MainLayout"
import { type RouteObject } from "react-router-dom"
import MissionRoutes from "./MissionRoutes"
import VehicleRoutes from "./VehicleRoutes"

const MainRoutes: RouteObject = {
  path: "/",
  element: <MainLayout />,
  children: [MissionRoutes, VehicleRoutes],
}

export default MainRoutes
