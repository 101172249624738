import { matchRoutes, useLocation } from "react-router-dom"
import useGetActionsForMissionQuery from "./query/actions/useGetActionsForMissionQuery"
import useGetDepartureRequestsQuery from "./query/mission/useGetDepartureRequestsQuery"
import useGetMissionByLoadIdQuery from "./query/mission/useGetMissionByLoadIdQuery"
import useGetMissionByUuidQuery from "./query/mission/useGetMissionByUuidQuery"
import useGetMissionsQuery from "./query/mission/useGetMissionsQuery"
import useGetNextMissionQuery from "./query/mission/useGetNextMissionQuery"

/**
 *
 * @param {string} uuid
 * @returns {import("@torc-robotics/mm-missions-client").Mission_Output}
 */
const useGetMissionByUuid = (uuid = null) => {
  return useGetMissionByUuidQuery(uuid)?.data
}

/**
 *
 * @param {string} loadId
 * @returns {import("@torc-robotics/mm-missions-client").Mission_Output | undefined}
 */
const useGetMissionByLoadId = (loadId = null) => {
  const routes = [{ path: "/missions/:load_id/*" }, { path: "/missions/v2/:load_id/*" }, { path: "*" }]
  const location = useLocation()
  const routeMatches = matchRoutes(routes, location)

  const mission_id = routeMatches[0].params.load_id ?? loadId
  const missionOverview = useGetMissions()?.find((mission) => mission.load_id === mission_id)
  const missionDetails = useGetMissionByLoadIdQuery(mission_id)?.data?.missions

  return missionDetails?.[0] ?? missionOverview ?? undefined
}

/**
 *
 * @returns {import("@torc-robotics/mm-missions-client").Mission_Output[]} missions
 */
const useGetMissions = () => {
  const missions = useGetMissionsQuery().data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.missions]
  }, [])

  return missions
}

/**
 *
 * @returns {import("@torc-robotics/mm-missions-client").Mission_Output} missions
 */
const useGetNextMission = (uuid = null) => {
  return useGetNextMissionQuery(uuid)?.data
}
const formatTime = (timestamp) => {
  const date = new Date(timestamp)
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedHours = hours % 12 || 12 // Use logical OR to handle midnight (0) correctly
  const period = hours >= 12 ? "PM" : "AM"

  const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`
  return formattedTime
}

const useGetDepartureRequestsWarning = (lookupMission) => {
  const mission = useGetMissionByLoadId() ?? lookupMission
  const { data: departureRequests } = useGetDepartureRequestsQuery(mission?.uuid)
  const { data: missionActions } = useGetActionsForMissionQuery(mission?.uuid)

  let hasAction = false
  let message = ""
  let latestAction = {}

  if (missionActions && !missionActions.error) {
    const sortedActions = [...missionActions.actions].sort(
      (a, b) => new Date(b.last_updated) - new Date(a.last_updated),
    )
    latestAction = sortedActions[0]
  }

  if (departureRequests && departureRequests.length > 0) {
    const latestRequest = departureRequests[0]

    switch (latestRequest.departure_request_state) {
      case "TIMED_OUT":
        hasAction = true
        message = "ADS has not responded. Re-send Departure Clearance"
        break
      case "DECLINED":
        hasAction = true
        message = `Departure denied. ${latestRequest.decline_reason}`
        break
      case "ERRORED":
        hasAction = true
        message = `Failure to send departure request to vehicle`
        break
      case "PENDING": {
        const lastUserToUpdate = latestAction?.last_user_to_update?.split("@")[0]
        const fullName = lastUserToUpdate
          ? lastUserToUpdate
              .split(".")
              .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
              .join(" ")
          : ""
        const formattedUpdateTime = formatTime(latestAction?.last_updated)
        message = `Departure Clearance was approved by ${fullName} at ${formattedUpdateTime}. Waiting on ADS acceptance.`
        break
      }
      default:
        break
    }
  }

  return { hasAction, message }
}

export { useGetDepartureRequestsWarning, useGetMissionByLoadId, useGetMissionByUuid, useGetMissions, useGetNextMission }
