import { getFormattedDate } from "@/framework/Utils"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Grid, IconButton, Tooltip, Typography } from "@mui/material"
import _ from "lodash"
import { Link } from "react-router-dom"
import styles from "./scss/MissionDetailsHeader.module.scss"

const MissionNext = ({ mission }) => {
  return (
    <Grid container direction="row" columns={3}>
      <Grid item xs={1}>
        <Typography className="data-header" variant="caption">
          MISSION ID
        </Typography>
        <Tooltip
          title={
            <div>
              {mission?.load_id}
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(mission?.load_id)
                }}
                disableRipple={false}
                sx={{ color: "white" }}
              >
                <ContentCopyIcon sx={{ fontSize: "16px" }} data-testid="toolTip-copy" />
              </IconButton>
            </div>
          }
        >
          <Link
            to={`/missions/${encodeURIComponent(mission?.load_id)}`}
            style={{ pointerEvents: !mission?.load_id ? "none" : "" }}
          >
            <Typography
              data-testid="load-id"
              className={mission?.load_id ? styles["mission-details-header__load-id"] : ""}
            >
              {_.get(mission, "load_id", "---")}
            </Typography>
          </Link>
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Typography className="data-header" variant="caption">
          SCHEDULED DEPARTURE
        </Typography>
        <div className="mission-departure-details__departure-date">
          <Typography className="mission-departure-details__date">
            {getFormattedDate(mission?.tms_schedule_departure_time)}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default MissionNext
