import { Box, Card, Typography } from "@mui/material"
import "./scss/MissionDetails.scss"
import useGetActionsForMissionQuery from "@/hooks/query/actions/useGetActionsForMissionQuery"
import { useMemo } from "react"
import MissionDepartureDetails from "./MissionDepartureDetails"

const MissionDetailsCard = ({ mission }) => {
  const { data: actionData } = useGetActionsForMissionQuery(mission?.uuid)
  const completedTaskCount = useMemo(() => {
    return actionData?.actions
      ? actionData?.actions.filter((action) => action?.state.toLowerCase() === "completed").length
      : 0
  }, [actionData])

  const missionActions = useMemo(() => {
    const actions = actionData?.actions
    return actions
  }, [actionData])

  if (!mission) {
    return (
      <Card data-testid="no-data-card">
        <Box>
          <Typography className="mission-list-title">No Mission Data</Typography>
        </Box>
      </Card>
    )
  }

  return (
    <Box sx={{ paddingTop: "6px" }}>
      <MissionDepartureDetails
        mission={mission}
        taskcount={completedTaskCount}
        details={true}
        actions={missionActions}
      />
    </Box>
  )
}

export default MissionDetailsCard
