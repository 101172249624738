import React from "react"
import ReactDOMClient from "react-dom/client"
import singleSpaReact from "single-spa-react"
import App from "./components/App"

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: App,
  // eslint-disable-next-line no-unused-vars
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <>err</>
  },
})

export const { bootstrap, mount, unmount } = lifecycles
