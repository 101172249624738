import { Chip } from "@mui/material"
import _ from "lodash"

export const AutonomousIndicator = ({ vehicleStatus }) => {
  const safetyMode = _.get(
    {
      2: "ADS Engaged",
      1: "Manual",
    },
    vehicleStatus?.safety_mode,
    "Unknown",
  )
  return (
    <Chip
      size="small"
      label={safetyMode}
      color={safetyMode !== "ADS Engaged" ? "primary" : "info"}
      className="status-chip"
      sx={{
        backgroundColor: safetyMode !== "ADS Engaged" ? "#7F7F7F" : "primary",
      }}
    />
  )
}
