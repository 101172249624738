/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { degToCompass } from "@/framework/Utils"
import {
  getVehicleBorderColor,
  getVehicleClassName,
  getVehicleHealthBg,
  getVehicleHealthColor,
  getVehicleSpeed,
} from "@/framework/utils/VehicleDisplayUtil"
import { useGetVehicle, useGetVehicleConnectivity, useGetVehicleStatus } from "@/hooks/vehiclesV2Hooks"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import { Box, IconButton, Typography } from "@mui/material"
import {
  type GetVehicleResponse as Vehicle,
  type GetVehicleConnectivityResponse as VehicleConnectivity,
  type GetVehicleStatusResponse as VehicleStatus,
} from "@torc-robotics/mm-vehicles-client"
import { type ReactElement } from "react"
import "../scss/VehicleHover.scss"
import { AutonomousIndicator } from "@/features/vehicles/AutonomousIndicator"

const VehiclesHoverTooltip = ({ vehicleId }: { vehicleId: string }): ReactElement => {
  const vehicleStatus: VehicleStatus | null = useGetVehicleStatus(vehicleId)
  const vehicleConnectivity: VehicleConnectivity | null = useGetVehicleConnectivity(vehicleId)

  const vehicle: Vehicle | null = useGetVehicle(vehicleId)

  if (vehicle === null || vehicle === undefined) {
    return <div />
  }

  const heading = vehicleStatus?.heading
  const health = vehicleConnectivity?.status

  return (
    <div
      className="main-container"
      style={{
        borderColor: getVehicleBorderColor(vehicleConnectivity),
      }}
    >
      <Box>
        <Box
          className="title-container"
          sx={{
            background: getVehicleHealthBg(vehicleConnectivity),
          }}
        >
          <Typography
            className={getVehicleClassName(vehicleConnectivity)}
            data-testid="hover-subtitle"
            variant="subtitle2"
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            {(health === "disconnected" || health === "partially_connected") && (
              <IconButton
                aria-label="warning"
                size="small"
                data-testid="warning"
                color={getVehicleHealthColor(vehicleConnectivity)}
              >
                {health === "disconnected" ? <ErrorIcon /> : <WarningIcon style={{ color: "#D6791C" }} />}
              </IconButton>
            )}
            {vehicle?.vehicle_id}
            {(() => {
              if (typeof vehicle?.name === "string" && vehicle?.name.trim() !== "") {
                return ` - ${vehicle?.name}`
              }
              return null
            })()}
          </Typography>
        </Box>
        <Typography sx={{ padding: "0.5rem", textAlign: "center" }}>
          <strong className="font1">
            {getVehicleSpeed(vehicleStatus) !== null ? getVehicleSpeed(vehicleStatus) : "---"}
          </strong>
          {" mph  "}
          <strong className="font1">{degToCompass(heading ?? 0)}</strong>
        </Typography>
        <AutonomousIndicator vehicleStatus={vehicleStatus} />
      </Box>
    </div>
  )
}

export default VehiclesHoverTooltip
