import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetVehicleV2Query = (vehicleId: string) => {
  const vehicleV2Query = useQuery({
    queryKey: ["vehicle", vehicleId],
    queryFn: async () => await VehiclesApiV2.getVehicleV2({ vehicleId }),
    retry: 3,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !isEmpty(vehicleId),
  })

  return vehicleV2Query
}

export default useGetVehicleV2Query
