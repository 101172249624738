import { SvgIcon } from "@mui/material"
import React from "react"

const CustomHeartMonitorIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M3 12h3l2-3 3 6 3-7 2 4h4"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default CustomHeartMonitorIcon
