import { DatadogContext } from "@/framework/contexts/Datadog"
import _ from "lodash"
import { createContext, useContext } from "react"

export const TaskFormContext = createContext(null)

const TaskForm = ({ onSubmit = _.noop, onCancel = _.noop, onSave = _.noop, action, children }) => {
  const { telemetry } = useContext(DatadogContext)

  telemetry.useViewContext("action", _.pick(action, ["uuid", "mission_uuid", "load_id", "state"]))
  telemetry.useAction(`Task.View ${action?.title}`, { state: "success" })

  return (
    <TaskFormContext.Provider
      value={{
        onSubmit: telemetry.withActionTelemetry(onSubmit, `Task.Submit ${action?.title}`),
        onCancel: telemetry.withActionTelemetry(onCancel, `Task.Cancel ${action?.title}`),
        onSave: telemetry.withActionTelemetry(onSave, `Task.Save ${action?.title}`),
      }}
    >
      {children}
    </TaskFormContext.Provider>
  )
}

export default TaskForm
