import MissionsApi, { MissionsApiV2 } from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionRouteQuery = (missionUuid) => {
  const missionRouteQuery = useQuery({
    queryKey: ["selected-route", missionUuid],
    queryFn: async () => await MissionsApi.getMissionRoutes({ missionUuid, selected: true }),
    enabled: !isEmpty(missionUuid),
  })

  return missionRouteQuery
}

export default useGetMissionRouteQuery
