import router from "@/router/router"
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { Amplify } from "aws-amplify"
import { RouterProvider } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { HotkeyContextProvider } from "@/features/shared/Hotkeys"
import { DatadogContextProvider } from "@/framework/contexts/Datadog"
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { defaultShouldDehydrateQuery, onlineManager } from "@tanstack/react-query"
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client"
import { useEffect } from "react"
import awsConfig from "../aws-config"
import LoadScreen from "./LoadScreen"
import queryClient from "./queryClient"

Amplify.configure(awsConfig)
const theme = createTheme({
  palette: {
    mode: "light",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})

const shouldDehydrateQuery = (q) => defaultShouldDehydrateQuery(q) && q?.meta?.persist !== false

const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])

  const handleConnection = () => {
    if (navigator.onLine) onlineManager.setOnline(true)
    else onlineManager.setOnline(false)
  }

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  })

  useEffect(() => {
    window.addEventListener("offline", handleConnection)
    window.addEventListener("online", handleConnection)
    onlineManager.setEventListener(handleConnection)
  }, [])

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery,
        },
      }}
      onSuccess={() => {
        if (navigator.onLine) {
          queryClient.resumePausedMutations()
        }
      }}
    >
      <>
        {authStatus === "authenticated" ? (
          <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </>
        ) : (
          <LoadScreen fullscreen={true} />
        )}
      </>
    </PersistQueryClientProvider>
  )
}

// eslint-disable-next-line react/display-name
export default ({ datadogRum, datadogLogs }) => (
  <Authenticator.Provider>
    <DatadogContextProvider datadogRumClient={datadogRum} datadogLogsClient={datadogLogs}>
      <HotkeyContextProvider>
        <App />
      </HotkeyContextProvider>
    </DatadogContextProvider>
  </Authenticator.Provider>
)
