import { useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"

const useBeforeUnload = () => {
  const queryClient = useQueryClient()

  const handleBeforeUnload = (event) => {
    const mutations = queryClient.getMutationCache().getAll()

    const pendingMutations = mutations.filter((mutation) => mutation.state.status === "pending")

    if (pendingMutations.length > 0) {
      const message = "You have unsaved changes and are currently offline. Are you sure you want to leave?"
      event.returnValue = message
      return message
    }
  }
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  })
}

export default useBeforeUnload
