import { Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { tooltipClasses } from "@mui/material/Tooltip"

export const IconTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    PopperProps={{
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -8],
          },
        },
      ],
    }}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#272E33",
    color: "#ffffff",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 5,
    boxShadow: "1px 1px 6px 0px rgba(0, 0, 0, 0.35)",
  },
}))
