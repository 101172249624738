import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQueries } from "@tanstack/react-query"

const useGetVehiclesMissionInfo = (vehicles) => {
  const vehicleIds = vehicles.map(({ vehicle_id }) => vehicle_id)

  const createMissionQuery = (vehicleId) => ({
    queryKey: ["vehicle-missions", vehicleId],
    queryFn: () =>
      MissionsApi.getMissions({ vehicle: vehicleId, states: ["IN_PROGRESS"] }).then((response) =>
        response?.missions.length ? response.missions : [],
      ),
    staleTime: Infinity,
  })

  const missionQuery = vehicleIds.map(createMissionQuery)

  const missionResults = useQueries({ queries: missionQuery })

  const missionStateInfo = missionResults.reduce((acc, { data, error }, index) => {
    const vehicleId = vehicleIds[index]
    acc[vehicleId] = error ? null : data
    return acc
  }, {})

  return missionStateInfo
}

export default useGetVehiclesMissionInfo
