import { getCriticalityColor, getCriticalitySeverityLabel, getTime } from "@/framework/Utils"
import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Pagination, Typography } from "@mui/material"
import _ from "lodash"
import { useMemo, useState } from "react"
import "./Log.scss"

const Log = ({ logs, dataType }) => {
  const rowsPerPage = 20
  const pageCount = Math.ceil(_.size(logs) / rowsPerPage)
  const [page, setPage] = useState(1)
  const visibleLogs = _.slice(logs, (page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)

  const handlePageChange = (e, pageNumber) => setPage(pageNumber)

  const logData = useMemo(() => {
    return _.map(visibleLogs, (event) => {
      return (
        <Accordion className="log-event" key={event.log_uuid} disableGutters elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Grid container columns={7}>
              <Grid xs={3} item>
                {event.category ? (
                  <Typography>{_.startCase(_.toLower(_.replace(event.category, /_/g, " ")))}</Typography>
                ) : (
                  <Typography>{_.startCase(_.toLower(_.replace(event.title, /_/g, " ")))}</Typography>
                )}
              </Grid>
              <Grid xs={2} item>
                <Typography sx={{ color: getCriticalityColor(event.level) }}>
                  {getCriticalitySeverityLabel(event.level)}
                </Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography className="log-event-timestamp">{getTime(event.creation_ts * 1000)}</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="log-event-detailed">
            <Typography className="log-text">{event.description}</Typography>
            {event.resolution ? (
              <Typography className="log-resolution-text">
                <span>Potential actions to resolve:</span>
                {event.resolution}
              </Typography>
            ) : null}
            {event.details?.body ? (
              <Typography className="log-data">
                <strong>Additional Details:</strong> {event.details.body}
              </Typography>
            ) : null}
          </AccordionDetails>
        </Accordion>
      )
    })
  }, [logs, page])

  return (
    <>
      <Typography className="log-event-title">{`Current ${dataType} Events`}</Typography>
      {logData && !_.isEmpty(logs) ? (
        logData
      ) : (
        <Box className="operational">
          <Typography>No logs at this time</Typography>
        </Box>
      )}
      {pageCount > 1 && (
        <Grid container justifyContent={"center"} marginTop={1} marginBottom={1}>
          <Grid item>
            <Pagination
              page={page}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Log
