import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useLastKnownLocation = (vehicleId: string, refetchFlag: boolean) => {
  const locationQuery = useQuery({
    queryKey: ["vehicle-location", vehicleId],
    queryFn: async () => await VehiclesApiV2.getVehicleStatusV2VehiclesVehicleIdStatusGet({ vehicleId }),
    enabled: !isEmpty(vehicleId),
  })
  return locationQuery
}

export default useLastKnownLocation
