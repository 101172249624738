import { Box, Grid, Typography } from "@mui/material"
import _ from "lodash"

const MissionCrew = ({ departure }) => {
  return (
    <Grid container direction="row" columns={3} sx={{ wordBreak: "break-all" }}>
      <Grid className="crew-label" item xs={1}>
        <Typography className="crew-label" variant="caption">
          IFTD (Safety Driver)
        </Typography>
        <Box className="crew-label" display="flex" alignItems="center">
          {_.get(departure, "safety_driver_email", "---") !== "---" ? (
            <Typography data-testid="crew-iftd" variant="body2">
              <a href={`mailto:${_.get(departure, "safety_driver_email")}`}>
                {_.get(departure, "safety_driver_email")}
              </a>
            </Typography>
          ) : (
            <Typography variant="body2">---</Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Typography className="crew-label" variant="caption">
          Safety Conductor
        </Typography>
        <Box className="crew-label" display="flex" alignItems="center">
          {_.get(departure, "safety_conductor_email", "---") !== "---" ? (
            <Typography data-testid="crew-sc" variant="body2">
              <a href={`mailto:${_.get(departure, "safety_conductor_email")}`}>
                {_.get(departure, "safety_conductor_email")}
              </a>
            </Typography>
          ) : (
            <Typography variant="body2">---</Typography>
          )}
        </Box>
      </Grid>
      <Grid className="crew-label" item xs={1}>
        <Typography className="crew-label" variant="caption">
          Mission Manager
        </Typography>
        <Box className="crew-label" display="flex" alignItems="center">
          {_.get(departure, "mission_manager_email", "---") !== "---" ? (
            <Typography data-testid="crew-mm" variant="body2">
              <a href={`mailto:${_.get(departure, "mission_manager_email")}`}>
                {_.get(departure, "mission_manager_email")}
              </a>
            </Typography>
          ) : (
            <Typography variant="body2">---</Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Typography className="crew-label" variant="caption">
          Passenger 1
        </Typography>
        <Box className="crew-label-1" display="flex" alignItems="center">
          <Typography variant="body2">{_.get(departure, "departure.passengers[0]", "---")}</Typography>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Typography className="crew-label data-header" variant="caption">
          Passenger 2
        </Typography>
        <Box className="display-data" display="flex" alignItems="center">
          <Typography variant="body2">{_.get(departure, "departure.passengers[1]", "---")}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MissionCrew
