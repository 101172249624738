import { type Map } from "maplibre-gl"
import { createRef } from "react"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface HoverInfo<T> {
  x: number
  y: number
  object: T
  component: React.FC<{ object: T }>
}

interface MapStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hoverInfo: HoverInfo<any> | null
  mapRef: React.RefObject<Map>
  containerSize: { width: number; height: number }
  isCentered: boolean
  setHoverInfo: <T>(hoverInfo: HoverInfo<T> | null) => void
  setContainerSize: (containerSize: { width: number; height: number }) => void
  setIsCentered: (isCentered: boolean) => void
}

const useMapStore = create<MapStore>()(
  devtools(
    (set) => ({
      hoverInfo: null,
      containerSize: { width: 0, height: 0 },
      isCentered: true,
      mapRef: createRef<Map>(),
      setHoverInfo: <T>(hoverInfo: HoverInfo<T> | null) => {
        set({ hoverInfo })
      },
      setContainerSize: (containerSize) => {
        set({ containerSize })
      },
      setIsCentered: (isCentered) => {
        set({ isCentered })
      },
    }),
    { name: "Map Store" },
  ),
)

export default useMapStore
