import { useGetDepartureRequestsWarning } from "@/hooks/missionHooks"
import useGetV2VehicleStatus from "@/hooks/query/vehicle/useGetV2VehicleStatus"
import ErrorIcon from "@mui/icons-material/Error"
import WifiOffIcon from "@mui/icons-material/WifiOff"
import { Box, Typography } from "@mui/material"
import { onlineManager, useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { matchRoutes, useLocation } from "react-router-dom"
import { getFormattedDate } from "../../framework/Utils"

const ETA_ARRIVING_TIME = 900 // 15 Min Indicator to notify users that the vehicle is arriving soon

const MissionAlert = ({ mission }) => {
  const [connectivity, setConnectivity] = useState(onlineManager.isOnline())

  const [pendingMutationsCount, setPendingMutationsCount] = useState(0)
  const departureRequests = useGetDepartureRequestsWarning(mission)
  const { data: vehicleStatus } = useGetV2VehicleStatus(mission?.vehicle_id)

  const queryClient = useQueryClient()

  const alertConfigs = [
    {
      condition: () => getRouteMatch("tasks") && mission?.state === "WAIT_ACCEPT" && departureRequests?.message,
      title: departureRequests?.hasAction ? "Action Required:" : "",
      message: departureRequests.message,
      backgroundColor: departureRequests?.hasAction ? "#ffefd6" : "#eefaff",
      icon: null,
    },
    {
      condition: () => getRouteMatch("notes") && !connectivity && pendingMutationsCount,
      title: "Notice:",
      message: `You are offline. ${pendingMutationsCount} note(s) will be synced to the system when the application reconnects.`,
      backgroundColor: "#eefaff",
      icon: <WifiOffIcon sx={{ color: "#0288d1" }} />,
    },
    {
      condition: () =>
        getRouteMatch("details") &&
        mission?.state === "IN_PROGRESS" &&
        mission?.eta_remaining_seconds <= ETA_ARRIVING_TIME,
      title: "Arriving Soon:",
      message: `This truck is expected to arrive at ${mission?.destination_display} Hub in 15 minutes or less`,
      backgroundColor: "#eefaff",
      icon: null,
    },
    {
      condition: () => mission?.state === "ARRIVED",
      title: "Arrived:",
      message: `${mission?.vehicle_id} Arrived at ${mission?.msm_arrived_ts && getFormattedDate(mission?.msm_arrived_ts, "HH:mm", "N/A")}`,
      backgroundColor: "#eefaff",
      icon: null,
    },
    {
      condition: () => mission?.state === "CLEARED" && vehicleStatus?.healthy !== undefined && !vehicleStatus?.healthy,
      title: "ACTIVE TYPE 2 FAULT -",
      message: `Vehicle has one or more Active Type 2 Faults`,
      backgroundColor: "#ffefd6",
      icon: <ErrorIcon sx={{ color: "#ff9d3d" }} />,
    },
  ]

  useEffect(() => {
    const updatePendingMutations = () => {
      const mutationCache = queryClient.getMutationCache()
      const pendingMutations = mutationCache.findAll({ status: "pending" })
      setPendingMutationsCount(pendingMutations.length)
    }

    onlineManager.subscribe((onlineState) => {
      setConnectivity(onlineState)
    })

    const mutationObserver = queryClient.getMutationCache().subscribe(updatePendingMutations)

    updatePendingMutations()

    return () => {
      mutationObserver()
    }
  }, [])

  const AlertBox = ({ title, message, bgColor, icon }) => (
    <Box
      item
      sx={{
        backgroundColor: bgColor,
        height: "56px",
        display: "flex",
        alignItems: "center",
        padding: ".7em",
        gap: ".5em",
      }}
    >
      {icon}
      <Typography sx={{ fontSize: "14px", padding: "1em" }} data-testid="action-warning">
        {title && <strong>{title}</strong>} {message}
      </Typography>
    </Box>
  )

  const getRouteMatch = (path) => {
    const routes = [{ path: `/missions/:load_id/${path}` }]
    const location = useLocation()
    const routeMatches = matchRoutes(routes, location)
    return routeMatches
  }

  return (
    <>
      {alertConfigs.map((config, index) => {
        if (config.condition()) {
          return (
            <AlertBox
              key={index}
              title={config.title ? config.title : null}
              message={config.message}
              bgColor={config.backgroundColor}
              icon={config.icon}
            />
          )
        }
        return null
      })}
    </>
  )
}
export default MissionAlert
