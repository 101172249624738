import { css } from "@emotion/react"
import { styled } from "@mui/system"

export const StyledInputRoot = styled("div")(css`
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`)
export const StyledInput = styled("input")(css`
  font-size: 0.875rem;
  width: 48px;
  height: 29px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  text-align: center;
`)

export const StyledButton = styled("button")(css`
  font-size: 14px;
  border: 1px solid rgba(122, 141, 153, 1);
  border-radius: 5px;
  width: 38px;
  height: 30px;
  min-width: 0;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #7a8d99;

  &:hover {
    cursor: pointer;
    background-color: rgba(224, 230, 235, 1);
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
  }
`)
