import MuiSmallTruckImage from "@/components/MuiSmallTruckImage"
import useVehicleHover from "@/hooks/map/layers/useVehicleHover"
import ReplyIcon from "@mui/icons-material/Reply"
import { Box, Card, CardActionArea, Grid, IconButton, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { FaCircle } from "react-icons/fa"
import { Link, useLocation, useNavigate } from "react-router-dom"
import VehiclesHoverTooltip from "../map/hover/VehiclesHoverTooltip"
import "./TruckTab.scss"

/**
 * @param {{ vehicleConnection: import("@torc-robotics/mm-vehicles-client").VehicleConnectivitySummary, stream: any}}
 * @returns
 */
const VehicleListCard = ({ vehicleConnection, stream }) => {
  const handleHover = useVehicleHover()
  const navigate = useNavigate()
  const location = useLocation()
  const vehicleConnected = vehicleConnection?.connectivity_status

  return (
    <Card
      data-testid={`${vehicleConnection?.vehicle_id}-card`}
      key={vehicleConnection?.vehicle_id}
      variant="outlined"
      sx={{ ":hover": { backgroundColor: grey[100] } }}
    >
      <CardActionArea
        data-testid={`${vehicleConnection?.vehicle_id}-card-action`}
        component={Link}
        to={stream ? "" : vehicleConnection?.vehicle_id}
        state={{ from: "vehicles" }}
        onMouseEnter={() =>
          handleHover(<VehiclesHoverTooltip vehicleId={vehicleConnection?.vehicle_id} />, vehicleConnection?.vehicle_id)
        }
        onMouseLeave={() => handleHover(<VehiclesHoverTooltip vehicleId={""} />)}
        onClick={() => handleHover(<VehiclesHoverTooltip />)}
      >
        <Box sx={{ m: 0.5, px: 0.75, display: "flex", alignItems: "center", gap: 1.5 }}>
          <div
            className={
              vehicleConnected === "connected"
                ? "green"
                : vehicleConnected === "partially_connected"
                  ? "yellow"
                  : "grey"
            }
            data-testid={`${vehicleConnection?.vehicle_id}-connectivity-bubble`}
          >
            <FaCircle />
          </div>
          <Typography variant="overline">{`${vehicleConnection?.vehicle_id}`}</Typography>
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center", gap: 2 }}>
            <Grid container display="flex" justifyContent="flex-end" sx={{ p: 1 }} columns={4}>
              <MuiSmallTruckImage vehicleId={vehicleConnection ? vehicleConnection?.vehicle_id : ""} />
            </Grid>
          </Box>
          {stream ? (
            <Box>
              <IconButton
                aria-label="back"
                size="small"
                data-testid="back-btn"
                data-dd-action-name="back-btn"
                onClick={() => {
                  navigate(`/${location.pathname.split("/")[1]}`)
                }}
              >
                <ReplyIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default VehicleListCard
