import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionRouteProgressQuery = (uuid, routeUuid) => {
  const missionRouteProgressQuery = useQuery({
    queryKey: ["mission-progress", uuid, routeUuid],
    queryFn: async () => await MissionsApi.getMissionRouteProgress({ missionUuid: uuid, routeUuid: routeUuid }),
    retry: 3,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !isEmpty(uuid) && !isEmpty(routeUuid),
  })

  return missionRouteProgressQuery
}

export default useGetMissionRouteProgressQuery
