import { getFormattedDate, getTime } from "@/framework/Utils"
import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import useGetMissionNotesQuery from "@/hooks/query/notes/useGetMissionNotesQuery"
import { Box, Chip, LinearProgress, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { onlineManager, useIsRestoring, useQueryClient } from "@tanstack/react-query"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import "./MissionNotes.scss"
import * as React from "react"

const MissionViewNotes = ({ missionUUID, drawerView = false }) => {
  const mission = useGetMissionByLoadId()
  const uuid = mission?.uuid ?? missionUUID
  const [connectivity, setConnectivity] = useState(onlineManager.isOnline())
  const queryClient = useQueryClient()
  const [offlineIds, setOfflineIds] = useState([])
  const { data: missionNotes, isLoading: notesIsLoading } = useGetMissionNotesQuery(uuid)

  const [offlinePostNotes, setOfflinePostNotes] = useState(() => {
    const initialNotes = !onlineManager.isOnline() && !notesIsLoading && missionNotes ? missionNotes : []

    const mutations = queryClient.getMutationCache().getAll()

    const pendingMutations = mutations.filter((mutation) => mutation?.state?.status === "pending")

    const pendingNotes = pendingMutations.map((mutation) => {
      const item = mutation?.state?.variables
      const note = item?.noteRequest

      return {
        body: note?.body,
        created_by: note?.created_by,
        user_creation_ts: note?.user_creation_ts || DateTime.now().toISO(),
        delay: note?.delay === "true",
        delay_value: note?.delay_value,
        mission_note_tags: note?.mission_note_tags,
        mission_note_type: note?.mission_note_type,
        expiration_ts: note?.expiration_ts || 0,
        pending: true,
        uuid: item?.noteUuid,
      }
    })
    return [...initialNotes, ...pendingNotes]
  })

  onlineManager.subscribe((onlineState) => {
    if (!onlineManager.isOnline()) {
      setOfflinePostNotes(missionNotes)
    } else {
      setOfflinePostNotes(missionNotes)
      setOfflineIds([])
    }
    setConnectivity(onlineState)
  })

  const updatePendingMutations = (event) => {
    var mutation = event?.mutation

    if (
      !connectivity &&
      mutation &&
      mutation.state.status == "pending" &&
      !offlineIds.some((id) => id === mutation.mutationId)
    ) {
      offlineIds.push(mutation.mutationId)

      var item = mutation.state.variables
      var note = item?.noteRequest
      const newNote = {
        body: note?.body,
        created_by: note?.created_by,
        user_creation_ts: note?.user_creation_ts || DateTime.now().toISO(),
        delay: note?.delay === "true",
        delay_value: note?.delay_value,
        mission_note_tags: note?.mission_note_tags,
        mission_note_type: note?.mission_note_type,
        expiration_ts: note?.expiration_ts || 0,
        pending: true,
      }

      setOfflinePostNotes((prevNote) => [...prevNote, newNote])
    }
  }

  useEffect(() => {
    const unsubscribeFn = queryClient.getMutationCache().subscribe(updatePendingMutations)

    return () => {
      unsubscribeFn()
    }
  }, [queryClient, connectivity])

  const isRestoring = useIsRestoring()

  const createMissionNotesRows = (notes) =>
    notes.map((note, i) => (
      <React.Fragment key={note.uuid}>
        <TableRow
          className="table-row no-border-bottom"
          style={{
            backgroundColor: note?.pending ? "#eefaff" : i % 2 === 0 ? "#ffffff" : "#f5f5f5",
          }}
        >
          <TableCell>{note.mission_note_type}</TableCell>
          <TableCell>
            <div>{getFormattedDate(note.user_creation_ts, "D", null)}</div>
            <div>{getTime(note.user_creation_ts)}</div>
          </TableCell>
          <TableCell>{note.delay && note.delay_value ? note.delay_value.toString() + " min" : "None"}</TableCell>
          <TableCell>
            {note.mission_note_tags.length === 0
              ? "-"
              : note.mission_note_tags.map((tag) => <Chip key={tag.uuid} label={tag.tag_name} size="small" />)}
          </TableCell>
          <TableCell>{note.created_by}</TableCell>
        </TableRow>
        <TableRow
          className="table-row no-border-top"
          style={{
            backgroundColor: note?.pending ? "#eefaff" : i % 2 === 0 ? "#ffffff" : "#f5f5f5",
          }}
        >
          <TableCell colSpan={5}>{note.body}</TableCell>
        </TableRow>
      </React.Fragment>
    ))

  return (
    <>
      <Box
        aria-label="notes table"
        className="table-container"
        data-testid="notes-table"
        sx={{ border: "1px solid #d7d7d7" }}
      >
        <TableContainer className="table-container">
          <Table aria-label="customized table" className="table">
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Note Type</TableCell>
                <TableCell>Time Stamp</TableCell>
                <TableCell>Delay</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>User</TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {!connectivity || (offlinePostNotes.length && isRestoring) ? (
                // offline or just coming back online and restoring with non-empty notes
                createMissionNotesRows(
                  offlinePostNotes?.sort(
                    (a, b) =>
                      DateTime.fromISO(b.user_creation_ts).toMillis() - DateTime.fromISO(a.user_creation_ts).toMillis(),
                  ),
                )
              ) : notesIsLoading ? (
                <LinearProgress sx={{ width: "90vw", minWidth: "640px" }} />
              ) : missionNotes ? (
                createMissionNotesRows(
                  missionNotes?.sort(
                    (a, b) =>
                      DateTime.fromISO(b.user_creation_ts).toMillis() - DateTime.fromISO(a.user_creation_ts).toMillis(),
                  ),
                )
              ) : null}
            </tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default MissionViewNotes
