import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Box, Typography } from "@mui/material"
import "./HealthDropdown.scss"
import ErrorIcon from "@mui/icons-material/Error"
import { IoMdWarning } from "react-icons/io"

const StatusTab = ({ value, componentStatus }) => {
  const iconStyles = (color, fontSize) => ({
    color,
    fontSize: fontSize || "1.3em",
    marginTop: "0.125em",
  })

  return (
    <Box className="service-status">
      <Box className="service-status__icon">
        {componentStatus === "ERROR" ? (
          <IoMdWarning data-testid="error-icon" style={{ color: "#fa3751", fontSize: "1.5em" }} />
        ) : componentStatus === "WARN" ? (
          <ErrorIcon fontSize="1.5em" data-testid="warn-icon" sx={iconStyles("#fa9a37")} />
        ) : (
          <CheckCircleIcon data-testid="ok-icon" sx={{ color: "#71bf5e", fontSize: "1.5em" }} />
        )}
      </Box>
      <Box className="service-status__service">
        <Typography>{value}</Typography>
      </Box>

      <Box className="service-status__status">
        <Typography
          sx={{
            color: componentStatus === "ERROR" ? "#fa3751" : componentStatus === "WARN" ? "#fa9a37" : "#71bf5e",
          }}
          id="service-status-operational"
        >
          {componentStatus === "ERROR" || componentStatus === "WARN" ? <strong>1 FAULT</strong> : "OPERATIONAL"}
        </Typography>
      </Box>

      {(componentStatus === "ERROR" && (
        <Box className="service-status__description">
          <Box className="service-status__description-health-alert-error" data-testid="health-alert-error">
            <Typography>
              <strong>Critical Health Alert:</strong> {value} service fault.
            </Typography>
          </Box>
        </Box>
      )) ||
        (componentStatus === "WARN" && (
          <Box className="service-status__description">
            <Box className="service-status__description-health-alert-warn" data-testid="health-alert-warn">
              <Typography>
                <strong>Health Alert:</strong> {value} service fault.
              </Typography>
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default StatusTab
