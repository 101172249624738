import { DatadogContext } from "@/framework/contexts/Datadog"
import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import { useGetVehicle } from "@/hooks/vehiclesV2Hooks"
import { Box, Container } from "@mui/material"
import _ from "lodash"
import { useContext } from "react"
import MissionDetailsCard from "./MissionDetailsCard"
import MissionDetailsTabs from "./MissionDetailsTabs"
import MissionOptions from "./MissionOptions"
import MissionVehicleDetailGrid from "./MissionVehicleDetailGrid"

const MissionDetailsV2 = ({ activeMission = null, drawerView = false }) => {
  const vehicle = useGetVehicle()
  const { telemetry } = useContext(DatadogContext)
  const mission = activeMission ?? useGetMissionByLoadId()

  telemetry.useViewContext("mission", _.pick(mission, ["uuid", "load_id", "state", "vehicle_id"]))

  return (
    <Container
      data-testid="mission-details"
      className="mission-details-v2"
      disableGutters
      sx={{ backgroundColor: "white" }}
    >
      <Box className="details-info-v2">
        <MissionDetailsCard key={mission?.uuid} mission={mission} />
        <MissionVehicleDetailGrid mission={mission} vehicle={vehicle} />
        <MissionOptions mission={mission} />
        <MissionDetailsTabs drawerView={drawerView} mission={mission} />
      </Box>
    </Container>
  )
}

export default MissionDetailsV2
