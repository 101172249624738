import MissionDetails from "@/features/missions/MissionDetails"
import MissionList from "@/features/missions/MissionList"
import MissionDetailsV2 from "@/features/missions/v2/MissionDetails"
import MissionListV2 from "@/features/missions/v2/MissionList"
import { tabRoutes } from "@/hooks/detailTabsHelper"
import { tabRoutesV2 } from "@/hooks/v2/detailTabsHelper"
import { Typography } from "@mui/material"
import { type RouteObject } from "react-router-dom"

const MissionRoutes: RouteObject = {
  path: "missions",
  children: [
    {
      path: "v2",
      children: [
        { index: true, element: <MissionListV2 /> },
        {
          path: ":load_id",
          element: <MissionDetailsV2 />,
          children: tabRoutesV2
            .filter((tab) => tab.matchRoute === "missions" || tab.matchRoute === "*")
            .map((tab) => ({
              path: tab.pathname,
              element: tab.element,
              errorElement: (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    margin: "1.5rem",
                  }}
                >
                  <Typography variant="overline">An error occurred while loading this tab.</Typography>
                  <Typography variant="overline">
                    Please refresh the page and contact developers if the issue persists.
                  </Typography>
                </div>
              ),
            })),
        },
      ],
    },
    { index: true, element: <MissionList /> },
    {
      path: ":load_id",
      element: <MissionDetails />,
      children: tabRoutes
        .filter((tab) => tab.matchRoute === "missions" || tab.matchRoute === "*")
        .map((tab) => ({
          path: tab.pathname,
          element: tab.element,
          errorElement: (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                margin: "1.5rem",
              }}
            >
              <Typography variant="overline">An error occurred while loading this tab.</Typography>
              <Typography variant="overline">
                Please refresh the page and contact developers if the issue persists.
              </Typography>
            </div>
          ),
        })),
    },
  ],
}

export default MissionRoutes
