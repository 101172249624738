/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  type GetVehicleConnectivityResponse as VehicleConnectivity,
  type GetVehicleStatusResponse as VehicleStatus,
} from "@torc-robotics/mm-vehicles-client"

/**
 * FIXME ! - the schema here is wrong, VehicleDetails is arbitrary, it needs to
 * be based on edge message type.
 */

export function getVehicleHealthBg(vehicleConnectivity: VehicleConnectivity | null): string {
  if (vehicleConnectivity === null) {
    return "transparent"
  }
  const background: string =
    vehicleConnectivity?.status === "disconnected"
      ? "#FFE0E4"
      : vehicleConnectivity?.status === "partially_connected"
        ? "#fce8ca"
        : "transparent"
  return background
}

export function getVehicleClassName(vehicleConnectivity: VehicleConnectivity | null): string {
  const health: string = vehicleConnectivity?.status ?? ""

  if (health === "disconnected") {
    return "error-tooltip"
  } else if (health === "partially_connected") {
    return "warning-tooltip"
  } else {
    return ""
  }
}

export function getVehicleHealthColor(
  vehicleConnectivity: VehicleConnectivity | null,
): "error" | "warning" | "inherit" | "primary" | "secondary" | "success" | "info" | "default" | undefined {
  const health: string = vehicleConnectivity?.status ?? ""

  if (health === "disconnected") {
    return "error"
  } else if (health === "partially_connected") {
    return "warning"
  } else {
    return "default"
  }
}

export function getVehicleBorderColor(
  vehicleConnectivity: VehicleConnectivity | null,
): "#FA3751" | "default" | "#FA9A37" | "transparent" {
  const health: string = vehicleConnectivity?.status ?? ""

  if (health === "disconnected") {
    return "#FA3751"
  } else if (health === "partially_connected") {
    return "#FA9A37"
  } else {
    return "transparent"
  }
}

export function getVehicleSpeed(vehicle: VehicleStatus | null): number | null {
  const mps = vehicle?.mps

  if (mps === undefined || mps === null || typeof mps !== "number") {
    return null
  } else {
    return Math.round(mps * 2.23694)
  }
}
