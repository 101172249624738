import useInitVehicleDataToStore from "@/hooks/useInitVehicleDataToStore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import { IconButton } from "@mui/material"
import "mapbox-gl/dist/mapbox-gl.css"
import useGetMissionsQuery from "@/hooks/query/mission/useGetMissionsQuery"
import useGetVehiclesV2Query from "@/hooks/query/vehicle/useGetVehiclesV2Query"
import useBeforeUnload from "@/hooks/useBeforeUnload"
import { onlineManager, useIsMutating, useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { matchRoutes, Outlet, useLocation, useNavigate } from "react-router-dom"
import TrafficMapVector from "../map/TrafficMapVector"
import TopBar from "../search/TopBar"
import "./MainLayout.scss"
import MissionDetailsHeader from "../missions/v2/MissionDetailsHeader"
import MissionFilters from "../search/v2/MissionFilters"
import ConnectivityBanner from "./ConnectivityBanner"

const MainLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const routes = [{ path: "/missions/v2/:load_id/*" }]
  const routeMatches = matchRoutes(routes, location)

  // connectivity can be [ online | offline | syncing ]
  // syncing is a special case of online
  const [connectivity, setConnectivity] = useState(onlineManager.isOnline() ? "online" : "offline")
  useInitVehicleDataToStore()
  const queryClient = useQueryClient()
  const numPendingMutations = useIsMutating({ queryClient: queryClient })

  const isWidePanel = useIsWidePanel()

  useGetVehiclesV2Query()
  useGetMissionsQuery()
  useBeforeUnload()

  if (connectivity === "syncing" && numPendingMutations === 0) {
    // we have synced all the pending mutations so go back to online state
    setConnectivity("online")
  }

  onlineManager.subscribe((isOnline) => {
    if (isOnline && numPendingMutations > 0) {
      // we are switching to online but there are pending mutations so
      // go to syncing state
      setConnectivity("syncing")
    } else {
      // we have either switched to online with no pending mutations or
      // switched to offline - in either case just go to that state
      setConnectivity(isOnline ? "online" : "offline")
    }
  })

  const gapClass = location.pathname === "/missions/v2" || routeMatches ? "gap-0" : "gap-1"

  useEffect(() => {
    if (location.pathname == "/") {
      navigate("/missions", { replace: true })
    }
  }, [location])

  const [expand, setExpand] = useState(0)

  return (
    <div
      role="mm-container"
      className={`mm-container ${connectivity} ${isWidePanel && !routeMatches && "mm-container--wide"} ${gapClass}`}
    >
      <ConnectivityBanner connectivity={connectivity} numMutations={numPendingMutations} />
      <div id="map-container">
        <TrafficMapVector />
      </div>
      {routeMatches && (
        <div id="mission-header">
          <MissionDetailsHeader />
        </div>
      )}
      {location.pathname === "/missions/v2" ? (
        <div id="mission-filters">
          <MissionFilters />
        </div>
      ) : (
        <TopBar />
      )}

      <div id="main-content" data-expand={expand} className={`main-content${isWidePanel && "--wide"}`}>
        <div id="expand-collapse">
          <IconButton id="max-btn" data-testid="max-btn" size="small" onClick={() => setExpand(2)}>
            <FullscreenIcon />
          </IconButton>
          <IconButton id="min-btn" data-testid="min-btn" size="small" onClick={() => setExpand(0)}>
            <FullscreenExitIcon />
          </IconButton>
          <IconButton
            id="expand-btn"
            data-testid="expand-btn"
            size="small"
            onClick={() => setExpand((prev) => Math.min(prev + 1, 2))}
          >
            <ExpandLessIcon />
          </IconButton>
          <IconButton
            id="collapse-btn"
            data-testid="collapse-btn"
            size="small"
            onClick={() => setExpand((prev) => Math.max(prev - 1, 0))}
          >
            <ExpandLessIcon sx={{ transform: "rotate(180deg)" }} />
          </IconButton>
        </div>

        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout

const useIsWidePanel = () => {
  const location = useLocation()
  const routes = [
    { path: "/missions", exact: true },
    { path: "/missions/v2", exact: true },
    { path: "/vehicles", exact: true },
    { path: "/hub", exact: true },
    { path: "*" },
  ]

  const [{ route }] = matchRoutes(routes, location)

  return route.path === "*"
}

export { useIsWidePanel }
