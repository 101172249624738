import useGetMeQuery from "@/hooks/query/user/useGetMeQuery"
import { useAuthenticator } from "@aws-amplify/ui-react"
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useRef, useState } from "react"
import { FaIdCard } from "react-icons/fa"
import { useUserClaims } from "../../hooks/UserHooks"

function ProfileMenu() {
  /* istanbul ignore next */
  const { user } = useAuthenticator((context) => [context.user])
  const [accessTokenCopySuccess, setAccessTokenCopySuccess] = useState("")
  const [idTokenCopySuccess, setIdTokenCopySuccess] = useState("")
  const [refreshTokenCopySuccess, setRefreshTokenCopySuccess] = useState("")

  const accessTokenRef = useRef(null)
  const idTokenRef = useRef(null)
  const refreshTokenRef = useRef(null)
  const userClaims = useUserClaims()

  const idToken = user.signInUserSession.idToken.jwtToken
  const accessToken = user.signInUserSession.accessToken.jwtToken
  const refreshToken = user.signInUserSession.refreshToken.token

  const { data: userData } = useGetMeQuery()

  //TODO, Clean these up and use { CopyToClipboard } from 'react-copy-to-clipboard'
  function copyAccessTokenToClipboard(e) {
    accessTokenRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    setAccessTokenCopySuccess("   Copied!   ")
  }

  function copyIdTokenToClipboard(e) {
    idTokenRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    setIdTokenCopySuccess("   Copied!   ")
  }

  function copyRefreshTokenToClipboard(e) {
    idTokenRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    setRefreshTokenCopySuccess("   Copied!   ")
  }

  return (
    <div id="profile-container" style={{ display: "grid", gap: "1rem" }}>
      <Typography variant="h5" data-testid="user-profile-header">
        <FaIdCard /> User Profile
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "400px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Claim</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="issuer">
              <TableCell>token_issuer</TableCell>
              <TableCell id="issuer">{user.pool.client.endpoint}</TableCell>
            </TableRow>
            <TableRow key="client-id">
              <TableCell>client_id</TableCell>
              <TableCell id="client-id">{user.pool.clientId}</TableCell>
            </TableRow>
            {Object.entries(userClaims).map((claimEntry) => {
              const claimName = claimEntry[0]
              const claimValue = Array.isArray(claimEntry[1]) ? claimEntry[1].join(", ") : claimEntry[1]
              const claimId = `claim-${claimName}`
              return (
                <TableRow key={claimName}>
                  <TableCell>{claimName}</TableCell>
                  <TableCell id={claimId}>{claimValue}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Copy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="access-token">
              <TableCell>access_token</TableCell>
              <TableCell id="access-token">
                <textarea readOnly ref={accessTokenRef} value={accessToken} />
              </TableCell>
              <TableCell>
                <Button
                  data-testid="copy-access-token-button"
                  id="copy-access-token-button"
                  variant="outlined"
                  onClick={copyAccessTokenToClipboard}
                >
                  Copy
                </Button>
                {accessTokenCopySuccess}
              </TableCell>
            </TableRow>
            <TableRow key="id-token">
              <TableCell>id_token</TableCell>
              <TableCell id="id-token">
                <textarea readOnly ref={idTokenRef} value={idToken} />
              </TableCell>
              <TableCell>
                <Button
                  data-testid="copy-id-token-button"
                  id="copy-id-token-button"
                  variant="outlined"
                  onClick={copyIdTokenToClipboard}
                >
                  Copy
                </Button>
                {idTokenCopySuccess}
              </TableCell>
            </TableRow>
            <TableRow key="refresh-token">
              <TableCell>refresh_token</TableCell>
              <TableCell id="refresh-token">
                <textarea readOnly ref={refreshTokenRef} value={refreshToken} />
              </TableCell>
              <TableCell>
                <Button
                  data-testid="copy-refresh-token-button"
                  id="copy-refresh-token-button"
                  variant="outlined"
                  onClick={copyRefreshTokenToClipboard}
                >
                  Copy
                </Button>
                {refreshTokenCopySuccess}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Info</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="roles">
              <TableCell>roles</TableCell>
              <TableCell>{userData?.display_roles ? userData?.display_roles.join(", ") : ""}</TableCell>
            </TableRow>
            <TableRow key="permissions">
              <TableCell>permissions</TableCell>
              <TableCell>{userData?.attributes ? userData?.attributes.join(", ") : ""}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ProfileMenu
