import { getJwtToken } from "@/framework/Api"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import axios from "axios"
import _ from "lodash"

const ROUTE_ADVISOR_API_URL = String(import.meta.env.VITE_ROUTE_ADVISOR_API_URL)
const MAX_ROUTE_ADVISOR_POLLING_ATTEMPTS = 20
const ROUTE_ADVISOR_WAIT_TIME = 5000

export const getRoutesAPICall = async (arrival, origin, map_version, vias) => {
  let location

  try {
    const response = await axios.post(
      `${ROUTE_ADVISOR_API_URL}/v1/async/route-requests`,
      JSON.stringify({
        map_version: map_version,
        origin: {
          type: "latlon",
          lat: origin.lat,
          lon: origin.lon,
        },
        destination: {
          type: "latlon",
          lat: arrival.lat,
          lon: arrival.lon,
        },
        vias: vias,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getJwtToken()}`,
        },
        cache: "default",
      },
    )

    location = response.headers["location"]
  } catch (err) {
    return { data: { message: err.message } }
  }

  return await getRoutes(location, origin)
}

export const getRoutes = async (location, origin) => {
  let attempts = 0
  let response

  while (attempts <= MAX_ROUTE_ADVISOR_POLLING_ATTEMPTS) {
    await new Promise((r) => setTimeout(r, ROUTE_ADVISOR_WAIT_TIME))

    try {
      response = await axios.get(location, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getJwtToken()}`,
        },
        cache: "default",
      })
    } catch (err) {
      return { data: { message: err.message } }
    }

    if (response.data.status === "error") {
      return { data: { message: getFriendlyRouteAdvisorErrorMessage(response.data, origin) } }
    } else if (response.data["routes"]) {
      return response
    } else {
      attempts += 1
      if (attempts >= MAX_ROUTE_ADVISOR_POLLING_ATTEMPTS) {
        throw new Error("Route advisor could not generate a route in time")
      }
    }
  }
}

const useGetRoutesQuery = (origin, arrival, map_version, vias, enabled) => {
  const viasData = vias ? vias : []
  const routeQuery = useQuery({
    queryKey: ["route-options", origin, viasData, arrival, map_version],
    queryFn: async () => await getRoutesAPICall(arrival, origin, map_version, viasData),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: enabled,
    placeholderData: keepPreviousData,
  })

  return routeQuery
}

export default useGetRoutesQuery

export const getFriendlyRouteAdvisorErrorMessage = (response, origin) => {
  const friendlyCode = _.startCase(_.toLower(response?.error_code || "Unknown Error"))
  switch (response?.error_code) {
    case "UNKNOWN_LOCATION":
      return `${friendlyCode}: Vehicle is not on or near a mapped route, please ensure the vehicle and all intermediate stops are close to a mapped route. Current vehicle coordinates are lat=${origin?.lat?.toFixed(5)}, lon=${origin?.lon?.toFixed(5)}.`
    default:
      return `${friendlyCode}: ${response?.error_message}`
  }
}
