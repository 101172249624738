import getToken from "@/framework/utils/api/getToken"
import { VehiclesApiClient } from "@torc-robotics/mm-vehicles-client"

const VehiclesApi = new VehiclesApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_VEHICLES_API_URL,
  TOKEN: getToken,
}).v1

export const VehiclesApiV2 = new VehiclesApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_VEHICLES_API_URL,
  TOKEN: getToken,
}).v2

export default VehiclesApi
