import MissionsApi, { MissionsApiV2 } from "@/framework/utils/api/MissionsAPI"
import usersApi from "@/framework/utils/api/UsersAPI"
import { usePostNoteMutation } from "@/hooks/query/notes/usePostNoteMutation"
import useMapStore from "@/store/useMapStore"
import EditIcon from "@mui/icons-material/Edit"
import NearMeIcon from "@mui/icons-material/NearMe"
import { Box, Button, Dialog, DialogActions, DialogTitle, Drawer } from "@mui/material"
import { onlineManager, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { DateTime } from "luxon"
import { useState } from "react"
import { toast } from "react-toastify"
import ConfirmationDialog from "../Notes/ConfirmationDialog"
import CreateNote from "../Notes/CreateNote"
import "./scss/MissionOptions.scss"
import CloseIcon from "@mui/icons-material/Close"
import { Icon } from "@mui/material"
import _ from "lodash"
import { useHotkeys } from "react-hotkeys-hook"
import ArrivalFlag from "../icons/ArrivalFlag"

const MissionOptions = ({ mission }) => {
  const queryClient = useQueryClient()

  const [showConfirmArrivalModal, setShowConfirmArrivalModal] = useState(false)
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)
  const [noteOpen, setNoteOpen] = useState(false)
  const [noteConfirm, setNoteConfirm] = useState(false)
  const setCentered = useMapStore((state) => () => state.setIsCentered(true))

  const disableAllowArrival = ["ARRIVED", "COMPLETED"].includes(mission?.state)
  const disableCancel = ["COMPLETED", "CANCELED"].includes(mission?.state)

  const { data: getMeData } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => await usersApi.getMeV1MeGet(),
  })

  const { mutate: postNoteMutate } = usePostNoteMutation()

  const arriveMutation = useMutation({
    mutationFn: () => MissionsApiV2.postMissionArrive({ missionUuid: mission?.uuid }),
    onSuccess: () => {
      toast.success("Successfully sent mission arrive request")
      queryClient.invalidateQueries({ queryKey: ["mission", mission?.uuid] })
    },
  })

  const cancelMutation = useMutation({
    mutationFn: () => MissionsApi.postCancelMission({ uuid: mission.mission?.uuid }),
    onSuccess: () => {
      toast.success("Successfully canceled mission")
      queryClient.invalidateQueries({ queryKey: ["mission", mission?.uuid] })
    },
  })

  const handleClose = () => {
    setNoteConfirm(false)
    setNoteOpen(false)
  }

  const confirmManualArrival = async () => {
    try {
      await arriveMutation.mutateAsync()
    } catch (err) {
      toast.error("Unable to request mission arrival.")
    }
    setShowConfirmArrivalModal(false)
  }

  const confirmCancelMission = async () => {
    try {
      await cancelMutation.mutateAsync()
    } catch (err) {
      toast.error("Unable to cancel mission.")
    }
    setShowConfirmCancelModal(false)
  }

  const addNewNote = (data) => {
    if (mission) {
      var postNote = {
        mission_note_type: data.noteType,
        mission_note_tags: data.noteTags,
        delay: data.isDelay.toString(),
        delay_value: data.delayValue,
        body: data.noteValue,
        created_by: _.get(getMeData, "email", ""),
        user_creation_ts: data.timestamp,
        vehicle_id: mission.vehicle_id,
      }

      postNoteMutate({ missionUuid: mission?.uuid, noteRequest: postNote })

      setNoteOpen(false)

      if (!onlineManager.isOnline()) {
        toast.info(`You are offline and have unsynced changes`)
      }
    }
  }
  useHotkeys(
    "meta+shift+n, meta+s, ctrl+shift+n, ctrl+s, ctrl+\\, meta+\\",
    (event) => {
      event.preventDefault()
      setNoteOpen(true)
    },
    { scope: "create-note" },
  )

  return (
    <Box>
      <Dialog open={showConfirmArrivalModal}>
        <DialogTitle data-testid="manual-arrival-dialog-title">
          Are you sure you want to manually arrive this mission?
        </DialogTitle>
        <DialogActions>
          <Button data-testid="confirm-manual-arrival" variant="contained" onClick={() => confirmManualArrival()}>
            Confirm
          </Button>
          <Button variant="outlined" onClick={() => setShowConfirmArrivalModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showConfirmCancelModal}>
        <DialogTitle data-testid="cancel-mission-dialog-title">
          Are you sure you want to cancel this mission?
        </DialogTitle>
        <DialogActions>
          <Button data-testid="confirm-cancel-mission" variant="contained" onClick={() => confirmCancelMission()}>
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => setShowConfirmCancelModal(false)}
            data-testid="close-mission-dialog"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer anchor="right" open={noteOpen}>
        <ConfirmationDialog open={noteConfirm} onClose={() => setNoteConfirm(false)} onAgree={handleClose} />
        <CreateNote
          onClose={() => setNoteConfirm(true)}
          addNewNote={addNewNote}
          timestamp={DateTime.now().toISO()}
          priorNote={null}
        />
      </Drawer>

      <Box id="mission-options-container">
        {mission?.state == "IN_PROGRESS" ? (
          <Button
            data-testid="open-mission-arrival"
            variant="outlined"
            disabled={disableAllowArrival}
            onClick={() => setShowConfirmArrivalModal(true)}
            className="mission-button"
          >
            <Icon className="icon" sx={{ display: "flex", alignItems: "center" }}>
              <ArrivalFlag flagColor={"#02a7f0"} width={"23px"} iconHeight={"20px"} />
            </Icon>
            MANUAL ARRIVAL
          </Button>
        ) : (
          <Button
            variant="outlined"
            data-testid="cancel-mission"
            disabled={disableCancel}
            onClick={() => setShowConfirmCancelModal(true)}
            className="mission-button"
          >
            <CloseIcon className="icon" />
            CANCEL MISSION
          </Button>
        )}

        <Button
          variant="outlined"
          className="mission-button"
          data-testid="find"
          onClick={() => {
            setCentered()
          }}
        >
          <NearMeIcon className="icon" />
          FIND ON MAP
        </Button>

        <Button
          variant="outlined"
          onClick={() => setNoteOpen(true)}
          className="mission-button"
          data-testid="new-mission-note"
        >
          <EditIcon className="icon" />
          MISSION NOTES
        </Button>
      </Box>
    </Box>
  )
}

export default MissionOptions
