import { Icon } from "@mui/material"
import ArrivalFlag from "./ArrivalFlag"
import { IconTooltip } from "./IconUtils"

export default function ArrivingIcon({
  backgroundColor = "#DBF1FE",
  flagColor = "#0066b0",
  width = "23px",
  iconHeight = "18px",
  ...props
}) {
  return (
    <IconTooltip title="Arriving Soon">
      <Icon
        sx={{
          width: width,
          height: width,
          backgroundColor: { backgroundColor },
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          marginTop: "0.125em",
        }}
      >
        <ArrivalFlag flagColor={flagColor} width={width} iconHeight={iconHeight} {...props} />
      </Icon>
    </IconTooltip>
  )
}
