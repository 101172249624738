import { MissionsApiV2 } from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionLocations = (missionUuid) => {
  return useQuery({
    queryKey: ["mission-locations", missionUuid],
    queryFn: async () => await MissionsApiV2.getMissionLocationsV2({ missionUuid }),
    enabled: !isEmpty(missionUuid),
    refetchInterval: 10000,
    meta: {
      persist: false,
    },
  })
}

export default useGetMissionLocations
