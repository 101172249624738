/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import useVehicleStore from "@/store/useVehicleStore"
import { useEffect } from "react"
import { useGetVehicle, useGetVehicleStatus } from "./vehiclesV2Hooks"

const useInitVehicleDataToStore = () => {
  const vehicle = useGetVehicle()
  const vehicleStatus = useGetVehicleStatus(vehicle?.vehicle_id)
  const updateVehicleRecord = useVehicleStore((state) => state.updateVehicleRecord)

  useEffect(() => {
    if (vehicle != null && vehicleStatus?.lat != null && vehicleStatus?.lon != null) {
      updateVehicleRecord({
        [vehicle.vehicle_id]: {
          vehicle_id: vehicle?.vehicle_id,
          data: {
            coordinates: {
              latitude: vehicleStatus?.lat,
              longitude: vehicleStatus?.lon,
            },
          },
        },
      })
    }
  }, [vehicleStatus])
}

export default useInitVehicleDataToStore
