import CloseIcon from "@mui/icons-material/Close"
import DescriptionIcon from "@mui/icons-material/Description"
import { Box, IconButton, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Form } from "@rjsf/mui"
import validator from "@rjsf/validator-ajv8"
import _ from "lodash"
import { useContext, useMemo, useRef, useState } from "react"
import ReviewCheckbox from "../review-checkbox/ReviewCheckbox"
import ErrorListTemplate from "./templates/ErrorListTemplate"
import HyperLink from "./widgets/HyperLink"
import "./Form.scss"
import TaskForm, { TaskFormContext } from "./TaskForm"

/**
 *
 * @param {{
 *   vehicle: import("@torc-robotics/mm-vehicles-client").VehicleDetails
 * }} props
 * @returns
 */
const ActionForm = (props) => {
  return (
    <TaskForm {...props}>
      <FormInternal {...props} />
    </TaskForm>
  )
}

const FormInternal = ({ mission, action, vehicle }) => {
  const [didSubmit, setDidSubmit] = useState(false)
  const { onSubmit, onSave, onCancel } = useContext(TaskFormContext)

  const theme = useTheme()

  const initActionState = {
    action: action,
  }

  const [actionState, setActionState] = useState(initActionState)
  const formRef = useRef()

  useMemo(() => {
    if (action) {
      let template = _.get(actionState, "action.template", {})

      if (_.get(template, "properties.testID")) {
        template.properties.testID["default"] = _.get(mission, "load_id")
      }

      if (_.get(template, "properties.reportVersion")) {
        template.properties.reportVersion["default"] = actionState.action.version + "-" + actionState.action.vehicle_gen
      }

      if (_.get(template, "properties.location")) {
        template.properties.location["default"] = _.get(mission, "start_display")
      }

      if (_.get(template, "properties.date")) {
        template.properties.date["default"] = _.get(mission, "start_time")
      }

      if (_.get(template, "properties.vehicle_id")) {
        template.properties.vehicle_id["default"] = _.get(mission, "vehicle_id")
      }

      if (_.get(template, "properties.sc")) {
        template.properties.sc["default"] = _.get(mission, "safety_conductor_email")
      }

      if (_.get(template, "properties.sd")) {
        template.properties.sd["default"] = _.get(mission, "safety_driver_email")
      }

      actionState.action.template = template
      setActionState(actionState)
    }
  }, [mission])

  const onSubmitAction = async () => {
    const form = formRef.current
    setDidSubmit(true)
    const { errors } = await form.validate(form.state.formData)

    if (_.isEmpty(errors)) {
      onSubmit(actionState.action)
    }
  }

  const onSaveAction = () => {
    onSave(actionState.action)
  }

  const setComment = (comment) => {
    action.comment = comment
  }
  const widgets = {
    HyperLink: HyperLink,
  }

  return action ? (
    <Box>
      <Box className="action-form-header">
        <DescriptionIcon sx={{ color: theme.palette.background.default, mt: 8 }} fontSize="large" />
        <Typography variant="h6" data-testid="New-Form" sx={{ color: theme.palette.background.default, mt: 8 }}>
          {_.get(action, "title", "")} v.{actionState?.action?.version}-{actionState?.action?.vehicle_gen} -{" "}
          {mission.vehicle_id}
        </Typography>
        <IconButton
          sx={{ marginLeft: "auto", color: theme.palette.background.default, mt: 8 }}
          onClick={onCancel}
          data-testid="close-action-form"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="create-form" data-testid="create-form" sx={{ overflowY: "auto", paddingBottom: "64px" }}>
        <Form
          schema={actionState.action.template}
          uiSchema={actionState.action.template_ui}
          validator={validator}
          formData={actionState.action.formData}
          liveValidate={didSubmit}
          role="form"
          name="ActionForm"
          ref={formRef}
          disabled={actionState?.action?.state == "COMPLETED"}
          onChange={(e) => {
            actionState.action.formData = e.formData
            setActionState(actionState)
          }}
          focusOnFirstError={false}
          widgets={widgets}
          formContext={{
            type: actionState?.action?.type,
          }}
          templates={{ ErrorListTemplate }}
        >
          <ReviewCheckbox
            action={action}
            onclose={onSaveAction}
            onSubmit={onSubmitAction}
            setComment={setComment}
            notesText="Comments"
            checkboxText="I certify the above inspection results are acceptable for departure"
            closeText="Save - Finish Later"
            submitText="Submit"
          />
        </Form>
      </Box>
    </Box>
  ) : (
    <Typography data-testid="not-supported">Action for Truck Gen {vehicle?.gen} Not Supported.</Typography>
  )
}

export default ActionForm
