import { initViewstate } from "@/features/map/TrafficMapVector"
import useMapStore from "@/store/useMapStore"
import useVehicleStore from "@/store/useVehicleStore"
import { useMediaQuery } from "@mui/material"
import { useEffect, useMemo, useRef } from "react"
import useGetElementDimensions from "../utils/useGetElementDimensions"
import { useGetVehicle } from "../vehiclesV2Hooks"

/**
 * useCenteredVehicle - handles all aspects of centering the map on a vehicle
 */
const useCenteredVehicle = () => {
  const mapRef = useMapStore((state) => state.mapRef.current)
  const vehicleId = useGetVehicle()?.vehicle_id
  const selectedVehicle = useVehicleStore((state) => state.vehicles.entities[vehicleId] ?? null)

  /* istanbul ignore next */
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"))
  const { width, height } = useGetElementDimensions("main-content")

  // mapPadding is the amount of padding to add to the map, handles responsiveness
  // TODO - this maybe should be moved to its own hook
  const mapPadding = useMemo(() => {
    let padding = {}
    if (isSmall) {
      padding = { left: 0, bottom: height }
    } else {
      padding = { left: width, bottom: 0 }
    }
    return padding
  }, [width, height, isSmall])

  useEffect(() => {
    if (!mapRef) return
    mapRef.easeTo({
      padding: mapPadding,
      duration: 100,
    })
  }, [mapPadding])

  // vehicleCenter is the center of the vehicle, if it exists, otherwise null
  const vehicleCenter = useMemo(() => {
    if (!selectedVehicle?.data?.coordinates?.longitude || !selectedVehicle?.data?.coordinates?.latitude) {
      return null
    } else {
      return [selectedVehicle?.data?.coordinates?.longitude, selectedVehicle?.data?.coordinates?.latitude]
    }
  }, [selectedVehicle?.data?.coordinates?.longitude, selectedVehicle?.data?.coordinates?.latitude])
  const prevVehicleCenter = useRef(vehicleCenter)

  // isCentered is a boolean that determines if the map should be centered on the vehicle
  const isCentered = useMapStore((state) => state.isCentered)
  const prevIsCentered = useRef(isCentered)
  const setIsCentered = useMapStore((state) => state.setIsCentered)

  // handles setting isCentered to true when vehicleCenter is set or false when vehicleCenter is null
  useEffect(() => {
    vehicleCenter && !prevVehicleCenter.current && setIsCentered(true)
    !vehicleCenter && setIsCentered(false)
    prevVehicleCenter.current = vehicleCenter
  }, [vehicleCenter])

  // handles centering the map on init viewstate or vehicleCenter (with or without zoom)
  useEffect(() => {
    if (!mapRef || !mapRef.loaded()) return
    if (!vehicleCenter) {
      mapRef.jumpTo({
        center: [initViewstate.longitude, initViewstate.latitude],
        zoom: initViewstate.zoom,
        padding: mapPadding,
      })
    } else if (vehicleCenter && isCentered) {
      mapRef.jumpTo({
        center: vehicleCenter,
        ...(prevIsCentered.current !== isCentered ? { zoom: 14 } : {}),
      })
    }
    prevIsCentered.current = isCentered
  }, [vehicleCenter, isCentered])
}
export default useCenteredVehicle
