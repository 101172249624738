import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React from "react"

const ConfirmationDialog = ({ open, onClose, onAgree }) => {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{"Create Note"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Are you sure you want to close the window?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Disagree
        </Button>
        <Button variant="contained" onClick={onAgree} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
