import { Grid, Typography } from "@mui/material"
import _ from "lodash"

const MissionFreight = ({ departure }) => {
  return (
    <Grid container direction="row" columns={3}>
      <Grid item xs={1}>
        <Typography className="data-header" variant="caption">
          Freight Billing Number
        </Typography>
        <Typography className="display-data">{_.get(departure, "tms_freight_bill_number", "---")}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography className="data-header" variant="caption">
          Trailer ID
        </Typography>
        <Typography className="display-data">{_.get(departure, "trailer_id", "---")}</Typography>
      </Grid>
    </Grid>
  )
}

export default MissionFreight
