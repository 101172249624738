import { TabContext, TabPanel } from "@mui/lab"
import { Tab, Tabs } from "@mui/material"
import ProfileMenu from "./ProfileMenu"

const SettingsPage = () => {
  // const [tab, setTab] = useState("profile")
  const tab = "profile"

  let settingsTabs = [
    {
      key: "profile",
      title: "User Profile",
      component: <ProfileMenu />,
    },
  ]

  return (
    <div id="main-container">
      <div>
        <TabContext value={tab}>
          {/* TODO add this back in when there's more than one tab */}
          {/* <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}> */}
          <Tabs value={tab}>
            {settingsTabs.map((tab) => (
              <Tab
                label={tab.title}
                value={tab.key}
                key={tab.key}
                data-testid={tab.title}
                data-dd-action-name={tab.title}
              />
            ))}
          </Tabs>
          {settingsTabs.map((tab) => (
            <TabPanel value={tab.key} key={`content-${tab.key}`}>
              <div>{tab.component}</div>
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </div>
  )
}

export default SettingsPage
