import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMapVersionQuery = (vehicleId) => {
  const mapVersionQuery = useQuery({
    queryKey: ["vehicles-map-version", vehicleId],
    queryFn: async () =>
      await VehiclesApiV2.getVehicleVersionV2VehiclesVehicleIdVersionsVersionIdGet({
        vehicleId: vehicleId,
        versionId: "$latest",
      }),
    enabled: !isEmpty(vehicleId),
  })

  return mapVersionQuery
}

export default useGetMapVersionQuery
