import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetNextMissionQuery = (missionUUID: string) => {
  const missionQuery = useQuery({
    queryKey: ["missions", missionUUID],
    queryFn: async () => await MissionsApi.getMissionUpcoming({ uuid: missionUUID }),
    enabled: !isEmpty(missionUUID),
  })

  return missionQuery
}

export default useGetNextMissionQuery
