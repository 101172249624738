import LoadScreen from "@/components/LoadScreen"
import { ActionsApiV2 } from "@/framework/utils/api/ActionsAPI"
import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import useGetMeQuery from "@/hooks/query/user/useGetMeQuery"
import { useGetVehicle } from "@/hooks/vehiclesV2Hooks"
import { Box } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import _ from "lodash"
import { DateTime } from "luxon"
import { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import ActionForm from "../forms/ActionForm"
import ReportForm from "../forms/ReportForm"
import ReviewForm from "../forms/ReviewForm"
import RequestForm from "../forms/v2/RequestForm"

const ActionDashboard = ({ task, onClose }) => {
  const vehicle = useGetVehicle()
  const mission = useGetMissionByLoadId()
  const queryClient = useQueryClient()
  const { data: userData } = useGetMeQuery()

  const [isLoading, setIsLoading] = useState(false)
  const [currentTask, setCurrentTask] = useState(task)

  const mutation = useMutation({
    mutationKey: ["missions", mission?.uuid, "forms"],
    mutationFn: ({ uuid, action }) => ActionsApiV2.putActionV2({ actionUuid: uuid, formData: action }),
    onSuccess: (data) => {
      toast.success("Successfully updated mission action " + _.get(data, "uuid", ""), {
        toastId: "action-success",
      })
      queryClient.invalidateQueries({ queryKey: ["mission-actions", mission?.uuid] })
      if (_.get(data, "state") == "INCOMPLETE") {
        setIsLoading(false)
        setCurrentTask(data)
      }
    },
    onError: (err) => {
      if (err?.status == 409) {
        setIsLoading(false)
        const errorDetails = err?.body?.detail
        const errorMessage = errorDetails?.message
        const blockingLoadId = errorDetails?.load_id
        const toastMessage = (
          <div>
            {errorMessage}; Please complete all open tasks for&nbsp;
            <Link
              to={`/missions/${encodeURIComponent(blockingLoadId)}/tasks`}
              style={{ pointerEvents: !blockingLoadId ? "none" : "" }}
            >
              {blockingLoadId}
            </Link>
            &nbsp;to begin pre-departure actions for this mission
          </div>
        )
        toast.warn(toastMessage, { theme: "light", autoClose: 10000 })
        onClose()
      } else {
        toast.error(`An error occured: ${_.get(err?.body, "detail", "Unable to update action.")}`)
      }
    },
  })

  useMemo(() => {
    if (currentTask.state == "NOT STARTED") {
      setIsLoading(true)
      let new_task = { ...currentTask }
      new_task.state = "INCOMPLETE"
      new_task.assigned_to = userData?.email
      new_task.assigned_at_ts = DateTime.now().toMillis()

      mutation.mutate({
        uuid: task.uuid,
        action: { action: new_task },
      })
    }
  }, [currentTask.state])

  const onSubmit = (task) => {
    let new_task = { ...task }
    new_task.state = "COMPLETED"
    onSave(new_task)
  }

  const onSave = (task) => {
    mutation.mutate({
      uuid: task.uuid,
      action: { action: task },
    })

    onCancel()
  }

  const onCancel = () => {
    onClose()
  }

  const actionForm = useMemo(() => {
    if (currentTask.type == "form") {
      return (
        <ActionForm
          action={currentTask}
          mission={mission}
          vehicle={vehicle}
          onSubmit={onSubmit}
          onSave={onSave}
          onCancel={onCancel}
        />
      )
    } else if (currentTask.type == "report") {
      return (
        <ReportForm
          missionId={mission.uuid}
          loadId={mission.load_id}
          action={currentTask}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )
      //Remove response type once cleaned up
    } else if (
      currentTask.type == "review" ||
      (currentTask.type == "response" && currentTask.mission_state === "REVIEWING")
    ) {
      return <ReviewForm action={currentTask} onSubmit={onSubmit} onSave={onSave} onCancel={onCancel} />
    } else if (
      currentTask.type == "request" ||
      (currentTask.type == "response" &&
        (currentTask.mission_state === "CLEARED" || currentTask.mission_state == "WAIT_ACCEPT"))
    ) {
      return (
        <RequestForm action={currentTask} mission={mission} vehicle={vehicle} onSubmit={onSubmit} onCancel={onCancel} />
      )
    } else {
      console.error(`action type ${currentTask.type} not supported.`)
      return null
    }
  }, [currentTask.type, currentTask.state])

  return !isLoading ? <Box sx={{ height: "100%" }}>{actionForm}</Box> : <LoadScreen />
}

export default ActionDashboard
