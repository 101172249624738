import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetVehicleConnectivityV2Query = (vehicleId: string) => {
  const vehicleConnectivityV2Query = useQuery({
    queryKey: ["vehicle_connectivity", vehicleId],
    queryFn: async () => await VehiclesApiV2.getVehicleConnectivityV2VehiclesVehicleIdConnectivityGet({ vehicleId }),
    retry: 3,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !isEmpty(vehicleId),
  })

  return vehicleConnectivityV2Query
}

export default useGetVehicleConnectivityV2Query
