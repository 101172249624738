import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { useLayoutEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import useGetMissionsQuery from "../../hooks/query/mission/useGetMissionsQuery"
import MissionListCard from "./MissionListCard"
import "./scss/MissionList.scss"

const MissionList = () => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useGetMissionsQuery()

  const scrollElement = useScrollElement()

  return (
    <Box id="missionList" data-testid="mission-list-box">
      {scrollElement &&
        (!isLoading ? (
          <InfiniteScroll
            dataLength={data?.pages.reduce((acc, page) => acc + page.missions.length, 0) || 0}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<LinearProgress />}
            scrollableTarget={scrollElement}
            endMessage={
              data?.pages.reduce((acc, page) => acc + page.missions.length, 0) > 0 ? (
                <Typography
                  data-testid="End-of-results"
                  sx={{ display: "flex", justifyContent: "center" }}
                  variant="overline"
                >
                  End of results
                </Typography>
              ) : (
                <Typography
                  data-testid="No-Missions"
                  sx={{ display: "flex", justifyContent: "center" }}
                  variant="overline"
                >
                  No missions found matching criteria
                </Typography>
              )
            }
          >
            <Stack data-testid="missions-list" spacing={0.5}>
              {data?.pages.map((page) =>
                page.missions?.map((mission) => <MissionListCard key={mission?.uuid} mission={mission} />),
              )}
            </Stack>
          </InfiniteScroll>
        ) : (
          <LinearProgress sx={{ m: 2 }} />
        ))}
    </Box>
  )
}

export default MissionList

/**
 * useScrollElement
 * @param {string} id - id of the element to get the parent of
 * @returns
 */
const useScrollElement = (id = "missionList") => {
  const [scrollElement, setScrollElement] = useState()

  useLayoutEffect(() => {
    const element = document.getElementById(id)?.parentNode
    setScrollElement(element)
  }, [])

  return scrollElement
}
