import MissionsApi from "@/framework/utils/api/MissionsAPI"
import VehiclesApi from "@/framework/utils/api/VehiclesAPI"
import { type NotificationMessage } from "@/models/iot/Notification"
import { useQueryClient } from "@tanstack/react-query"
import useSubscription from "./useSubscription"

const useNotificationSubscription = (): void => {
  const queryClient = useQueryClient()

  useSubscription<NotificationMessage>({
    topic: ["mission_management/to_portal/v2.1.0/Notification"],
    next: (message) => {
      const missionUUID: string = message.data.mission_uuid ?? ""
      const vehicleId: string = message.data.vehicle_id ?? ""

      if (missionUUID !== "") {
        MissionsApi.getMission({ uuid: missionUUID })
          .then(async () => {
            await queryClient.invalidateQueries({ queryKey: ["missions"] })
            await queryClient.invalidateQueries({ queryKey: ["mission-actions"] })
          })
          .catch((err) => {
            console.warn(err)
          })
        MissionsApi.getDepartureRequests({ missionUuid: missionUUID })
          .then(async () => {
            await queryClient.invalidateQueries({ queryKey: ["departure-requests"] })
          })
          .catch((err) => {
            console.warn(err)
          })
      } else if (vehicleId !== "") {
        VehiclesApi.getVehicleDetails({ vehicleId })
          .then(async (vehicle) => {
            await queryClient.invalidateQueries({ queryKey: ["vehicles", vehicleId] })
          })
          .catch((err) => {
            console.warn(err)
          })
      }
    },
  })
}
export default useNotificationSubscription
