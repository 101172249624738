import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"

const useGetVehiclesV2Query = () => {
  const cursor = null
  const count = "1000"
  return useQuery({
    queryKey: ["getVehiclesV2", cursor, count],
    queryFn: async () => await VehiclesApiV2.getVehiclesV2({ cursor, count }),
  })
}

export default useGetVehiclesV2Query
