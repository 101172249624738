import { Route, Routes } from "react-router-dom"
import HubSearch from "./HubSearch"
import MissionSearch from "./MissionSearch"
import VehicleSearch from "./VehicleSearch"

const SearchBar = () => {
  return (
    <Routes>
      <Route path="/missions">
        <Route index element={<MissionSearch />} />
      </Route>
      <Route path="/vehicles">
        <Route index element={<VehicleSearch />} />
      </Route>
      <Route path="/hub">
        <Route index element={<HubSearch />} />
      </Route>
    </Routes>
  )
}

export default SearchBar
