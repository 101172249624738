import "./css/CustomToggleFieldTemplate.scss"

const CustomToggleFieldTemplate = (props) => {
  const { id, title, description, required, schema } = props

  return (
    <div className={`toggle_group_template`}>
      {title && (
        <label className="toggle_group_template__title" id={id}>
          {required ? "* " : null}
          {title}
        </label>
      )}
      <div className={`toggle_group_content`}>
        <div className={`toggle_group_content__${schema.name}`}>
          {description && <p className="description">{description}</p>}
          {props.properties.map((element) => (
            <div className={`toggle_group_content__${schema.name}__item`} id={element.name} key={element.name}>
              {element.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CustomToggleFieldTemplate
