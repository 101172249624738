import { useEffect, useState } from "react"

const useDebouncedValues = <T>(values: T, delay: number): T => {
  const [debouncedValues, setDebouncedValues] = useState<T>(values)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValues(values)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [values, delay])

  return debouncedValues
}

export default useDebouncedValues
