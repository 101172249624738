import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetDepartureRequestsQuery = (missionUUID) => {
  const departureRequestsQuery = useQuery({
    queryKey: ["departure-requests", missionUUID],
    queryFn: () => MissionsApi.getDepartureRequests({ missionUuid: missionUUID }),
    enabled: !isEmpty(missionUUID),
  })

  return departureRequestsQuery
}

export default useGetDepartureRequestsQuery
