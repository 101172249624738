import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetV2VehicleStatus = (vehicleId: string, trigger = true) => {
  const locationQuery = useQuery({
    queryKey: ["status", vehicleId],
    queryFn: async () => await VehiclesApiV2.getVehicleStatusV2VehiclesVehicleIdStatusGet({ vehicleId }),
    retry: 3,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !isEmpty(vehicleId) && trigger,
  })
  return locationQuery
}

export default useGetV2VehicleStatus
