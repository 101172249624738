import { Box } from "@mui/material"

const MissionTimeline = ({ height = 10, borderRadius = 15, details, actions = [] }) => {
  const colorMap = {
    Complete: "rgba(117, 187, 67, 1)",
    "Not Started": "rgba(215, 215, 215, 1)",
    "In Progress": "#42a5f5",
    Error: "#fa3751",
  }

  const inProgressTasks = actions?.filter((action) => action?.state === "INCOMPLETE")

  const completedTasks = actions?.filter((action) => action?.state === "COMPLETED")

  const notStartedTasks = actions?.length - completedTasks.length - inProgressTasks.length

  const inProgressTasksIndex = (index) => {
    return index + completedTasks.length
  }

  const notStartedTasksIndex = (index) => {
    return index + completedTasks.length + inProgressTasks.length
  }

  return (
    <>
      {completedTasks.map((task, index) => (
        <Box sx={{ flexGrow: 1 }} key={index}>
          <Box
            border={0}
            data-testid={`completed-tasks-${index}`}
            sx={{
              backgroundColor: colorMap["Complete"],
              height: height,
              borderTopLeftRadius: index === 0 && details ? borderRadius : 0,
              borderBottomLeftRadius: index === 0 && details ? borderRadius : 0,
              borderTopRightRadius: index === actions.length - 1 && details ? borderRadius : 0,
              borderBottomRightRadius: index === actions.length - 1 && details ? borderRadius : 0,
            }}
          />
        </Box>
      ))}

      {inProgressTasks.map((task, index) => (
        <Box sx={{ flexGrow: 1 }} key={inProgressTasksIndex(index)}>
          <Box
            border={0}
            data-testid={`inprog-tasks-${inProgressTasksIndex(index)}`}
            sx={{
              backgroundColor: "#009afa",
              height: height,
              borderTopLeftRadius: inProgressTasksIndex(index) === 0 && details ? borderRadius : 0,
              borderBottomLeftRadius: inProgressTasksIndex(index) === 0 && details ? borderRadius : 0,
              borderTopRightRadius: inProgressTasksIndex(index) === actions.length - 1 && details ? borderRadius : 0,
              borderBottomRightRadius: inProgressTasksIndex(index) === actions.length - 1 && details ? borderRadius : 0,
            }}
          />
        </Box>
      ))}

      {Array.from({ length: notStartedTasks }).map((_, index) => (
        <Box sx={{ flexGrow: 1 }} key={notStartedTasksIndex(index)}>
          <Box
            border={0}
            data-testid={`notstarted-tasks-${notStartedTasksIndex(index)}`}
            sx={{
              backgroundColor: "#dfdfdf",
              height: height,
              borderTopLeftRadius: notStartedTasksIndex(index) === 0 && details ? borderRadius : 0,
              borderBottomLeftRadius: notStartedTasksIndex(index) === 0 && details ? borderRadius : 0,
              borderTopRightRadius: notStartedTasksIndex(index) === actions.length - 1 && details ? borderRadius : 0,
              borderBottomRightRadius: notStartedTasksIndex(index) === actions.length - 1 && details ? borderRadius : 0,
            }}
          />
        </Box>
      ))}
    </>
  )
}

export default MissionTimeline
