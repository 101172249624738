import { ActionsApiV2 } from "@/framework/utils/api/ActionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

export default function useGetActionsForMissionQuery(uuid: string) {
  const missionQuery = useQuery({
    queryKey: ["mission-actions", uuid],
    queryFn: async () => await ActionsApiV2.getActionsV2ActionsGet({ missionUuid: uuid }),
    enabled: !isEmpty(uuid),
  })

  return missionQuery
}
