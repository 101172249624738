import { getFormattedDate } from "@/framework/Utils"
import { ArrowRightAlt, SportsScore } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useContext, useMemo } from "react"
import { FaShippingFast } from "react-icons/fa"
import { Link } from "react-router-dom"
import ReportMap from "../reports/ReportMap"
import "../reports/css/Report.scss"
import { formatTimeLeft, getOnTimeClass, getOnTimeText } from "../reports/utils/utils"
import ReviewCheckbox from "../review-checkbox/ReviewCheckbox"
import TaskForm, { TaskFormContext } from "./TaskForm"

const ReportForm = (props) => {
  return (
    <TaskForm {...props}>
      <FormInternal {...props} />
    </TaskForm>
  )
}

const FormInternal = (props) => {
  const { action, loadId, missionId } = props
  const { onSubmit, onCancel } = useContext(TaskFormContext)
  const theme = useTheme()

  const arrivalReport = action?.formData ?? {}

  const setComment = (comment) => {
    action.comment = comment
  }

  const notes = useMemo(() => {
    return arrivalReport.notes
      ? arrivalReport.notes.map((note) => {
          return (
            <TableRow key={note.uuid} note={note}>
              <TableCell>
                <Typography>{note.body}</Typography>
              </TableCell>
              <TableCell>{getTags(note.mission_note_tags)}</TableCell>
              <TableCell>{note.created_by}</TableCell>
              <TableCell>{getFormattedDate(note.creation_date)}</TableCell>
              <TableCell>{note.delay ? `${note.delay_value} min` : "no"}</TableCell>
            </TableRow>
          )
        })
      : null
  }, [arrivalReport])

  function getTags(tags) {
    return (
      <div>
        {tags.map((tag) => {
          return <Chip key={tag.uuid} label={tag.tag_name} size="small" />
        })}
      </div>
    )
  }

  const onSubmitAction = () => onSubmit(action)

  return action ? (
    <Box>
      <Box className="report">
        <Box className="report__header">
          <SportsScore sx={{ color: theme.palette.background.default }} fontSize="large" />
          <Typography variant="h6" data-testid="report-header" sx={{ color: theme.palette.background.default }}>
            Arrival Report
          </Typography>
          <IconButton
            sx={{ marginLeft: "auto", color: theme.palette.background.default }}
            onClick={onCancel}
            data-testid="close-action-form"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box className="report__timeliness">
          <Typography>{loadId}</Typography>
          {arrivalReport.quick_turnaround ? (
            <div className="report__timeliness__indicator" data-testid="quick-turn-around">
              <FaShippingFast />
              QUICK TURN-AROUND
            </div>
          ) : null}
        </Box>
        <Box className="report__overview">
          <Typography className="title">Overview</Typography>
          <Box className="report__overview__grid">
            <div className="report__overview__grid__truckId item">
              <Typography className="item__label">Truck Id</Typography>
              <Typography className="item__value">{arrivalReport.vehicle_id}</Typography>
            </div>
            <div className="report__overview__grid__total-miles item">
              <Typography className="item__label">Total Miles Traveled</Typography>
              <Typography className="item__value">
                {arrivalReport.total_miles ? `${Math.round(arrivalReport.total_miles)} miles` : "N/A"}
              </Typography>
            </div>
            <div className="report__overview__grid__trailerId item">
              <Typography className="item__label">Trailer Id</Typography>
              <Typography className="item__value">
                {arrivalReport.trailer_id ? arrivalReport.trailer_id : "N/A"}
              </Typography>
            </div>
            <div className="report__overview__grid__total-durarion item">
              <Typography className="item__label">Total Duration</Typography>
              <Typography className="item__value">
                {arrivalReport.total_mission_duration ? formatTimeLeft(arrivalReport.total_mission_duration) : "N/A"}
              </Typography>
            </div>
          </Box>
          <Box className="report__details">
            <Typography className="title">Mission Details</Typography>
            <Box className="report__details__grid">
              <div className="report__details__grid__departure item">
                <Typography className="item__label">Departure Hub</Typography>
                <Typography className="item__value">{arrivalReport.departure_hub}</Typography>
              </div>
              <div className="report__overview__grid__arrival item">
                <Typography className="item__label">Arrival Hub</Typography>
                <Typography className="item__value">{arrivalReport.arrival_hub}</Typography>
              </div>
              <div className="report__overview__grid__sc-departure item">
                <Typography className="item__label">SCHEDULED DEPARTURE TIME</Typography>
                <Typography className="item__value">
                  {arrivalReport.scheduled_departure_time
                    ? getFormattedDate(arrivalReport.scheduled_departure_time, "LL/dd", "N/A")
                    : "N/A"}
                </Typography>
                <Typography className="item__value" sx={{ marginLeft: "20px" }}>
                  {arrivalReport.scheduled_arrival_time
                    ? getFormattedDate(arrivalReport.scheduled_arrival_time, "hh:mm", "N/A")
                    : ""}
                </Typography>
              </div>
              <div className="report__overview__grid__sc-arrival item">
                <Typography className="item__label">SCHEDULED Arrival TIME</Typography>
                <Typography className="item__value">
                  {arrivalReport.scheduled_arrival_time
                    ? getFormattedDate(arrivalReport.scheduled_arrival_time, "LL/dd", "N/A")
                    : "N/A"}
                </Typography>
                <Typography className="item__value" sx={{ marginLeft: "20px" }}>
                  {arrivalReport.scheduled_arrival_time
                    ? getFormattedDate(arrivalReport.scheduled_arrival_time, "hh:mm", "N/A")
                    : ""}
                </Typography>
              </div>
              <div className="report__overview__grid__ac-departure item">
                <Typography className="item__label">Actual DEPARTURE TIME</Typography>
                <Typography className="item__value">
                  {arrivalReport.actual_departure_time
                    ? getFormattedDate(arrivalReport.actual_departure_time, "LL/dd", "N/A")
                    : "N/A"}
                </Typography>
                <Typography className="item__value" sx={{ marginLeft: "20px" }}>
                  {arrivalReport.actual_departure_time
                    ? getFormattedDate(arrivalReport.actual_departure_time, "hh:mm", "N/A")
                    : ""}
                </Typography>
              </div>
              <div className="report__overview__grid__ac-arrival item">
                <Typography className="item__label">Actual Arrival TIME</Typography>
                <Typography className="item__value">
                  {arrivalReport.actual_arrival_time
                    ? getFormattedDate(arrivalReport.actual_arrival_time, "LL/dd", "N/A")
                    : "N/A"}
                </Typography>
                <Typography className="item__value" sx={{ marginLeft: "20px" }}>
                  {arrivalReport.actual_arrival_time
                    ? getFormattedDate(arrivalReport.actual_arrival_time, "hh:mm", "N/A")
                    : ""}
                </Typography>
              </div>
              <div className="report__overview__grid__tm-departure item">
                <Typography className="item__label">DEPARTURE Timeliness</Typography>
                <Typography className={`item__value${getOnTimeClass(arrivalReport.departure_time_difference)}`}>
                  {arrivalReport.departure_time_difference
                    ? getOnTimeText(arrivalReport.departure_time_difference)
                    : ""}
                </Typography>
              </div>
              <div className="report__overview__grid__tm-arrival item ">
                <Typography className="item__label">Arrival Timeliness</Typography>
                <Typography className={`item__value${getOnTimeClass(arrivalReport.arrival_time_difference)}`}>
                  {arrivalReport.arrival_time_difference ? getOnTimeText(arrivalReport.arrival_time_difference) : ""}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box className="report__route">
            <Box className="report__route__wrapper">
              <Typography className="title">Route</Typography>
              <div className="report__route__wrapper__example">
                <div>
                  <Typography>Planned</Typography>
                  <div className="report__route__wrapper__planned-rectangle"></div>
                </div>
                <div>
                  <Typography>Actual</Typography>
                  <div className="report__route__wrapper__actual-rectangle"></div>
                </div>
              </div>
            </Box>
            <Box className="report__route__map">
              <ReportMap missionId={missionId} />
            </Box>
            <Box className="report__route__grid">
              <div className="report__route__grid__route-overview item">
                <Typography className="item__label">Route</Typography>
                <Typography className="item__value">
                  {arrivalReport.departure_hub} <ArrowRightAlt /> {arrivalReport.arrival_hub}
                </Typography>
              </div>
              <div className="report__route__grid__deviations item">
                <Typography className="item__label">Deviations</Typography>
                <Typography className="item__value">N/A</Typography>
              </div>
              <div className="report__route__grid__sdelays item">
                <Typography className="item__label">Delays Caused By Route Deviations</Typography>
                <Typography className="item__value">N/A</Typography>
              </div>
            </Box>
          </Box>
          <Box className="report__fuel">
            <Typography className="title">Fuel</Typography>
            <Box className="report__fuel__grid">
              <div className="report__fuel__grid__start item">
                <Typography className="item__label">Starting Fuel Level</Typography>
                <Typography className="item__value">
                  {arrivalReport.fuel_level_start ? arrivalReport.fuel_level_start : "N/A"}
                </Typography>
              </div>
              <div className="report__fuel__grid__end item">
                <Typography className="item__label">Ending Fuel Level</Typography>
                <Typography className="item__value">
                  {arrivalReport.fuel_level_end ? arrivalReport.fuel_level_end : "N/A"}
                </Typography>
              </div>
              <div className="report__route__grid__efficiency item">
                <Typography className="item__label">EST. FUEL EFFICIENCY</Typography>
                <Typography className="item__value">
                  {arrivalReport.estimated_fuel_efficiency ? arrivalReport.estimated_fuel_efficiency : "N/A"}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box className="report__next">
            <Typography className="title">Truck: Next Assignment</Typography>
            <Box className="report__next__grid">
              <div className="report__next__grid__next-mission item">
                <Typography className="item__label">Next Mission</Typography>
                <Typography className="item__value">
                  <Link
                    className={arrivalReport.upcoming_mission ? "" : "__disabled"}
                    to={
                      arrivalReport.upcoming_mission?.load_id
                        ? `/missions/${encodeURIComponent(arrivalReport.upcoming_mission.load_id)}`
                        : null
                    }
                  >
                    {arrivalReport.upcoming_mission ? arrivalReport.upcoming_mission.load_id : "---"}
                  </Link>
                </Typography>
              </div>
              <div className="report__next__grid__departure-time item">
                <Typography className="item__label">Departure Time</Typography>
                <Typography className={`item__value${arrivalReport.quick_turnaround ? "__quick" : ""}`}>
                  {arrivalReport.next_mission_departure_time
                    ? getFormattedDate(arrivalReport.next_mission_departure_time, "LL/dd hh:mm", "N/A")
                    : "N/A"}
                  {arrivalReport.quick_turnaround ? <FaShippingFast /> : null}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box className="report__notes">
            <Typography className="title">{`Other Mission Notes (${
              arrivalReport.notes && arrivalReport.notes.length
            })`}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "35%" }}>Comments</TableCell>
                  <TableCell sx={{ width: "25%" }}>Tags</TableCell>
                  <TableCell sx={{ width: "18%" }}>User</TableCell>
                  <TableCell sx={{ width: "15%" }}>Timestamp</TableCell>
                  <TableCell sx={{ width: "10%" }}>Delay</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{notes}</TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
      <ReviewCheckbox
        action={action}
        onSubmit={onSubmitAction}
        setComment={setComment}
        notesText="Arrival Notes"
        submitText="Reviewed"
      />
    </Box>
  ) : (
    <LinearProgress />
  )
}

export default ReportForm
