import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Button, Divider, Menu, MenuItem, useMediaQuery } from "@mui/material"
import React, { useEffect } from "react"
import { NavLink, useLocation } from "react-router-dom"
import SearchBar from "./SearchBar"
import "./TopBar.scss"

const TopBar = () => {
  const navLinks = [
    {
      name: "Missions",
      path: "missions",
    },
    {
      name: "Fleet",
      path: "vehicles",
    },
  ]

  const location = useLocation()
  const isVisible =
    location.pathname === "/missions" || location.pathname === "/vehicles" || location.pathname === "/hub"

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"))
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  useEffect(() => {
    if (!isSmall) {
      handleMenuClose()
    }
  }, [isSmall])

  return (
    <>
      {isVisible && (
        <div id="topbar">
          <div className="topbar__nav">
            {/* lg up nav btns */}
            {navLinks.map((link) => (
              <Button key={link.name} className="topbar__nav-btn" component={NavLink} to={link.path}>
                {link.name}
              </Button>
            ))}

            {/* lg down dropdown btn */}
            <Button
              className="topbar__nav-btn topbar__nav-btn--dropdown active"
              id="nav-btn"
              aria-controls={open ? "nav-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuOpen}
              endIcon={<ExpandMoreIcon />}
            >
              {navLinks.find((link) => location.pathname.includes(link.path))?.name}
            </Button>
            <Menu
              id="nav-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "nav-btn",
                className: "topbar__nav-dropdown",
              }}
              slotProps={{ paper: { sx: { borderRadius: ".5rem", mt: 0.5 } } }}
              marginThreshold={0}
            >
              {navLinks.map((link) => (
                <MenuItem key={link.name} component={NavLink} to={link.path} onClick={handleMenuClose}>
                  {link.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
          {!isSmall ? <Divider flexItem orientation="vertical" /> : null}
          <SearchBar />
        </div>
      )}
    </>
  )
}

export default React.memo(TopBar)
