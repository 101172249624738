import useGetMissionRouteQuery from "@/hooks/query/routes/useGetMissionRouteQuery"
import { Typography } from "@mui/material"
import _ from "lodash"

const MissionOverview = ({ mission }) => {
  const mission_type = mission?.customer_name || "N/A"
  const route_details = useGetMissionRouteQuery(mission?.uuid)
  const formattedMissionType = _.startCase(_.toLower(_.replace(mission_type, /_/g, " ")))
  const totalMiles = route_details?.data?.[0]?.total_miles ?? "TBD"

  return (
    <div className="mission-overview-container">
      <div className="mission-overview">
        <Typography className="mission-overview__label" data-testid="mission-type">
          TYPE
        </Typography>
        <Typography className="mission-overview__value">{formattedMissionType}</Typography>
      </div>
      <div className="mission-overview">
        <Typography className="mission-overview__label" data-testid="miles">
          MILES
        </Typography>
        <Typography className="mission-overview__value">{totalMiles}</Typography>
      </div>
    </div>
  )
}

export default MissionOverview
