import getToken from "@/framework/utils/api/getToken"
import { MissionsApiClient } from "@torc-robotics/mm-missions-client"

const MissionsApi = new MissionsApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_MISSIONS_API_URL,
  TOKEN: getToken,
}).v1

export const MissionsApiV2 = new MissionsApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_MISSIONS_API_URL,
  TOKEN: getToken,
}).v2

export default MissionsApi
