import ClearIcon from "@mui/icons-material/Clear"
import SortIcon from "@mui/icons-material/Sort"
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import useSearchStore from "../../store/useSearchStore"
import "./Search.scss"
import SearchFilters from "./SearchFilters"
import SearchInput from "./SearchInput"

const MissionSearch = () => {
  const { date, sort, filter, search } = useSearchStore((state) => state.mission)
  const updateParams = useSearchStore((state) => state.updateMissionParams)

  const isValidDate = (date) => {
    let parsedDate = Date.parse(date)
    return !isNaN(parsedDate)
  }

  return (
    <div className="search" data-testid="mission-search" data-dd-action-name="mission-search">
      <SearchInput search={search} updateSearch={(search) => updateParams({ search })} placeholder="Search Load IDs" />
      <div className="searchbar__search-filters">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            shrink={true}
            inputFormat="MM/dd/yyyy"
            value={date}
            onChange={(newDate) => updateParams({ date: newDate })}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                variant="standard"
                sx={{ maxWidth: "180px" }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {date && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="clear date"
                            onClick={() => updateParams({ date: null })}
                            // hacky, couldnt figure out how to get rid of the default margin
                            style={{ marginRight: "-12px" }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      {props.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>

        <FormControl size="small" sx={{ minWidth: "96px" }} variant="standard">
          <InputLabel id="sort-label" shrink={true}>
            Sort
          </InputLabel>
          <Select
            data-testid="sort"
            data-dd-action-name="sort"
            labelId="sort-label"
            id="sort-select"
            value={sort.field}
            disabled
            label="Sort"
          >
            <MenuItem data-testid="sort-clear" data-dd-action-name="sort-clear" value={""}>
              Clear
            </MenuItem>
            <MenuItem data-testid="sort-ascending" data-dd-action-name="sort-ascending" value={"load-id"}>
              Load ID
            </MenuItem>
          </Select>
        </FormControl>
        <IconButton
          size="small"
          className="searchbar__sort-icon"
          data-testid="sort-order"
          data-dd-action-name="sort-order"
          onClick={() => updateParams({ sort: { order: sort.order === "asc" ? "desc" : "asc" } })}
        >
          <SortIcon
            sx={
              sort.order === "asc" && {
                transform: "rotate(180deg) scaleX(-1)",
              }
            }
          />
        </IconButton>

        <SearchFilters
          filterOptions={filter}
          updateFilter={updateParams}
          additionalFilters={{
            ...(date && { date: isValidDate(date) ? Intl.DateTimeFormat("en-US").format(date) : null }),
          }}
        ></SearchFilters>
      </div>
    </div>
  )
}

export default MissionSearch
