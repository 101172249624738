import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionByUuidQuery = (uuid: string) => {
  const missionQuery = useQuery({
    queryKey: ["mission", uuid],
    queryFn: async () => await MissionsApi.getMission({ uuid }),
    enabled: !isEmpty(uuid),
  })

  return missionQuery
}

export default useGetMissionByUuidQuery
