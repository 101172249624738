import { HotkeyTooltip } from "@/features/shared/Hotkeys"
import useGetNoteTagsQuery from "@/hooks/query/notes/useGetNoteTagsQuery"
import { yupResolver } from "@hookform/resolvers/yup"
import CloseIcon from "@mui/icons-material/Close"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useHotkeys } from "react-hotkeys-hook"
import * as yup from "yup"
import { getTime } from "../../../framework/Utils"
import { useGetMissionByLoadId } from "../../../hooks/missionHooks"

const filter = createFilterOptions()

const MAX_NOTE_LENGTH = 10000
const MAX_TAG_LENGTH = 15

const schema = yup.object({
  noteType: yup.string().required("Select a note type."),
  delayValue: yup
    .number()
    .integer()
    .nullable()
    .label("Delay Value")
    .min(1)
    .max(2880)
    .typeError("Delay Value is required."),
  noteValue: yup
    .string()
    .required("Enter a note or comment.")
    .max(MAX_NOTE_LENGTH, `Note must be less than ${MAX_NOTE_LENGTH} characters.`),
})

const regexNumber = /^(\d+|)$/
const regexTags = /^([A-Za-z0-9\-_]|)+$/

const CreateNote = ({ onClose, addNewNote, timestamp, priorNote }) => {
  const theme = useTheme()
  const mission = useGetMissionByLoadId()

  const { control, handleSubmit, setValue, trigger, watch, clearErrors } = useForm({
    defaultValues: {
      noteType: priorNote ? priorNote?.mission_note_type : "",
      noteTags: priorNote ? priorNote?.mission_note_tags : [],
      isDelay: priorNote ? priorNote?.delay : false,
      delayValue: priorNote ? priorNote?.delay_value : 1,
      noteValue: priorNote ? priorNote?.body : "",
      timestamp: priorNote ? priorNote?.user_creation_ts : timestamp,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  })

  const [tagInput, setTagInput] = useState("")
  const { data: noteTags } = useGetNoteTagsQuery("")

  const noteTypes = [
    { id: "safety_event", display: "Safety Event" },
    { id: "performance", display: "Performance" },
    { id: "logistical", display: "Logistical" },
    { id: "data_collection", display: "Data Collection" },
    { id: "action", display: "Action" },
  ]

  const handleTagInputChange = (_event, value) => {
    if (value.length > MAX_TAG_LENGTH || !value.match(regexTags)) return

    setTagInput(value.toLowerCase())
  }

  const handleTagChange = (_event, value, reason) => {
    if (reason === "createOption") return
    const cleansedTags = value.map((tag) => ({
      uuid: tag.uuid || null,
      inputValue: tag.inputValue,
      tag_name: tag.tag_name,
      timestamp: tag.timestamp ? tag.timestamp : new Date().getTime(),
    }))

    setValue("noteTags", cleansedTags)
  }
  useHotkeys(
    "meta+enter, ctrl+enter",
    (event) => {
      event.preventDefault()
      handleSubmit(addNewNote)()
    },
    { enableOnFormTags: true, scope: "create-note" },
  )
  const watchDelay = watch("isDelay")

  const getOptionLabel = (option) => (option.uuid && option.tag_name) || option.tag_display || `(+) ${option.tag_name}`
  const isOptionEqualToValue = (option, value) => {
    return (option.uuid && option.uuid === value.uuid) || option.tag_name === value.tag_name
  }

  const filterOptions = (options, state) => {
    const filtered = filter(options, state)
    const { inputValue } = state
    const isExisting = options.some((option) => inputValue === option.tag_name)
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        tag_name: inputValue,
        tag_display: `new tag - (${inputValue.length}/${MAX_TAG_LENGTH}) "${inputValue}"`,
      })
    }
    return filtered
  }

  const handleIsDelayChange = (e) => {
    // form sends string'true', instead of boolean
    setValue("isDelay", e.target.value === "true")
    setValue("delayValue", 1)
    clearErrors("delayValue")
    trigger("isDelay")
    trigger("delayValue")
  }

  const handleDelayValueChange = (e) => {
    if (!e.target.value.match(regexNumber)) return

    setValue("delayValue", e.target.value)
    trigger("delayValue")
  }

  // keeps max noteValue.length > MAX_NOTE_LENGTH
  // TODO - need to handle pasting
  const handleNoteChange = (e, field, fieldState) => {
    if (e.target.value.length > MAX_NOTE_LENGTH) return

    setValue(field.name, e.target.value)
    if (!fieldState.isDirty) {
      trigger(field.name)
    }
  }

  const generateSelectOptions = (options) => {
    return options.map((item) => (
      <MenuItem data-testid={item.id} key={item.id} value={item.display}>
        {item.display}
      </MenuItem>
    ))
  }

  return (
    <Box sx={{ mt: 4, px: 2, pb: 2, width: "25vw" }} data-testid="create-note">
      <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
        <Box sx={{ borderRadius: "50%", p: 1, mr: 1, backgroundColor: theme.palette.primary.light }}>
          <LocalShippingIcon sx={{ color: theme.palette.background.default }} fontSize="large" />
        </Box>
        <Box sx={{ lineHeight: 0.5, display: "block" }}>
          <Typography variant="h6" sx={{ mb: 0, pb: 0 }} data-testid="load-id">
            {mission?.load_id}
          </Typography>
          <Typography variant="caption" sx={{ mt: 0 }} data-testid="vehicle-id">
            {mission?.vehicle_id} - {mission?.trailer_id}
          </Typography>
        </Box>
        <IconButton data-testid="close-btn" sx={{ marginLeft: "auto" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" data-testid="New-Note">
          {priorNote ? `Edit Note` : `New Note`}
        </Typography>
        <Typography variant="caption" data-testid="timestamp" color={"rgba(0, 0, 0, 0.6)"}>
          {`Timestamp: ${getTime(timestamp, "HH:mm:ss", true)}`}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(addNewNote)}>
        <fieldset style={{ border: 0 }}>
          <Controller
            name="noteType"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error} sx={{ mb: 2 }}>
                <InputLabel id="note-type-label">Note Type</InputLabel>
                <Select
                  data-testid="note-type"
                  {...field}
                  labelId="note-type-label"
                  aria-describedby="note-type"
                  label="Note Type *"
                >
                  {generateSelectOptions(noteTypes)}
                </Select>
                {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name="noteValue"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  data-testid="note-comments"
                  fullWidth
                  multiline
                  error={!!fieldState.error}
                  rows={4}
                  label="Comments/Notes"
                  {...field}
                  onChange={(e) => handleNoteChange(e, field, fieldState)}
                />
                <Box sx={{ display: "flex" }}>
                  {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                  <FormHelperText id="note-comments-input-length" style={{ marginLeft: "auto" }}>
                    {field.value.length}/{MAX_NOTE_LENGTH}
                  </FormHelperText>
                </Box>
              </>
            )}
          />
          <Controller
            name="noteTags"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={noteTags ?? []}
                renderInput={(params) => (
                  <TextField data-testid="note-tags" {...params} label="Note Tags" placeholder="Note Tags" />
                )}
                autoHighlight
                clearOnBlur
                filterOptions={filterOptions}
                freeSolo
                getOptionLabel={getOptionLabel}
                handleHomeEndKeys
                inputValue={tagInput}
                isOptionEqualToValue={isOptionEqualToValue}
                limitTags={3}
                multiple
                onChange={handleTagChange}
                onInputChange={handleTagInputChange}
                selectOnFocus
                sx={{ mb: 2 }}
              />
            )}
          />
          <Controller
            name="isDelay"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ ml: 1, mb: 1, width: "100%" }}>
                <FormLabel id="delay-group-label">Incident caused a delay?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="delay-radio-group-label"
                  name="delay-radio-group"
                  {...field}
                  onChange={handleIsDelayChange}
                >
                  <FormControlLabel value={true} control={<Radio data-testid="yes-note-delay" />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            )}
          />
          <Controller
            name="delayValue"
            control={control}
            data-testid="note-delay-value"
            render={({ field, fieldState }) =>
              watchDelay && (
                <TextField
                  {...field}
                  label="Delay in minutes"
                  sx={{ mb: 2 }}
                  error={!!fieldState.error}
                  onChange={handleDelayValueChange}
                  helperText={!!fieldState.error && fieldState.error.message}
                />
              )
            }
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Button type="reset" variant="outlined" onClick={onClose}>
                {priorNote ? `Discard Changes` : `Discard Note`}
              </Button>
            </Box>
            &nbsp;
            <Box sx={{ mb: 2 }}>
              <HotkeyTooltip shortcut="⌘ + [enter]">
                <div>
                  <Button data-testid="submit-note" type="submit" variant="contained">
                    {priorNote ? `Save` : `Submit`}
                  </Button>
                </div>
              </HotkeyTooltip>
            </Box>
          </Box>
        </fieldset>
      </form>
    </Box>
  )
}

export default CreateNote
