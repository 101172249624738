import { Box, Tooltip, Typography } from "@mui/material"
import { truncateText } from "./Utils"

const MissionInfoBox = ({ title = "", detail = "", subtext = "" }) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        color: "#333",
        minHeight: "7em",
        overflow: "hidden",
        display: "grid",
        gridTemplateRows: "1fr 1fr 1fr",
        placeItems: "center",
        p: ".5rem",
        border: "1px solid #d7d7d7",
      }}
    >
      <Typography
        variant="body1"
        component="h1"
        data-testid={`${title}-info-box`}
        sx={{
          fontWeight: 700,
          color: "#333333",
        }}
      >
        {title}
      </Typography>

      {title.toLowerCase() !== "ads mode" ? (
        <Tooltip title={<div>{detail}</div>}>
          <Typography
            variant="h8"
            component="p"
            data-testid={`${title}-detail-box`}
            id={title.toLowerCase() === "truck" ? detail.toString() : ""}
          >
            {truncateText(detail.toString(), 10)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="h8" component="p" data-testid={`${title}-detail-box`}>
          {detail}
        </Typography>
      )}
      <Typography
        variant="subtitle2"
        data-testid={`${title}-subtitle-box`}
        sx={{
          color: "#aaaaaa",
        }}
      >
        {subtext}
      </Typography>
    </Box>
  )
}

export default MissionInfoBox
