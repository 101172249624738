import MuiTruckImage from "@/components/MuiTruckImage"
import useMapStore from "@/store/useMapStore"
import ErrorIcon from "@mui/icons-material/Error"
import NearMeIcon from "@mui/icons-material/NearMe"
import { Box, Button, Grid, Typography } from "@mui/material"
import "./TruckTab.scss"
import { useGetVehicleConnectivity } from "@/hooks/vehiclesV2Hooks"

/**
 *
 * @param {{
 *   vehicle: import("@torc-robotics/mm-vehicles-client").GetVehicleResponse as Vehicle
 * }} param0
 * @returns
 */
const TruckTabHeader = ({ vehicle }) => {
  const isCentered = useMapStore((state) => state.isCentered)
  const setCentered = useMapStore((state) => () => state.setIsCentered(true))
  const vehicleConnectivity = useGetVehicleConnectivity(vehicle?.vehicle_id)
  const vehicleId = vehicle?.vehicle_id
  const vehicleName = vehicle?.name ? `-${vehicle?.name}` : ""
  const vehicleTitle = `${vehicleId}${vehicleName}`

  const getVehicleStatus = () => {
    switch (vehicleConnectivity?.status) {
      case "disconnected":
        return (
          <Typography data-testid="vehicle-health" sx={{ color: "red", fontStyle: "italic" }}>
            <ErrorIcon sx={{ color: "red" }} /> System Faulty
          </Typography>
        )
      case "connected":
        return (
          <Typography data-testid="vehicle-health" sx={{ color: "green", fontStyle: "italic" }}>
            All Systems Connected
          </Typography>
        )
      default:
        return (
          <Typography data-testid="vehicle-health" sx={{ color: "blue", fontStyle: "italic" }}>
            N/A
          </Typography>
        )
    }
  }

  return (
    <>
      <Box className="truck-header-img-wrapper">
        <MuiTruckImage vehicleId={vehicleId}></MuiTruckImage>
        {vehicleId !== "" ? (
          <Button
            data-testid="center-btn"
            onClick={setCentered}
            className="center-btn"
            variant={!isCentered ? "outlined" : "contained"}
          >
            Center <NearMeIcon />
          </Button>
        ) : null}
      </Box>
      <Grid container columns={11} sx={{ height: "8em" }}>
        <Grid item xs={10}>
          <Typography variant="h6">{vehicleTitle}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">Make/Model</Typography>
          <Typography>Freightliner | Cascadia</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption">Generation</Typography>
          <Typography>{vehicle?.gen ? vehicle?.gen : "N/A"}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption">Edge</Typography>
          <Typography>{vehicle?.edge_version}</Typography>
        </Grid>
        <Grid item xs={2}>
          {getVehicleStatus()}
        </Grid>
      </Grid>
    </>
  )
}

export default TruckTabHeader
