import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import { useGetVehicle } from "@/hooks/vehiclesV2Hooks"
import { Container } from "@mui/material"
import _ from "lodash"
import MissionDetailsHeader from "./MissionDetailsHeader"
import MissionDetailsTabs from "./MissionDetailsTabs"
import MissionVehicleDetailGrid from "./MissionVehicleDetailGrid"
import "./scss/MissionDetails.scss"
import { DatadogContext } from "@/framework/contexts/Datadog"
import { useContext } from "react"

const MissionDetails = ({ activeMission = null, drawerView = false, hideCloseIcon = true }) => {
  const vehicle = useGetVehicle()
  const { telemetry } = useContext(DatadogContext)
  const mission = activeMission ?? useGetMissionByLoadId()

  telemetry.useViewContext("mission", _.pick(mission, ["uuid", "load_id", "state", "vehicle_id"]))

  return (
    <Container
      data-testid="mission-details"
      className="mission-details"
      disableGutters
      sx={{ backgroundColor: "white" }}
    >
      <MissionDetailsHeader
        mission={mission}
        vehicle={vehicle}
        hideBackNavigation={drawerView}
        hideCloseIcon={hideCloseIcon}
      />

      <MissionVehicleDetailGrid mission={mission} vehicle={vehicle} />
      <MissionDetailsTabs drawerView={drawerView} mission={mission} />
    </Container>
  )
}

export default MissionDetails
