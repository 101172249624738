import CloseIcon from "@mui/icons-material/Close"
import DescriptionIcon from "@mui/icons-material/Description"
import { Box, IconButton, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Form } from "@rjsf/mui"
import validator from "@rjsf/validator-ajv8"
import _ from "lodash"
import { useContext } from "react"
import { useGetMissionByLoadId } from "../../../hooks/missionHooks"
import ReviewCheckbox from "../review-checkbox/ReviewCheckbox"
import HyperLink from "./widgets/HyperLink"
import "./Form.scss"
import useGetMeQuery from "@/hooks/query/user/useGetMeQuery"
import TaskForm, { TaskFormContext } from "./TaskForm"

const ReviewForm = (props) => {
  return (
    <TaskForm {...props}>
      <FormInternal {...props} />
    </TaskForm>
  )
}

const FormInternal = ({ action }) => {
  const theme = useTheme()
  const { data: userData } = useGetMeQuery()
  const { onCancel, onSubmit, onSave } = useContext(TaskFormContext)

  const mission = useGetMissionByLoadId()

  const setComment = (comment) => {
    action.comment = comment
  }

  const widgets = {
    HyperLink: HyperLink,
  }

  const onSubmitAction = async () => {
    const user = _.get(userData, "email", "")
    action["completed_by"] = user
    onSubmit(action)
  }

  const onSaveAction = () => {
    onSave(action)
  }

  return action ? (
    <Box>
      <Box
        className="review-form-header"
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(39, 46, 51, 1)",
          position: "sticky",
          top: 0,
          opacity: 1,
          zIndex: 1000,
        }}
      >
        <DescriptionIcon sx={{ color: theme.palette.background.default }} fontSize="large" />
        <Typography variant="h6" data-testid="vehicle-id" sx={{ color: theme.palette.background.default }}>
          {_.get(action, "title", "")} - {mission?.vehicle_id}
        </Typography>
        <IconButton
          sx={{ marginLeft: "auto", color: theme.palette.background.default }}
          onClick={onCancel}
          data-testid="close-action-form"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="review-form">
        <Form
          schema={_.get(action, "template", {})}
          uiSchema={_.get(action, "template_ui", {})}
          formData={_.get(action, "formData", {})}
          disabled={true}
          validator={validator}
          widgets={widgets}
        >
          <ReviewCheckbox
            action={action}
            onSubmit={onSubmitAction}
            setComment={setComment}
            onclose={onSaveAction}
            notesText={"Comments"}
            checkboxText="I have reviewed this form submission and acknowledge that inspection results are acceptable for departure"
            closeText="Close"
            submitText="Approve"
          />
        </Form>
      </Box>
    </Box>
  ) : (
    <Typography data-testid="not-supported">Action for Truck Gen Not Supported.</Typography>
  )
}

export default ReviewForm
