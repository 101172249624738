/* istanbul ignore file */
import LoadScreen from "@/components/LoadScreen"
import config from "@/config"
import { dataLayer } from "@/features/map/mapStyle"
import useGetRouteQuery from "@/hooks/query/routes/useGetRouteQuery"
import { useGeoCreds } from "@/hooks/UserHooks"
import { Signer } from "@aws-amplify/core"
import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import { bbox } from "@turf/bbox"
import { center, lineString } from "@turf/turf"
import _ from "lodash"
import maplibregl from "maplibre-gl"
import { useEffect, useMemo, useRef, useState } from "react"
import Map, { FullscreenControl, Layer, Source, useControl } from "react-map-gl"
import useRouteEndLayer from "./useRouteEndLayer"
import useRouteStartLayer from "./useRouteStartLayer"
import useRouteWaypointLayer from "./useRouteWaypointLayer"

const RouteSelectionMap = ({ routeId, startCoord, endCoord, arrivalVias }) => {
  const geoCreds = useGeoCreds()
  const mapRef = useRef(null)
  const { data: routeData, isLoading } = useGetRouteQuery(routeId)
  const route = !isLoading && routeData ? routeData.latlons?.map((coords) => [coords.lon, coords.lat]) : []
  const startIcon = !routeId ? useRouteStartLayer(startCoord) : route ? useRouteStartLayer(route[0]) : null
  const endIcon = !routeId ? useRouteEndLayer(endCoord) : route ? useRouteEndLayer(route[route.length - 1]) : null
  const waypointCoords = arrivalVias?.flatMap((currentVia) =>
    currentVia?.latlons?.map((currentViaLatLons) => [currentViaLatLons?.lon, currentViaLatLons?.lat]),
  )
  const waypointIcon = !_.isEmpty(waypointCoords) ? useRouteWaypointLayer(waypointCoords) : null

  const routePath = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: route,
    },
  }

  const [mapLoaded, setMapLoaded] = useState(false)

  const transformRequest = (url, resourceType) => {
    if (resourceType === "Style" && url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1) {
      url = `${config.AWS_MAP_URL}`
    }
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds.accessKeyId,
          secret_key: geoCreds.secretAccessKey,
          session_token: geoCreds.sessionToken,
        }),
      }
    }

    return { url: url || "" }
  }

  const initialView = useMemo(() => {
    if (routeData) {
      const routeLine = lineString(routeData.latlons?.map((coords) => [coords.lon, coords.lat]))

      let calculatedCenter = center(routeLine)
      let centerLat = calculatedCenter.geometry.coordinates[1]
      let centerLon = calculatedCenter.geometry.coordinates[0]

      // Define the initial view state
      return {
        latitude: centerLat,
        longitude: centerLon,
        zoom: 11.5,
        bearing: 0,
        pitch: 0,
      }
    } else
      return {
        longitude: startCoord[0],
        latitude: startCoord[1],
        zoom: 17,
      }
  }, [routeData])

  useEffect(() => {
    if (mapRef.current && mapLoaded && routeData) {
      const routeLine = lineString(routeData.latlons?.map((coords) => [coords.lon, coords.lat]))
      const [minLon, minLat, maxLon, maxLat] = bbox(routeLine)

      mapRef.current.fitBounds(
        [
          [minLon, minLat],
          [maxLon, maxLat],
        ],
        { padding: 20, animate: false },
      )
    }
  }, [mapLoaded])

  return geoCreds && !isLoading ? (
    <Map
      ref={mapRef}
      id="routemap"
      style={{
        transition: "opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
        opacity: mapLoaded ? 1 : 0,
        filter: mapLoaded ? "blur(0px)" : "blur(100px)",
        pointerEvents: mapLoaded ? "auto" : "none",
      }}
      initialViewState={initialView}
      dragRotate={true}
      touchRotate={false}
      mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
      mapLib={maplibregl}
      transformRequest={transformRequest}
      dragPan={true}
      scrollZoom={true}
      doubleClickZoom={false}
      onLoad={() => {
        setMapLoaded(true)
      }}
      attributionControl={false}
    >
      <Source id="dc-route" type="geojson" data={routePath}>
        <Layer {...dataLayer} />
      </Source>
      <DeckGLOverlay layers={[waypointIcon, startIcon, endIcon]} />
      <FullscreenControl />
    </Map>
  ) : (
    <LoadScreen />
  )
}

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

export default RouteSelectionMap
