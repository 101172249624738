import { Box, Button, TextField, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import _ from "lodash"
import { useState } from "react"
import "./ReviewCheckbox.scss"

const ReviewCheckbox = (props) => {
  const {
    action,
    onclose,
    onSubmit,
    setComment,
    title,
    closeText,
    checkboxText,
    submitText,
    notesText,
    route_info = "",
  } = props
  const [isChecked, setIsChecked] = useState(false)

  var action_state_complete = _.get(action, "state") === "COMPLETED"
  /* istanbul ignore next */
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  return (
    <Box className="review_submit">
      <Box className="review_submit__title">
        <Typography data-testid="title-label">{title}</Typography>
      </Box>
      {notesText ? (
        <Box className="review_submit__notes">
          <Typography data-testid="notes-label">{notesText}</Typography>
          <TextField
            datat-testid="notes-box"
            inputProps={{ "data-testid": "notes-value" }}
            className="notes-box"
            onChange={(e) => {
              setComment(e.currentTarget.value)
            }}
            multiline={true}
            disabled={action_state_complete}
            defaultValue={_.get(action, "comment", "")}
          />
        </Box>
      ) : null}
      {checkboxText ? (
        <Box className="review_submit__checkbox">
          <Checkbox
            data-testid="checkbox-acknowledge"
            checked={action_state_complete ? action_state_complete : isChecked}
            disabled={action_state_complete}
            onChange={handleCheckboxChange}
            role="checkbox"
          />
          <Typography>{checkboxText}</Typography>
        </Box>
      ) : null}

      <Box className="review_submit__btn_group">
        {closeText ? (
          <Button
            className="review_submit__btn_group__close"
            data-testid="close-action"
            type="reset"
            variant="outlined"
            size="large"
            onClick={onclose}
            hidden={action_state_complete}
          >
            {action_state_complete ? "Close" : closeText}
          </Button>
        ) : null}
        <Button
          className="review_submit__btn_group__submit"
          data-testid="submit-action"
          type="submit"
          variant="contained"
          size="large"
          disabled={
            action_state_complete
              ? action_state_complete
              : _.get(action, "type") == "request" && !route_info
                ? true
                : checkboxText
                  ? !isChecked
                  : false
          }
          hidden={action_state_complete}
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      </Box>
    </Box>
  )
}

export default ReviewCheckbox
