import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { isEmpty } from "lodash"
import useGetMissionRouteQuery from "./useGetMissionRouteQuery"

const useGetMissionRouteLatLons = (missionUuid) => {
  const { data: missionRouteData } = useGetMissionRouteQuery(missionUuid)
  const routeLatLonsUrl = missionRouteData?.[0]?.latlons_s3_presigned_url
  return useQuery({
    queryKey: ["mission-route-lat-lons", missionUuid, routeLatLonsUrl],
    queryFn: async () => (await axios.get(routeLatLonsUrl)).data,
    enabled: !isEmpty(missionRouteData),
    meta: {
      persist: false,
    },
  })
}

export default useGetMissionRouteLatLons
