import useVehicleHover from "@/hooks/map/layers/useVehicleHover"
import { Box, Card, Divider, Typography } from "@mui/material"
import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import VehiclesHoverTooltip from "../map/hover/VehiclesHoverTooltip"
import MissionDepartureDetails from "./MissionDepartureDetails"
import MissionDetailsHeader from "./MissionDetailsHeader"
import headerStyles from "./scss/MissionDetailsHeader.module.scss"

const RETURN_SCROLL_DURATION = 150
const MissionListCard = ({ mission, conflicts, from = "missions" }) => {
  const handleHover = useVehicleHover()

  const cardRef = useRef(null)

  useEffect(() => {
    let clickPos = sessionStorage.getItem("clickPos")

    const element = document.getElementById("missionList")?.parentNode
    if (!element) {
      return
    }

    setTimeout(() => {
      const cardRect = cardRef.current?.getBoundingClientRect()

      if (clickPos && cardRect && !element?.scrollTop && cardRect?.top <= clickPos && clickPos <= cardRect?.bottom) {
        const scrollableRect = element.getBoundingClientRect()

        // mobile
        const expandBar = document.getElementById("expand-collapse")
        const expandBarHeight = expandBar?.getBoundingClientRect()?.height || 0

        const scrollTop = cardRect?.top - (scrollableRect.top + expandBarHeight)

        element?.scrollTo({ top: scrollTop, behavior: "instant" })
      }

      sessionStorage.removeItem("clickPos")
    }, RETURN_SCROLL_DURATION)
  }, [sessionStorage.getItem("clickPos")])

  if (!mission) {
    return (
      <Card data-testid="no-data-card">
        <Box>
          <Typography className="mission-list-title">No Mission Data</Typography>
        </Box>
      </Card>
    )
  }

  function getAbsYCoord(e) {
    var yPos = 0

    while (e) {
      if (e.tagName == "BODY") {
        var yScrollPos = e.scrollTop || document.documentElement.scrollTop

        yPos += e.offsetTop - yScrollPos + e.clientTop
      } else {
        yPos += e.offsetTop - e.scrollTop + e.clientTop
      }
      e = e.offsetParent
    }
    return yPos
  }

  return (
    <Link
      ref={cardRef}
      data-testid={`mission-card-link-${mission.load_id}`}
      onMouseEnter={() => handleHover(<VehiclesHoverTooltip vehicleId={mission.vehicle_id} />, mission.vehicle_id)}
      onMouseLeave={() => handleHover(<VehiclesHoverTooltip vehicleId={""} />)}
      onClick={(e) => {
        handleHover(<VehiclesHoverTooltip vehicleId={""} />), sessionStorage.setItem("clickPos", getAbsYCoord(e.target))
      }}
      to={`/missions/${encodeURIComponent(mission.load_id)}`}
      className="mission-list-card"
      state={{ from }}
    >
      <MissionDetailsHeader conflicts={conflicts} mission={mission} hideBackNavigation />
      <Divider />
      <div className={headerStyles["mission-details-header__row"]}>
        <MissionDepartureDetails mission={mission} />
      </div>
    </Link>
  )
}

export default MissionListCard
