import { VehiclesApiV2 } from "@/framework/utils/api/VehiclesAPI"
import { useQueries } from "@tanstack/react-query"

const useGetVehiclesConnectivityInfo = (vehicles) => {
  const vehicleIds = vehicles.map(({ vehicle_id }) => vehicle_id)

  const vehicleConnectivityQuery = (vehicleId) => ({
    queryKey: ["vehicle-connectivity", vehicleId],
    queryFn: () =>
      VehiclesApiV2.getVehicleConnectivityV2VehiclesVehicleIdConnectivityGet({ vehicleId }).then((response) => ({
        status: response?.status,
        vehicle_id: vehicleId,
      })),
    staleTime: Infinity,
  })

  const vehiclesConnectivityQuery = vehicleIds.map(vehicleConnectivityQuery)
  const connectivityResults = useQueries({ queries: vehiclesConnectivityQuery })

  const vehicleConnectivityInfo = connectivityResults.reduce((acc, { data }) => {
    if (data) acc[data.vehicle_id] = data.status
    return acc
  }, {})

  return vehicleConnectivityInfo
}

export default useGetVehiclesConnectivityInfo
