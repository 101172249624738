import { getFormattedDate } from "@/framework/Utils"
import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import useGetMissionRouteQuery from "@/hooks/query/routes/useGetMissionRouteQuery"
import { Box, Grid, Stack, Typography } from "@mui/material"
import "./scss/MissionDetailInfo.scss"
import { capitalizeWords } from "./Utils"

const MissionDetailInfo = ({ activeMission }) => {
  const mission = useGetMissionByLoadId() ?? activeMission
  const { data: missionRouteData } = useGetMissionRouteQuery(mission?.uuid)

  const renderAddressLines = (line1, line2) => (
    <Stack spacing={0.5}>
      <Typography variant="body2" sx={{ fontSize: "12px" }}>
        {line1}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "12px" }}>
        {line2}
      </Typography>
    </Stack>
  )

  const MISSION_DETAILS = {
    PLANNING: [
      [
        { label: "DEPARTURE HUB", value: capitalizeWords(mission?.start_display) },
        {
          label: "DEPARTURE HUB ADDRESS",
          value: renderAddressLines(mission?.origin_address_line_1, mission?.origin_address_line_2),
        },
      ],
      [
        { label: "ARRIVAL HUB", value: capitalizeWords(mission?.destination_display) },
        {
          label: "ARRIVAL HUB ADDRESS",
          value: renderAddressLines(mission?.destination_address_line_1, mission?.destination_address_line_2),
        },
      ],
      [
        {
          label: "SCHEDULED DEPARTURE TIME",
          value: getFormattedDate(mission?.tms_schedule_departure_time, " hh:mm ZZZZ"),
        },
        { label: "SCHEDULED ARRIVAL TIME", value: getFormattedDate(mission?.tms_schedule_arrival_time, " hh:mm ZZZZ") },
      ],
      [
        { label: "ACTUAL DEPARTURE TIME", value: getFormattedDate(mission?.tms_actual_departure_time, " hh:mm ZZZZ") },
        { label: "ACTUAL ARRIVAL TIME", value: getFormattedDate(mission?.tms_actual_arrival_time, "  hh:mm ZZZZ") },
      ],
      [
        { label: "ESTIMATED TRIP DURATION", value: missionRouteData?.total_time },
        { label: "TOTAL ROUTE DISTANCE", value: missionRouteData?.total_miles },
        { label: "MISSION TYPE", value: capitalizeWords(mission?.customer_name) },
      ],
    ],
    "MISSION INFORMATION": [
      [
        { label: "CUSTOMER", value: capitalizeWords(mission?.customer_name) },
        { label: "MISSION ID", value: mission?.load_id },
        { label: "TRUCK ID", value: mission?.vehicle_id },
        { label: "TRAILER ID", value: mission?.trailer_id },
      ],
    ],
  }

  return (
    <Stack spacing={2} sx={{ border: "1px solid #d7d7d7", paddingLeft: "15px", paddingTop: "10px" }}>
      {Object.entries(MISSION_DETAILS).map(([sectionTitle, sectionGroups]) => (
        <Box key={sectionTitle} mb={4} className="details-info">
          <Typography color="primary" variant="h6" className="heading" mb={2}>
            {sectionTitle}
          </Typography>
          {sectionGroups.map((group, groupIndex) => (
            <Grid container spacing={1} key={groupIndex} sx={{ mb: groupIndex < sectionGroups.length - 1 ? 4 : 0 }}>
              {group.map(({ label, value }) => (
                <Grid container item xs={12} spacing={1} key={label}>
                  <Grid item xs={5}>
                    <Typography variant="body2" className="title">
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2" className="value">
                      {value ?? "--"}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Box>
      ))}
    </Stack>
  )
}

export default MissionDetailInfo
