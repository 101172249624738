import { Box, Divider, Grid, Stack } from "@mui/material"
import { FaCircle } from "react-icons/fa"
import "./ServiceStatusTab.scss"

const ServiceStatusTab = ({ vehicleConnectivity }) => {
  const connectionStatus = vehicleConnectivity?.status
  const services = vehicleConnectivity?.services

  return (
    <Stack spacing={1} sx={{ height: "12rem" }}>
      <Box>
        <Grid container>
          <Grid item xs={9}>
            Overall Status:
          </Grid>
          <Grid item>
            <span
              className={
                connectionStatus === "connected"
                  ? "green"
                  : connectionStatus === "partially_connected"
                    ? "yellow"
                    : "gray"
              }
              style={{ marginLeft: "1rem" }}
            >
              <FaCircle />
            </span>
            <span style={{ marginLeft: "0.5rem" }}>{connectionStatus?.replace(/_/g, " ")}</span>
          </Grid>
        </Grid>
      </Box>
      <Divider flexItem sx={{ borderBottomWidth: 3 }} />
      <Box>Services</Box>
      {services?.map((service) => (
        <Box key={service?.service}>
          <Grid container>
            <Grid item xs={9}>
              {service?.service}
            </Grid>
            <Grid item>
              <span className={service?.connected ? "green" : "grey"} style={{ marginLeft: "1rem" }}>
                <FaCircle />
              </span>
              <span style={{ marginLeft: "0.5rem" }}>{service?.connected ? "connected" : "disconnected"}</span>
            </Grid>
          </Grid>
          <Divider flexItem sx={{ borderBottomWidth: 3 }} />
        </Box>
      ))}
    </Stack>
  )
}

export default ServiceStatusTab
