import type EgressMessage from "@/models/iot/egress/EgressMessage"
import { type EgressStatus } from "@/models/iot/egress/EgressStatus"
import { merge } from "lodash"
import { devtools } from "zustand/middleware"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"
import { type NormalizedData } from "../framework/utils/normalizeByKey"

interface VehicleState {
  vehicles: NormalizedData<EgressMessage<EgressStatus>, "vehicle_id">
  updateVehicleRecord: (vehicles: Record<string, Partial<EgressMessage<Partial<EgressStatus>>>>) => void
}

const useVehicleStore = createWithEqualityFn<VehicleState>()(
  devtools(
    (set) => ({
      vehicles: {
        ids: [] as string[],
        entities: {},
      },
      updateVehicleRecord: (vehicles) => {
        set((state: VehicleState) => ({
          vehicles: {
            ids: [...new Set([...state.vehicles.ids, ...Object.keys(vehicles)])],
            entities: merge({}, state.vehicles.entities, vehicles),
          },
        }))
      },
    }),

    { name: "vehicleStore" },
  ),
  shallow,
)

export default useVehicleStore
