import { getFormattedDate, secondsToHHMM } from "../Utils"

export const states = {
  SCHEDULED: "SCHEDULED",
  PREPARING: "PREPARING",
  REVIEWING: "REVIEWING",
  CLEARED: "CLEARED",
  WAIT_ACCEPT: "WAIT_ACCEPT",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  ACCEPTED: "ACCEPTED",
  ARRIVED: "ARRIVED",
  CANCELED: "CANCELED",
  DEPARTURE_BLOCKED: "DEPARTURE_BLOCKED",
  DST_CHG_REQ: "DST_CHG_REQ",
}

const stateDisplayMap = {
  [states.SCHEDULED]: "Scheduled",
  [states.PREPARING]: "Pre-departure",
  [states.REVIEWING]: "Pre-departure",
  [states.CLEARED]: "Pre-departure",
  [states.WAIT_ACCEPT]: "Pre-departure",
  [states.PENDING]: "Pre-departure",
  [states.IN_PROGRESS]: "In Transit",
  [states.COMPLETED]: "Completed",
  [states.ACCEPTED]: "Accepted",
  [states.CANCELED]: "Canceled",
  [states.DEPARTURE_BLOCKED]: "Departure Blocked",
  [states.DST_CHG_REQ]: "Destination Change Request",
}

export function getStateDisplay(state) {
  return stateDisplayMap[state] || state
}

//This is V2 version of mission states matching

const stateDisplayMapV2 = {
  [states.SCHEDULED]: "Scheduled",
  [states.PREPARING]: "Pre-departure",
  [states.REVIEWING]: "Pre-departure",
  [states.CLEARED]: "Staged",
  [states.WAIT_ACCEPT]: "Staged",
  [states.ACCEPTED]: "Departing",
  [states.PENDING]: "Departing",
  [states.IN_PROGRESS]: "In-Transit",
  [states.COMPLETED]: "Completed",
  [states.CANCELED]: "Canceled",
  [states.ARRIVED]: "Arrived",
  [states.DEPARTURE_BLOCKED]: "Blocked",
  [states.DST_CHG_REQ]: "Destination Change Requested",
}

export function getStateDisplayV2(state) {
  return stateDisplayMapV2[state] || state
}

export const stateValues = {
  SCHEDULED: 0,
  PREPARING: 1,
  REVIEWING: 2,
  CLEARED: 3,
  WAIT_ACCEPT: 4,
  PENDING: 5,
  IN_PROGRESS: 6,
  COMPLETED: 7,
  ACCEPTED: 8,
  CANCELED: 9,
  DEPARTURE_BLOCKED: 10,
  DST_CHG_REQ: 11,
}

export function getDepartureLabel(state) {
  let value = stateValues[state]
  if (value >= stateValues.WAIT_ACCEPT) {
    return "ACTUAL"
  } else {
    return "SCHEDULED"
  }
}

export function getArrivalLabel(state) {
  let value = stateValues[state]
  if (value > stateValues.WAIT_ACCEPT && value < stateValues.COMPLETED) {
    return "ESTIMATED"
  } else if (value >= stateValues.COMPLETED) {
    return "ACTUAL"
  } else {
    return "SCHEDULED"
  }
}

export function getETADisplay(targetTime, scheduledTime) {
  let onTimeDisplay = {
    style: "is-on-time",
    label: "a ON TIME",
    visible: true,
  }

  if (targetTime < scheduledTime) {
    onTimeDisplay.style = "is-on-time"
    onTimeDisplay.label = `${secondsToHHMM((scheduledTime - targetTime) / 1000)} ON TIME`
    onTimeDisplay.visible = true
    return onTimeDisplay
  } else {
    onTimeDisplay.style = "is-delay"
    onTimeDisplay.label = `${secondsToHHMM((targetTime - scheduledTime) / 1000)} DELAYED`
    onTimeDisplay.visible = true
    return onTimeDisplay
  }
}

export function getDepartureDate(mission, format = "LL/dd hh:mm ZZZZ") {
  let state = stateValues[mission?.state]
  if (state > stateValues.WAIT_ACCEPT && mission?.msm_wait_accept_ts) {
    return format ? getFormattedDate(mission?.msm_wait_accept_ts, format) : mission?.msm_wait_accept_ts
  } else {
    return format ? getFormattedDate(mission?.tms_actual_departure_time, format) : mission?.tms_actual_departure_time
  }
}

export function getScheduledDepartureDate(mission) {
  return getFormattedDate(mission?.tms_schedule_departure_time, "LL/dd hh:mm ZZZZ")
}

export function getScheduledArrivalDate(mission) {
  return getFormattedDate(mission?.tms_schedule_arrival_time, "LL/dd hh:mm ZZZZ")
}
