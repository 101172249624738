import MissionLog from "@/features/missions/v2/Log/MissionLog"
import MissionDetailInfo from "@/features/missions/v2/MissionDetailInfo"
import MissionViewNotes from "@/features/missions/v2/Notes/MissionNotes"
import DepartureTasks from "@/features/tasks/v2/DepartureTasks"
import { useEffect, useState } from "react"
import { matchRoutes, useLocation, useNavigate } from "react-router-dom"

interface TabRoute {
  label: string
  matchRoute: "missions" | "vehicles" | "*"
  pathname: string
  role?: string
  permission?: string
  minGen?: number
  element: React.ReactNode
}

const tabRoutesV2: TabRoute[] = [
  {
    label: "DETAILS",
    matchRoute: "missions",
    pathname: "details",
    element: <MissionDetailInfo activeMission={null} />,
  },
  {
    label: "ACTIVITY LOG",
    matchRoute: "missions",
    pathname: "mission-log",
    element: <MissionLog />,
  },

  {
    label: "NOTES",
    matchRoute: "missions",
    pathname: "notes",
    element: <MissionViewNotes missionUUID={null} />,
  },
  {
    label: "TASKS",
    matchRoute: "missions",
    pathname: "tasks",
    element: <DepartureTasks drawerView={false} />,
  },
]

const useGetTabs = ({ drawerView }: { drawerView: boolean } = { drawerView: false }): TabRoute[] => {
  const location = useLocation()
  if (drawerView) {
    return tabRoutesV2.filter((tab) => !(tab.label === "Truck" && tab.pathname === "details"))
  }

  return tabRoutesV2.filter((tab) => {
    return location.pathname.includes(tab.matchRoute)
  })
}

const useTabHandler = (defaultTab = "details"): string => {
  const location = useLocation()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(defaultTab)

  const tabRoutes = useGetTabs()

  useEffect(() => {
    const routeMatches = matchRoutes(
      [
        {
          path: "/missions/v2/:load_id",
        },
        {
          path: "/vehicles/:vehicle_id",
        },
      ],
      location,
    )

    if (
      routeMatches?.[0]?.route.path === "/missions/v2/:load_id" ||
      routeMatches?.[0]?.route.path === "/vehicles/:vehicle_id"
    ) {
      navigate("details", { replace: true })
      return
    }

    setTabValue(tabRoutes.find((tabRoute) => location.pathname.includes(tabRoute.pathname))?.pathname ?? defaultTab)
  }, [location])

  return tabValue
}

export { tabRoutesV2, useGetTabs, useTabHandler }
