import { Box, Divider, Stack } from "@mui/material"
import TruckTabHeader from "./TruckTabHeader"

const TruckTab = ({ vehicle }) => {
  return (
    <Stack spacing={1} divider={<Divider flexItem sx={{ borderBottomWidth: 3 }} />}>
      <Box sx={{ display: "flex" }}>
        <TruckTabHeader vehicle={vehicle} />
      </Box>
    </Stack>
  )
}

export default TruckTab
