const awsExports = {
  API: {
    endpoints: [
      {
        name: "PortalCloudFrontApi",
        endpoint: "/api",
      },
      {
        name: "UsersApi",
        endpoint: import.meta.env.VITE_USERS_API_URL,
      },
      {
        name: "ConfigurationsApi",
        endpoint: import.meta.env.VITE_CONFIGURATIONS_API_URL,
      },
      {
        name: "RoutesApi",
        endpoint: import.meta.env.VITE_ROUTES_API_URL,
      },
      {
        name: "MissionsApi",
        endpoint: import.meta.env.VITE_MISSIONS_API_URL,
      },
      {
        name: "ActionsApi",
        endpoint: import.meta.env.VITE_ACTIONS_API_URL,
      },
    ],
  },
  Auth: {
    region: import.meta.env.VITE_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',
    identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,

    userPoolWebClientId: import.meta.env.VITE_COGNITO_WEB_CLIENT_ID,

    mandatorySignIn: false,
    oauth: {
      domain: import.meta.env.VITE_COGNITO_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: import.meta.env.VITE_COGNITO_SIGNIN_URL,
      redirectSignOut: import.meta.env.VITE_COGNITO_SIGNOUT_URL,
      responseType: "code",
    },
  },
}

export default awsExports
