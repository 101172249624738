import { MqttOverWSProvider, type MqttProviderOptions } from "@aws-amplify/pubsub"
import { Amplify } from "aws-amplify"
import { v4 as uuid } from "uuid"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface MqttStoreState {
  client: MqttOverWSProvider | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptions: Record<string, Subscription<any>>
}

interface MqttStoreActions {
  connect: (options: MqttProviderOptions) => void
  createSubscription: <U>({
    id,
    topic,
    next,
    error,
    complete,
  }: {
    id?: string
    topic: string | string[]
    next: (message: U) => void
    error: (error: Error) => void
    complete: () => void
  }) => void
  subscribe: (id: string) => void
  unsubscribe: (id: string) => void
  cleanupSubscription: (id: string) => void
}

interface Subscription<T> {
  subscription: ZenObservable.Subscription | undefined
  topic: string | string[]
  next: (message: T) => void
  error: (error: Error) => void
  complete: () => void
}

type MqttStore = MqttStoreState & MqttStoreActions

const useMqttStore = create<MqttStore>()(
  devtools(
    (set) => ({
      client: null,
      subscriptions: {},
      connect: (options) => {
        set(() => {
          const client = new MqttOverWSProvider(options)
          Amplify.addPluggable(client)
          return {
            client,
          }
        })
      },
      createSubscription: ({ id = uuid(), topic, next, error, complete }) => {
        set((state) => {
          return {
            subscriptions: {
              ...state.subscriptions,
              [id]: {
                subscription: undefined,
                topic,
                next,
                error,
                complete,
              },
            },
          }
        })
      },
      subscribe: (id) => {
        set((state) => {
          if (state.subscriptions?.[id]?.subscription !== undefined) {
            state.subscriptions?.[id]?.subscription?.unsubscribe()
          }
          const sub = state.client?.subscribe(state.subscriptions[id].topic).subscribe({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            next: (msg: any) => {
              console.debug(`MQTT::ID:${id}`, msg)
              state.subscriptions[id].next(msg)
            },
            error: state.subscriptions[id].error,
            complete: state.subscriptions[id].complete,
          })

          return {
            subscriptions: {
              ...state.subscriptions,
              [id]: {
                ...state.subscriptions[id],
                subscription: sub,
              },
            },
          }
        })
      },
      unsubscribe: (id) => {
        set((state) => {
          if (state.subscriptions?.[id]?.subscription !== undefined) {
            state.subscriptions?.[id]?.subscription?.unsubscribe()
          }

          return {
            subscriptions: {
              ...state.subscriptions,
              [id]: {
                ...state.subscriptions[id],
                subscription: undefined,
              },
            },
          }
        })
      },
      cleanupSubscription: (id) => {
        set((state) => {
          if (state.subscriptions?.[id]?.subscription !== undefined) {
            state.subscriptions?.[id]?.subscription?.unsubscribe()
          }

          const { [id]: _, ...subscriptions } = state.subscriptions

          return {
            subscriptions,
          }
        })
      },
    }),
    { name: "mqttStore", serialize: true },
  ),
)

export default useMqttStore
