import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton } from "@mui/material"
import { useRef, useState } from "react"

/**
 * SearchInput, a reusable custom search input component
 * @param {object} props - props
 * @param {string} props.search - search
 * @param {function} props.updateSearch - updateSearch
 * @param {string} props.placeholder - placeholder
 * @returns
 */
const SearchInput = ({ search, updateSearch, placeholder = "Search..." }) => {
  const [searchActive, setSearchActive] = useState(false)
  const searchFieldRef = useRef(null)
  const searchClearRef = useRef(null)
  const handleSearchClick = () => {
    setSearchActive(true)
    searchFieldRef.current.focus({ preventScroll: true })
  }

  const handleSearchClear = () => {
    searchFieldRef.current.blur()
    searchClearRef.current.blur()
    setSearchActive(false)
    updateSearch("")
  }

  return (
    <div
      className={`search-container ${search || searchActive ? "search-container--active" : ""}`}
      data-testid="search-container"
    >
      <IconButton className="searchbar__search-icon" data-testid="search-icon" onClick={handleSearchClick}>
        <SearchIcon />
      </IconButton>
      <input
        ref={searchFieldRef}
        className="searchbar__search-input"
        data-testid="search-input"
        placeholder={placeholder}
        value={search}
        onChange={(e) => updateSearch(e.target.value)}
      />
      <IconButton
        ref={searchClearRef}
        data-testid="search-clear"
        data-dd-action-name="search-clear"
        onClick={handleSearchClear}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default SearchInput
