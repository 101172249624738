import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AssignmentIcon from "@mui/icons-material/Assignment"
import WarningIcon from "@mui/icons-material/Warning"
import { Box, Chip, Tooltip, Typography } from "@mui/material"
import "./Task.scss"
import { emailToName } from "@/features/missions/v2/Utils"

function colorConfig(state, assignee) {
  if (!assignee) {
    return ["#fa9a37", "#fff3e0", "#fa9a37", "#fff3e0"]
  } else if (state.toLowerCase() === "completed") {
    return ["#d7d7d7", "#ffffff", "#d7d7d7", "#f2f2f2"]
  } else {
    return ["#ccd8e0", "#f2f2f2", "#7f7f7f", "#d7d7d7"]
  }
}

const Task = ({ assignee, title, state }) => {
  const [borderColor, backgroundColor, hoverBorder, hoverBackground] = colorConfig(state, assignee)

  const status = state.toLowerCase() === "not started" ? "not-started" : state.toLowerCase()
  const statusDisplay = state.toLowerCase() === "incomplete" ? "in progress" : state.toLowerCase()

  return (
    <Box
      className="task"
      data-testid="task"
      sx={{
        borderColor: { borderColor },
        backgroundColor: { backgroundColor },
        "&:hover": {
          backgroundColor: hoverBackground,
          borderColor: hoverBorder,
        },
      }}
    >
      <Box className="task__type">
        <AssignmentIcon />
        <Typography>{title}</Typography>
      </Box>
      <Box className="task__assignee">
        <Tooltip
          className="custom-width-tooltip"
          componentsProps={{
            tooltip: { sx: { maxWidth: "80vw" } },
          }}
          title={
            <Box display="flex" sx={{ overflowX: "auto" }}>
              <Box display="flex" alignItems="center" justifyContent="center" mr={1}>
                {assignee ? (
                  <AccountCircleIcon sx={{ color: "#ccac7c", fontSize: "2.5em" }} />
                ) : (
                  <WarningIcon sx={{ color: "#fa9a37", fontSize: "2.5em" }} />
                )}
              </Box>
              <Box>
                <Typography noWrap sx={{ fontSize: "1em" }}>{`Name: ${emailToName(assignee)}`}</Typography>
                <Typography noWrap sx={{ fontSize: "1em" }}>{`Email: ${assignee}`}</Typography>
              </Box>
            </Box>
          }
        >
          {
            <Box className="task__assignee__details" gap={1}>
              {assignee ? <AccountCircleIcon sx={{ color: "#ccac7c" }} /> : <WarningIcon sx={{ color: "#fa9a37" }} />}
              <Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {emailToName(assignee)}
              </Typography>
            </Box>
          }
        </Tooltip>
      </Box>

      <Box className={`task__status`}>
        <Chip className={`${status}`} label={statusDisplay} data-testid="status-chip" />
      </Box>
    </Box>
  )
}

export default Task
