import { useGetTabs, useTabHandler } from "@/hooks/detailTabsHelper"
import useGetMeQuery from "@/hooks/query/user/useGetMeQuery"
import { hasAnyPermissions, isBreakRoomUser } from "@/hooks/UserHooks"
import ErrorIcon from "@mui/icons-material/Error"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import { useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useGetVehicle, useGetVehicleConnectivity } from "../../hooks/vehiclesV2Hooks"
import DepartureTasks from "../tasks/DepartureTasks"
import TruckDetails from "../vehicles/TruckDetails"
import MissionLog from "./Log/MissionLog"
import MissionDetailInfo from "./MissionDetailInfo"
import MissionViewNotes from "./Notes/MissionNotes"

const MissionDetailsTabs = ({ mission, drawerView = false }) => {
  // TODO - REFACTOR THIS WHOLE COMPONENT, IT'S A MESS
  // drawer view vs main view is a mess
  const { userData } = useGetMeQuery()
  const navigate = useNavigate()
  const currentTab = useTabHandler()
  const [drawerTab, setDrawerTab] = useState("details")

  // TODO - passing in the vehicle id is temporary, remove when this hook is a lil more fleshed out
  const vehicle = useGetVehicle(mission?.vehicle_id)
  const vehicleConnectivity = useGetVehicleConnectivity(mission?.vehicle_id)

  const tabsList = useGetTabs({ drawerView })

  const filteredGenTabs = tabsList.filter((tab) => !tab?.minGen || tab.minGen < parseFloat(vehicle?.gen))
  const authorizedTabs = filteredGenTabs.filter(
    (tab) => !tab?.permission || hasAnyPermissions(userData, [tab.permission]),
  )

  const handleTabChange = (_event, newValue) => {
    if (drawerView) {
      setDrawerTab(newValue)
      return
    }
    navigate(newValue, { replace: true })
  }

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <TabContext value={drawerView ? drawerTab : currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="mission details tabs" variant="scrollable">
            {authorizedTabs.map((tab) => (
              <Tab
                data-testid={tab.pathname}
                key={tab.pathname}
                className="mission-details-tab-names"
                label={
                  <span>
                    {tab.label}
                    {tab.label === "Truck" ? (
                      vehicleConnectivity?.status === "disconnected" ? (
                        <ErrorIcon sx={{ color: "red" }} />
                      ) : null
                    ) : null}
                  </span>
                }
                value={tab.pathname}
                hidden={
                  // TODO - refactor the very bad hardcoding here
                  tab.pathname === "pre-departure" && isBreakRoomUser(userData)
                }
              />
            ))}
          </TabList>
        </Box>

        <div className="mission-details-tabs" style={{ overflow: "auto", padding: ".5rem" }}>
          {!drawerView ? (
            <Outlet />
          ) : (
            <>
              <TabPanel value="pre-departure">
                <DepartureTasks activeMission={mission} drawerView={drawerView} vehicle={vehicle} />
              </TabPanel>
              <TabPanel value="details">
                <MissionDetailInfo activeMission={mission} />
              </TabPanel>
              <TabPanel value="mission-log">
                <MissionLog missionUUID={mission?.uuid} />
              </TabPanel>
              <TabPanel value="vehicle">
                <TruckDetails vehicle={vehicle} mission={mission} />
              </TabPanel>
              <TabPanel value="notes" style={{ padding: 0 }}>
                <MissionViewNotes missionUUID={mission?.uuid} drawerView={drawerView} />
              </TabPanel>
            </>
          )}
        </div>
      </TabContext>
    </Box>
  )
}

export default MissionDetailsTabs
