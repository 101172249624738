import UnknownIcon from "@/assets/img/unknown.png"
import { useMemo, useState } from "react"

function MuiSmallTruckImage({ vehicleId, ...rest }) {
  const [truckImage, setTruckImage] = useState("")

  useMemo(() => {
    // TODO - figure out how svg imports work with vite... this seems like a hack
    const modules = import.meta.glob("../assets/img/decals/*.svg", { eager: true })
    setTruckImage(modules?.[`../assets/img/decals/${vehicleId}.svg`]?.default || UnknownIcon)
  }, [])

  return (
    <img
      src={new URL(truckImage, import.meta.url).href}
      alt={vehicleId}
      style={{ width: "40px", height: "auto" }}
      {...rest}
    />
  )
}

export default MuiSmallTruckImage
