import { getFormattedDate } from "@/framework/Utils"
import { Box, Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import _ from "lodash"
import { useMemo, useState } from "react"
import "./Log.scss"

const Log = ({ logs }) => {
  const rowsPerPage = 20
  const pageCount = Math.ceil(_.size(logs) / rowsPerPage)
  const [page, setPage] = useState(1)
  const visibleLogs = _.slice(logs, (page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)

  const handlePageChange = (e, pageNumber) => setPage(pageNumber)

  const logRows = useMemo(() => {
    return _.map(visibleLogs, (event, index) => (
      <TableRow key={event.log_uuid} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        <TableCell className="event-cell">
          {event.category
            ? _.startCase(_.toLower(_.replace(event.category, /_/g, " ")))
            : _.startCase(_.toLower(_.replace(event.title, /_/g, " ")))}
        </TableCell>
        <TableCell className="time-cell">{getFormattedDate(event.creation_ts * 1000, "MM/dd HH:mm ZZZZ")}</TableCell>
        <TableCell className="details-cell">
          <Typography className="log-data">{event.description}</Typography>
          {event.resolution && (
            <Typography className="log-resolution-text">
              <strong>Potential actions to resolve:</strong> {event.resolution}
            </Typography>
          )}
          {event.details?.body ? (
            <Typography variant="log-data">
              <strong>Additional Details:</strong> {event.details.body}
            </Typography>
          ) : null}
        </TableCell>
      </TableRow>
    ))
  }, [logs, page])

  return (
    <>
      {!_.isEmpty(logs) ? (
        <Box>
          <Typography id="log-event-time">
            <strong>Start Time</strong>
            <span>{getFormattedDate(logs[logs.length - 1].creation_ts * 1000, "ccc, LLL d, yyyy, HH:mm ZZZZ")}</span>
          </Typography>

          <Table className="log-event-table">
            <TableHead>
              <TableRow className="thead">
                <TableCell className="thead-cell">Event</TableCell>
                <TableCell className="thead-cell">Time</TableCell>
                <TableCell className="thead-cell">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{logRows}</TableBody>
          </Table>
          {pageCount > 1 && (
            <Grid container justifyContent={"center"} marginTop={1} marginBottom={1}>
              <Grid item>
                <Pagination
                  page={page}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <Box className="operational">
          <Typography>No logs at this time</Typography>
        </Box>
      )}
    </>
  )
}

export default Log
