import useGetV2VehicleStatus from "@/hooks/query/vehicle/useGetV2VehicleStatus"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleIcon from "@mui/icons-material/Circle"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, Grid } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Slide from "@mui/material/Slide"
import Typography from "@mui/material/Typography"
import * as React from "react"
import MissionInfoBox from "./MissionInfoBox"
import "./scss/MissionVehicleDetailGrid.scss"
import { AutonomousIndicator } from "@/features/vehicles/AutonomousIndicator"
import { useGetVehicle, useGetVehicleConnectivity } from "@/hooks/vehiclesV2Hooks"
import CancelIcon from "@mui/icons-material/Cancel"

const HEALTH = ["Routes", "Vehicles", "Remote-Cockpit"]

const MissionVehicleDetailGrid = ({ mission }) => {
  const vehicleId = mission?.vehicle_id
  const vehicle = useGetVehicle()
  const selectedConnection = useGetVehicleConnectivity(vehicleId)
  const connectionStatus = selectedConnection?.status
  const services = selectedConnection?.services || []
  const { data: vehicleStatus } = useGetV2VehicleStatus(vehicleId)
  const [expanded, setExpanded] = React.useState(false)

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded)
  }
  const fuel = Math.round(vehicleStatus?.fuel)
  const odometer = Math.round(vehicleStatus?.odometer * 0.000621371)
  const fuelGallons = vehicleStatus?.fuel * vehicle?.fuel_tank_size

  return (
    <>
      <div className="mission-details-gridv2__container">
        <Grid container direction="column" justifyContent="space-between" spacing={2}>
          <Grid container item spacing={1} alignItems="stretch" columns={8}>
            <Grid item xs={2}>
              <MissionInfoBox
                title="TRUCK"
                detail={vehicleId}
                subtext={!isNaN(odometer) ? `${odometer} miles` : "--- miles"}
              />
            </Grid>
            <Grid item xs={2}>
              <MissionInfoBox title="TRAILER" detail={"TRL-00055"} />
            </Grid>
            <Grid item xs={2}>
              <MissionInfoBox
                title="Fuel/Range"
                detail={!isNaN(fuelGallons) ? `${fuelGallons} gal` : "--- gal"}
                subtext={fuel ? fuel + "% Full" : "---"}
              />
            </Grid>
            <Grid item xs={2}>
              <MissionInfoBox title="ADS MODE" detail={<AutonomousIndicator vehicleStatus={vehicleStatus} />} />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className="health__container">
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          TransitionProps={{
            timeout: 500,
            easing: "cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="accordian-header">
            <Box display="flex" alignItems="center" sx={{ paddingLeft: "15px" }}>
              <Typography>HEALTH</Typography>

              {connectionStatus === "connected" ? (
                <CheckCircleIcon sx={{ color: "#71bf5e", fontSize: "20px", marginLeft: "15px" }} />
              ) : (
                <CancelIcon sx={{ color: "#fa3751", fontSize: "20px", marginLeft: "15px" }} />
              )}

              <Typography
                sx={{
                  color: connectionStatus === "connected" ? "#71bf5e" : "#fa3751",
                }}
                id="operational"
              >
                {connectionStatus === "connected" ? "OPERATIONAL" : "DISCONNECTED"}
              </Typography>
            </Box>
          </AccordionSummary>

          <Slide in={expanded} direction="up" mountOnEnter unmountOnExit>
            <AccordionDetails className="accordian-details">
              <List>
                {HEALTH.map((value) => {
                  const service = services.find((service) => service?.service === value.toLocaleLowerCase())

                  return (
                    <ListItem key={value}>
                      <CircleIcon
                        sx={{
                          color: service?.connected ? "#71bf5e" : "#fa3751",
                        }}
                        className="bullet-icon"
                      />
                      <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
                    </ListItem>
                  )
                })}
              </List>
            </AccordionDetails>
          </Slide>
        </Accordion>
      </div>
    </>
  )
}

export default MissionVehicleDetailGrid
