import ErrorBoundary from "@/components/ErrorBoundary"
import useGlobalSubscriptions from "@/hooks/subscriptions/useGlobalSubscriptions"
import useNotificationSubscription from "@/hooks/subscriptions/useNotificationSubscription"
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"

const MfuiLayout = () => {
  useGlobalSubscriptions()
  useNotificationSubscription()

  return (
    <>
      <ToastContainer className="toast-top" theme="colored" position="top-center" />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}

export default MfuiLayout
