import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { toast } from "react-toastify"

const MissionCancel = (mission) => {
  const queryClient = useQueryClient()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const disableCancel = mission.mission?.state == "COMPLETED" || mission.mission?.state == "CANCELED" ? true : false

  /* istanbul ignore next */
  const mutation = useMutation({
    mutationFn: () => MissionsApi.postCancelMission({ uuid: mission.mission?.uuid }),
    onSuccess: (data) => {
      toast.success("Successfully canceled mission", data)
      queryClient.invalidateQueries({ queryKey: ["mission", mission.mission?.uuid] })
    },
  })

  const cancelMission = () => {
    setShowConfirmModal(true)
  }

  const confirmCancelMission = async () => {
    try {
      await mutation.mutateAsync()
    } catch (err) {
      toast.error("Unable to cancel mission.")
    }
    setShowConfirmModal(false)
  }

  return (
    <Box>
      {showConfirmModal && (
        <Dialog open={showConfirmModal} onClose={() => setShowConfirmModal(false)} data-testid="cancel-mission-dialog">
          <DialogTitle data-testid="cancel-mission-dialog-title">
            Are you sure you want to cancel this mission?
          </DialogTitle>
          <DialogActions>
            <Button
              data-testid="confirm-cancel-mission"
              type="submit"
              variant="contained"
              onClick={() => confirmCancelMission()}
            >
              Confirm
            </Button>
            <Button
              data-testid="cancel-cancel-mission"
              type="submit"
              variant="outlined"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        data-testid="open-mission-cancel"
        type="submit"
        variant="contained"
        disabled={disableCancel}
        onClick={() => cancelMission()}
      >
        Cancel Mission
      </Button>
    </Box>
  )
}

export default MissionCancel
