import { Tooltip } from "@mui/material"
import { createContext, useContext, useState } from "react"
import { isHotkeyPressed, useHotkeys } from "react-hotkeys-hook"

export const HotkeyContext = createContext(null)

export function HotkeyContextProvider({ children }) {
  const [showHotkeys, setShowHotkeys] = useState(false)
  const contextValue = { showHotkeys: showHotkeys }
  useHotkeys(["meta"], () => setShowHotkeys(isHotkeyPressed("meta")), {
    keyup: true,
    keydown: true,
    enableOnFormTags: true,
  })
  return <HotkeyContext.Provider value={contextValue}>{children}</HotkeyContext.Provider>
}

export const HotkeyTooltip = ({ shortcut, children }) => {
  const hotkeyOverlay = useContext(HotkeyContext)
  return (
    <Tooltip title={shortcut} open={hotkeyOverlay?.showHotkeys} placement="top">
      {children}
    </Tooltip>
  )
}
