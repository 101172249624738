import useGetVehicleConnectivityV2Query from "@/hooks/query/vehicle/useGetVehicleConnectivityV2Query"
import { useGetVehicleConnectivity, useGetVehicleId } from "@/hooks/vehiclesV2Hooks"
import SensorsIcon from "@mui/icons-material/Sensors"
import { LinearProgress, Typography } from "@mui/material"
import HealthDropdown from "./HealthDropdown"

const HEALTH = ["Routes", "Vehicles", "Remote-Cockpit"]

const ServiceStatusDetails = ({ vehicle, vehicleConnectivity }) => {
  const vehicleId = useGetVehicleId() ?? vehicle?.vehicle_id
  const serviceStatusDetailsQuery = useGetVehicleConnectivityV2Query(vehicleId)
  const selectedConnection = useGetVehicleConnectivity(vehicleId) ?? vehicleConnectivity

  const services = selectedConnection?.services

  const connectionStatus =
    !selectedConnection?.status || selectedConnection.status === "disconnected"
      ? "ERROR"
      : selectedConnection.status === "partially_connected"
        ? "WARN"
        : "OK"

  const title = (
    <Typography sx={{ fontSize: "0.85em" }}>
      Service Statuses:{" "}
      <strong>{connectionStatus === "ERROR" || connectionStatus === "WARN" ? "1 FAULT" : "CONNECTED"}</strong>
    </Typography>
  )

  return serviceStatusDetailsQuery.isLoading ? (
    <LinearProgress sx={{ m: "1rem" }} />
  ) : serviceStatusDetailsQuery.isError ? (
    <Typography variant="overline">The services for vehicle &apos;{vehicleId}&apos; were not found</Typography>
  ) : (
    selectedConnection && (
      <HealthDropdown
        title={title}
        connectionStatus={connectionStatus}
        Icon={SensorsIcon}
        components={HEALTH}
        services={services}
        vehicleStatus={true}
      />
    )
  )
}
export default ServiceStatusDetails
