import useGetMissionRouteQuery from "@/hooks/query/routes/useGetMissionRouteQuery"
import { Typography } from "@mui/material"

const MissionRoute = ({ mission }) => {
  var { data, isFetched } = useGetMissionRouteQuery(mission?.uuid)

  var route = ""

  if (isFetched && data) {
    route = data[0]?.route_id ? data[0].route_id : ""
  }

  return (
    <div className="mission-route-details">
      <div>
        <Typography className="mission-route-details__label">PLANNED ROUTE</Typography>
      </div>
      <div className="mission-route-details__route">
        <Typography className="mission-route-details__route" data-testid="route-details">
          {route}
        </Typography>
      </div>
    </div>
  )
}

export default MissionRoute
