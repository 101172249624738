import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionByLoadIdQuery = (loadId: string | null) => {
  const missionQuery = useQuery({
    queryKey: ["missions", loadId],
    queryFn: async () => await MissionsApi.getMissions({ loadId }),
    enabled: !isEmpty(loadId),
  })

  return missionQuery
}

export default useGetMissionByLoadIdQuery
