import { postNoteMutationFn, postNoteOnError, postNoteOnSuccess } from "@/hooks/query/notes/usePostNoteMutation"
import { putNoteMutationFn, putNoteOnError, putNoteOnSuccess } from "@/hooks/query/notes/usePutNoteMutation"
import { QueryClient } from "@tanstack/react-query"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 500,
    },
    mutations: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retryDelay: 500,
    },
  },
})

queryClient.setMutationDefaults(["mission", "notes-post"], {
  mutationFn: postNoteMutationFn,
  onSuccess: (data) => postNoteOnSuccess({ missionUuid: data.mission_uuid, queryClient: queryClient }),
  onError: postNoteOnError,
})

queryClient.setMutationDefaults(["mission", "notes-put"], {
  mutationFn: putNoteMutationFn,
  onSuccess: (data) => putNoteOnSuccess({ missionUuid: data.mission_uuid, queryClient: queryClient }),
  onError: putNoteOnError,
})

export default queryClient
