import MfuiLayout from "@/features/layouts/MfuiLayout"
import SettingsPage from "@/features/settings/SettingsPage"
import { createBrowserRouter } from "react-router-dom"
import MainRoutes from "./main/MainRoutes"

const router = createBrowserRouter([
  {
    path: "/",
    element: <MfuiLayout />,
    children: [
      MainRoutes,
      {
        path: "settings",
        element: <SettingsPage />,
      },
    ],
  },

  // error boundary?
  {
    path: "*",
    element: <div>404</div>,
  },
])

export default router
