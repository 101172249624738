import ReplyIcon from "@mui/icons-material/Reply"
import { Box, IconButton, Typography } from "@mui/material"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import "./scss/MissionDetailsHeader.scss"
import { useGetMissionByLoadId } from "@/hooks/missionHooks"
import useSearchStore from "@/store/useSearchStore"
import CloseIcon from "@mui/icons-material/Close"
import { truncateText } from "./Utils"

const MissionDetailsHeader = ({ hideBackNavigation = false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const mission = useGetMissionByLoadId()
  const [locationState] = useState(location.state ?? null)
  const { status } = useSearchStore((state) => state.mission)

  return (
    <>
      <div data-testid={`mission-header_${mission?.load_id}`} className="mission-details-header">
        <div
          className="back-option"
          onClick={() =>
            locationState?.from
              ? navigate(`/${locationState.from}/v2`)
              : navigate(`/${location.pathname.split("/")[1]}/v2`)
          }
        >
          {!hideBackNavigation && (
            <>
              <IconButton
                aria-label="back"
                size="small"
                sx={{ color: "#009afa" }}
                data-testid="back-btn"
                data-dd-action-name="back-btn"
              >
                <ReplyIcon />
              </IconButton>
              <Typography className="list-field">Missions List</Typography>
            </>
          )}
        </div>

        <div className="status-selector">
          <ToggleButtonGroup value={status} exclusive className="custom-toggle-group">
            <ToggleButton value="all" className="custom-toggle-button">
              All
            </ToggleButton>
            <ToggleButton value="departures" className="custom-toggle-button">
              Departures
            </ToggleButton>
            <ToggleButton value="arrivals" className="custom-toggle-button">
              Arrivals
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }} className="missions-bar">
        <Box
          sx={{ flexGrow: 1, textAlign: "center" }}
          data-testid="load_id"
        >{`${mission?.load_id && truncateText(mission?.load_id, 25)} Mission Details`}</Box>
        <div
          className="close-btn"
          onClick={() =>
            locationState?.from
              ? navigate(`/${locationState.from}/v2`)
              : navigate(`/${location.pathname.split("/")[1]}/v2`)
          }
        >
          {!hideBackNavigation && (
            <>
              <IconButton
                aria-label="back"
                size="small"
                sx={{ color: "white" }}
                data-testid="close-btn"
                data-dd-action-name="close-btn"
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      </Box>
    </>
  )
}

export default MissionDetailsHeader
