import VehiclesHoverTooltip from "@/features/map/hover/VehiclesHoverTooltip"
import { getVehicleIconState } from "@/framework/utils/MissionState"
import useGetVehiclesConnectivityInfo from "@/hooks/query/vehicle/useGetVehiclesConnectivityInfo"
import useGetVehiclesMissionInfo from "@/hooks/query/vehicle/useGetVehiclesMissionInfo"
import { useGetVehicle, useGetVehiclesFiltered } from "@/hooks/vehiclesV2Hooks"
import useVehicleStore from "@/store/useVehicleStore"
import { IconLayer } from "@deck.gl/layers"
import DirectionalIcon from "../../../assets/img/truck-icons/directional.svg"
import ErrorDirectionIcon from "../../../assets/img/truck-icons/error-directional.svg"
import InactiveDirectionalIcon from "../../../assets/img/truck-icons/inactive-default-directional.svg"
import WarningDirectionalIcon from "../../../assets/img/truck-icons/warning-directional.svg"
import WarningIcon from "../../../assets/img/truck-icons/Warning.svg"
import useVehicleHover from "./useVehicleHover"

const useVehiclesLayer = () => {
  const filteredVehicles = useGetVehiclesFiltered().filteredVehicles?.map((v) => v.vehicle_id)

  const selectedVehicle = useGetVehicle()

  const vehicles = useVehicleStore((state) =>
    Object.values(state.vehicles.entities)
      .flatMap((v) => v)
      .filter((vehicle) => (selectedVehicle ? vehicle.vehicle_id === selectedVehicle.vehicle_id : true))
      .filter((vehicle) => filteredVehicles?.includes(vehicle.vehicle_id))
      .filter((vehicle) => vehicle?.data?.coordinates?.latitude && vehicle?.data?.coordinates?.longitude),
  )

  const vehicleConnectivityInfo = useGetVehiclesConnectivityInfo(vehicles)

  const vehiclesMissionInfo = useGetVehiclesMissionInfo(vehicles)

  const handleVehicleHover = useVehicleHover()

  function getDirectionalIcon(vehicle) {
    const missionStateValue = getVehicleIconState(vehiclesMissionInfo[vehicle.vehicle_id])
    if (vehicle?.current_mission || missionStateValue == "IN_PROGRESS") {
      return vehicleConnectivityInfo[vehicle.vehicle_id] === "disconnected"
        ? ErrorDirectionIcon
        : vehicleConnectivityInfo[vehicle.vehicle_id] === "partially_connected"
          ? WarningDirectionalIcon
          : DirectionalIcon
    } else null
  }

  function getInactiveDirectionalIcon(vehicle) {
    const missionStateValue = getVehicleIconState(vehiclesMissionInfo[vehicle.vehicle_id])
    if (missionStateValue !== "IN_PROGRESS") {
      return InactiveDirectionalIcon
    }
  }

  function getWarningIcon(vehicle) {
    const isInProgress = getVehicleIconState(vehiclesMissionInfo[vehicle.vehicle_id]) == "IN_PROGRESS"
    const hasWarning =
      vehicleConnectivityInfo[vehicle.vehicle_id] === "disconnected" ||
      vehicleConnectivityInfo[vehicle.vehicle_id] === "partially_connected"
    if (isInProgress && hasWarning) {
      return WarningIcon
    }
  }

  //DECKGL LAYERS

  const getPosition = (d, index) => [d?.data?.coordinates?.longitude, d?.data?.coordinates?.latitude, index * 10]

  const activeVehiclesLayer = new IconLayer({
    id: "active-vehicles-layer",
    data: vehicles,
    pickable: true,
    getPosition: getPosition,
    getIcon: (d) => ({
      url: new URL(getDirectionalIcon(d), import.meta.url).href,
      width: 37,
      height: 51,
      anchorY: 32.5,
    }),
    getSize: () => 40,
    onHover: ({ object }) =>
      handleVehicleHover(<VehiclesHoverTooltip vehicleId={object?.vehicle_id} />, object?.vehicle_id),
    getAngle: (d) => -d?.data?.heading ?? 0,
  })

  const inactiveVehiclesLayer = new IconLayer({
    id: "inactive-vehicles-layer",
    data: vehicles,
    pickable: true,
    getPosition: getPosition,
    getIcon: (d) => ({
      url: new URL(getInactiveDirectionalIcon(d), import.meta.url).href,
      width: 37,
      height: 51,
    }),
    getSize: () => 25,
    onHover: ({ object }) =>
      handleVehicleHover(<VehiclesHoverTooltip vehicleId={object?.vehicle_id} />, object?.vehicle_id),
  })

  const warningIconLayer = new IconLayer({
    id: "warning-icon-layer",
    data: vehicles,
    pickable: true,
    getIcon: (d) => ({
      url: new URL(getWarningIcon(d), import.meta.url).href,
      width: 11,
      height: 11,
    }),
    getSize: () => 10,
    getPosition: (d) => getPosition(d),
  })

  const combinedLayers = [activeVehiclesLayer, warningIconLayer, inactiveVehiclesLayer]

  return combinedLayers
}

export default useVehiclesLayer
