import { Typography } from "@mui/material"
import CustomHeartMonitorIcon from "../../../missions/icons/HeartMonitor"
import HealthDropdown from "./HealthDropdown"

const COMPONENTS = ["Mission Health"]

const SDTHealth = ({ mission }) => {
  const connectionStatus = mission?.health
  const services = mission?.health

  const title = (
    <Typography sx={{ fontSize: "0.85em" }}>
      SDT Health:{" "}
      <strong>{connectionStatus === "ERROR" || connectionStatus === "WARN" ? "1 FAULT" : "OPERATIONAL"}</strong>
    </Typography>
  )

  return (
    <HealthDropdown
      title={title}
      connectionStatus={connectionStatus}
      Icon={CustomHeartMonitorIcon}
      components={COMPONENTS}
      services={services}
      vehicleStatus={false}
    />
  )
}
export default SDTHealth
