import MissionsApi from "@/framework/utils/api/MissionsAPI"
import CloseIcon from "@mui/icons-material/Close"
import DescriptionIcon from "@mui/icons-material/Description"
import { Box, IconButton, Stack, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Form from "@rjsf/core"
import validator from "@rjsf/validator-ajv8"
import { useMutation } from "@tanstack/react-query"
import _ from "lodash"
import { useContext, useRef, useState } from "react"
import { toast } from "react-toastify"
import RouteSelection from "../../reports/routes/v2/RouteSelection"
import ReviewCheckbox from "../../review-checkbox/ReviewCheckbox"
import "../../reports/css/RouteSelection.scss"
import CustomToggleFieldTemplate from "../templates/CustomToggleFieldTemplate"
import ToggleCheck from "../widgets/ToggleCheck"
import "../Form.scss"
import SDTHealth from "@/features/vehicles/ServiceStatus/v2/SDTHealth"
import ServiceStatusDetails from "@/features/vehicles/ServiceStatus/v2/ServiceStatusDetails"
import useGetMeQuery from "@/hooks/query/user/useGetMeQuery"
import { ErrorSchemaBuilder } from "@rjsf/utils"
import TaskForm, { TaskFormContext } from "../TaskForm"
import ErrorListTemplate from "../templates/ErrorListTemplate"
import CustomToggle from "../widgets/CustomToggle"

const RequestForm = (props) => {
  return (
    <TaskForm {...props}>
      <FormInternal {...props} />
    </TaskForm>
  )
}

const FormInternal = (props) => {
  const { mission, action, vehicle } = props
  const { onSubmit, onCancel } = useContext(TaskFormContext)
  const [didSubmit, setDidSubmit] = useState(false)
  const [extraErrors, setExtraErrors] = useState({})

  let currentMission = _.get(mission, "uuid", {})
  const theme = useTheme()
  const { data: userData } = useGetMeQuery()
  const [route_info, setRouteInfo] = useState({
    route_id: parseFloat(vehicle?.gen) < 3.0 ? mission?.mission_route : "",
    total_time: null,
    total_miles: null,
  })

  const formRef = useRef()

  const getRouteInfo = (route_id, total_time, total_miles) => {
    if (parseFloat(vehicle?.gen) >= 3.0) {
      setRouteInfo({ route_id, total_time, total_miles })
    }
  }

  const postRoute = useMutation({
    mutationFn: () =>
      MissionsApi.postMissionRoute({
        missionUuid: mission?.uuid,
        requestBody: {
          route_id: route_info.route_id,
          total_miles: route_info.total_miles,
          total_time: route_info.total_time,
        },
      }),
    onSuccess: (data) => {
      toast.success("Successfully updated mission route", data)
    },
  })

  const checkExtraErrors = () => {
    const builder = new ErrorSchemaBuilder()

    if (parseFloat(vehicle?.gen) < 3.0 && route_info?.route_id === "CACTUS") {
      builder.addErrors("CACTUS route not supported on 2.3 vehicles")
    }
    return builder.ErrorSchema
  }

  const onSubmitAction = async () => {
    const form = formRef.current
    setDidSubmit(true)

    const { errors } = await form.validate(form.state.formData)
    const newExtraErrors = checkExtraErrors()
    setExtraErrors(newExtraErrors)

    if (currentMission && _.isEmpty(errors) && _.isEmpty(newExtraErrors)) {
      const user = _.get(userData, "email", "")
      action["state"] = "INCOMPLETE"
      action["completed_by"] = user
      delete action.last_updated

      try {
        if (parseFloat(vehicle?.gen) >= 3.0) await postRoute.mutateAsync()
      } catch (err) {
        toast.error("Mission route was unable to be sent to the vehicle. Please try again.")
        return
      }

      let route_dropdown = document.getElementById("route-selection")
      let route_display = route_dropdown?.innerText
      let arrival_dropdown = document.getElementById("arrival-selections__arrival_route")
      let arrival_display = arrival_dropdown?.innerText
      let laps_input = document.getElementById("arrivals__number_input")
      let laps = laps_input?.value ? Number(laps_input.value) : undefined
      let vias_dropdown = document.getElementById("arrival-selections__waypoint_route")
      let via = vias_dropdown?.innerText

      action["formData"] = {
        route_id: route_info,
        selected_route: parseFloat(vehicle.gen) < 3.0 ? route_info : route_display,
        lap_count: laps,
        via_name: via,
        arrival_location:
          parseFloat(vehicle.gen) < 3.0
            ? `${mission.destination_display} ${mission.destination_zone}`
            : arrival_display,
        ...form.state.formData,
      }

      onSubmit(action)
      onCancel()
    }
  }

  const widgets = {
    ToggleCheck: ToggleCheck,
    CustomToggle: CustomToggle,
  }

  return action ? (
    <Box>
      <Box className="request-form-header">
        <DescriptionIcon sx={{ color: theme.palette.background.default }} fontSize="large" />
        <Typography variant="h6" data-testid="vehicle-id" sx={{ color: theme.palette.background.default }}>
          {_.get(action, "title", "")} - {mission.vehicle_id}
        </Typography>
        <IconButton
          sx={{ marginLeft: "auto", color: theme.palette.background.default }}
          onClick={onCancel}
          data-testid="close-action-form"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="request-form" data-testid="create-form" sx={{ overflowY: "auto" }}>
        <Stack className="route-details" sx={{ padding: 2 }}>
          <Box sx={{ borderBottom: "1px solid #d7d7d7" }}>
            <Typography className="mission-route-details__mission-label">{mission?.load_id}</Typography>
            <Typography className="mission-route-details__truck-name">
              {vehicle?.vehicle_id}
              {vehicle?.name ? `-${vehicle.name}` : null}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "0.85em", color: "#7f7f7f", paddingBottom: "1em" }}>
            Please review statuses of the mission, truck, and environmental conditions.
          </Typography>
          <SDTHealth mission={mission} />
          <ServiceStatusDetails vehicle={vehicle} vehicleConnectivity={null} />
          <RouteSelection mission={mission} vehicle={vehicle} action={action} postRoute={getRouteInfo} />
        </Stack>
        <Form
          schema={_.get(action, "template", {})}
          uiSchema={_.get(action, "template_ui", {})}
          formData={_.get(action, "formData", {})}
          validator={validator}
          role="form"
          name="RequestForm"
          ref={formRef}
          disabled={action?.state == "COMPLETED"}
          onChange={(e) => {
            action.formData = e.formData
          }}
          liveValidate={didSubmit}
          widgets={widgets}
          formContext={{
            type: action?.type,
          }}
          templates={{ ObjectFieldTemplate: CustomToggleFieldTemplate, ErrorListTemplate }}
          extraErrors={extraErrors}
          extraErrorsBlockSubmit={true}
        >
          <ReviewCheckbox
            action={action}
            onSubmit={onSubmitAction}
            onclose={onCancel}
            route_info={route_info?.route_id}
            title={"CLEAR FOR DEPARTURE"}
            checkboxText="I have reviewed and acknowledge that autonomous system, operating environment and mission are healthy"
            closeText="Close"
            submitText="Send Mission"
          />
        </Form>
      </Box>
    </Box>
  ) : (
    <Typography data-testid="not-supported">Action for Truck Gen {vehicle?.gen} Not Supported.</Typography>
  )
}

export default RequestForm
