import ErrorIcon from "@mui/icons-material/Error"
import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const VehicleFaultsBanner = ({ vehicleId }) => (
  <Box
    item
    sx={{
      backgroundColor: "#ffefd6",
      height: "56px",
      display: "flex",
      alignItems: "center",
      padding: ".7em",
      gap: ".5em",
    }}
  >
    <ErrorIcon sx={{ color: "#ff9d3d" }} />
    <Typography sx={{ fontSize: "14px" }} data-testid="action-warning">
      <strong>ACTIVE TYPE 2 FAULT -</strong> Vehicle has one or more
      <Link style={{ marginLeft: "5px" }} to={`/healthviz?veh_id=${vehicleId}`}>
        Active Type 2 Faults
      </Link>
    </Typography>
  </Box>
)

export default VehicleFaultsBanner
