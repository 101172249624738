import SortIcon from "@mui/icons-material/Sort"
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material"
import useSearchStore from "../../store/useSearchStore"
import "./Search.scss"
import SearchFilters from "./SearchFilters"
import SearchInput from "./SearchInput"

const VehicleSearch = () => {
  const { filter, search, sort } = useSearchStore((state) => state.vehicle)
  const updateVehicleParams = useSearchStore((state) => state.updateVehicleParams)

  return (
    <div className="search" data-testid="vehicle-search">
      <SearchInput
        search={search}
        updateSearch={(search) => updateVehicleParams({ search })}
        placeholder="Search Fleet"
      />
      <div className="searchbar__search-filters">
        <FormControl size="small" sx={{ minWidth: 96 }} variant="standard">
          <InputLabel id="sort-select-label">Sort</InputLabel>
          <Select
            data-testid="sort"
            data-dd-action-name="sort"
            value={sort.field}
            id="sort-select"
            onChange={(e) => updateVehicleParams({ sort: { field: e.target.value } })}
          >
            <MenuItem data-testid="sort-clear" data-dd-action-name="sort-clear" value="">
              Clear
            </MenuItem>
            <MenuItem data-testid="sort-vehicle-name" data-dd-action-name="sort-vehicle-name" value={"vehicle-name"}>
              Vehicle Name
            </MenuItem>
            <MenuItem data-testid="sort-vehicle-id" data-dd-action-name="sort-vehicle-id" value={"vehicle-id"}>
              Vehicle ID
            </MenuItem>
            <MenuItem
              data-testid="sort-active-inactive"
              data-dd-action-name="sort-active-inactive"
              value={"active-inactive"}
            >
              Active/Inactive
            </MenuItem>
          </Select>
        </FormControl>
        <IconButton
          size="small"
          className="searchbar__sort-icon"
          data-testid="sort-order"
          data-dd-action-name="sort-order"
          onClick={() => updateVehicleParams({ sort: { order: sort.order === "asc" ? "desc" : "asc" } })}
        >
          <SortIcon
            sx={
              sort.order === "asc" && {
                transform: "rotate(180deg) scaleX(-1)",
              }
            }
          />
        </IconButton>
        <SearchFilters filterOptions={filter} updateFilter={updateVehicleParams} />
      </div>
    </div>
  )
}

export default VehicleSearch
