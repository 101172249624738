import getToken from "@/framework/utils/api/getToken"
import { UserApiClient } from "@torc-robotics/mm-user-client"
import { toast } from "react-toastify"
import { APIGet } from "../../Api"

export interface TableauTokenResponse {
  token: string
}

function isTableauTokenResponse(response: unknown): response is TableauTokenResponse {
  return (
    typeof response === "object" &&
    response !== null &&
    "token" in response &&
    typeof (response as TableauTokenResponse).token === "string"
  )
}

const getTableauToken = async (): Promise<TableauTokenResponse> => {
  const apiPath = `/me/tableau-token`

  try {
    const response: unknown = await APIGet("UsersApi", apiPath, {})

    if (isTableauTokenResponse(response)) {
      return response
    }
    throw new Error("Unexpected response format")
  } catch (error) {
    toast.error("Error getting tableau token - Please contact support if the error continues.")
    console.error(`Error Getting tableau token:`, error)
    throw error
  }
}

export { getTableauToken }

const UserApi = new UserApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_USER_API_URL,
  TOKEN: getToken,
}).v1

export default UserApi
