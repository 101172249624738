import { useAuthenticator } from "@aws-amplify/ui-react"
import { Auth } from "aws-amplify"
import _ from "lodash"
import { useEffect, useState } from "react"

/**
 * Fetches all User claims from auth provider
 */
export const useUserClaims = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])
  const [userClaims, setUserClaims] = useState({})

  useEffect(() => {
    if (authStatus === "authenticated") {
      setUserClaims({
        email: user.signInUserSession.idToken.payload.email,
      })
    } else {
      setUserClaims({})
    }
  }, [authStatus])

  return userClaims
}

/**
 * Fetches geo credentials from AWS
 */
export const useGeoCreds = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])
  const [geoCreds, setGeoCreds] = useState()
  useEffect(() => {
    if (authStatus === "authenticated") {
      console.debug("fetching geo credentials -->")
      Auth.currentUserCredentials().then((result) => {
        setGeoCreds(result)
      })
    }
    return () => {
      setGeoCreds() // Clean on unmount to prevent memory leaks
    }
  }, [authStatus, user])

  return geoCreds
}

export const isBreakRoomUser = (userData) => {
  const userRoles = userData?.display_roles.map((roleData) => roleData.toLowerCase().replace(/[\W_]/g, ""))
  return userRoles.length === 1 && userRoles.includes("breakroom")
}

export const canReviewTasks = (userData) => {
  const userRoles = userData?.display_roles.map((roleData) => roleData.toLowerCase().replace(/[\W_]/g, ""))

  return userRoles.length !== 2 || !userRoles.includes("huboperator")
}

export const displayRole = (roleId, userData) => {
  const index = _.findIndex(userData?.roles, (id) => id === roleId)

  if (index !== -1) {
    return userData?.display_roles[index]
  }

  return ""
}

export const hasAnyPermissions = (user, requiredAttributes) => {
  return (
    requiredAttributes?.some((attribute) => user?.attributes?.includes(attribute)) || requiredAttributes.length === 0
  )
}

export function hasRole(userRoles = [], roleClaims = []) {
  if (!userRoles.length || !roleClaims.length) return false

  const normalizedUserRoles = userRoles.map((role) => role.toLowerCase().replace(/[\W_]/g, ""))

  return roleClaims.some((role) => normalizedUserRoles.includes(role.toLowerCase().replace(/[\W_]/g, "")))
}
