import { WebMercatorViewport } from "@deck.gl/core"
import { useEffect, useRef } from "react"
import useMapStore from "../../../store/useMapStore"
import useVehicleStore from "../../../store/useVehicleStore"

const useVehicleHover = () => {
  const setHoverInfo = useMapStore((state) => state.setHoverInfo)
  const { width, height } = useMapStore((state) => state.containerSize)
  const mapRef = useMapStore((state) => state.mapRef)

  // Fetch initial state
  const vehiclesRef = useRef(useVehicleStore.getState().vehicles.entities)

  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => useVehicleStore.subscribe((state) => (vehiclesRef.current = state.vehicles.entities)), [])

  const handleVehicleHover = (component, vehicleId) => {
    const vehicle = vehiclesRef.current[vehicleId]

    if (!vehicle) {
      setHoverInfo(null)
      return
    }

    const viewport = new WebMercatorViewport({
      width,
      height,
      longitude: mapRef.current?.getCenter().lng,
      latitude: mapRef.current?.getCenter().lat,
      zoom: mapRef.current?.getZoom(),
      padding: mapRef.current?.getPadding(),
    })

    const position = [vehicle.data?.coordinates?.longitude, vehicle.data?.coordinates?.latitude]
    const [x, y] = viewport.project(position)

    setHoverInfo({ object: vehicle, x: x, y: y, component })
  }

  return handleVehicleHover
}

export default useVehicleHover
