import { LinearProgress, Typography } from "@mui/material"
import { useGetVehiclesConnectivitySummaryFiltered } from "../../hooks/vehiclesV2Hooks"
import VehicleListCard from "./VehicleListCard"

function VehicleList() {
  const { vehiclesConnectionSummary, filteredVehiclesConnectionSummary, isGetVehiclesConnectionSummaryLoading } =
    useGetVehiclesConnectivitySummaryFiltered()

  return (
    <div data-testid="fleet-list">
      {isGetVehiclesConnectionSummaryLoading ? (
        <LinearProgress sx={{ m: "1rem" }} />
      ) : vehiclesConnectionSummary.length === 0 ? (
        <Typography variant="overline">No trucks available. Contact admins to resolve.</Typography>
      ) : filteredVehiclesConnectionSummary.length === 0 ? (
        <Typography variant="overline">No results found. Modify your search and try again.</Typography>
      ) : (
        filteredVehiclesConnectionSummary.map((vehicleConnection) => (
          <VehicleListCard key={vehicleConnection.vehicle_id} vehicleConnection={vehicleConnection} />
        ))
      )}
    </div>
  )
}

export default VehicleList
