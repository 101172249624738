import useGetVehicleConnectivityV2Query from "@/hooks/query/vehicle/useGetVehicleConnectivityV2Query"
import { useGetVehicleConnectivity, useGetVehicleId } from "@/hooks/vehiclesV2Hooks"
import { Container, LinearProgress, Typography } from "@mui/material"
import ServiceStatusTab from "./ServiceStatusTab"

const ServiceStatusDetails = ({ vehicle, vehicleConnectivity }) => {
  const vehicleId = useGetVehicleId() ?? vehicle?.vehicle_id
  const selectedConnection = useGetVehicleConnectivity(vehicleId) ?? vehicleConnectivity
  const serviceStatusDetailsQuery = useGetVehicleConnectivityV2Query(vehicleId)

  return (
    <Container>
      {serviceStatusDetailsQuery.isLoading ? (
        <LinearProgress sx={{ m: "1rem" }} />
      ) : serviceStatusDetailsQuery.isError ? (
        <Typography variant="overline">The services for vehicle &apos;{vehicleId}&apos; were not found</Typography>
      ) : (
        selectedConnection && <ServiceStatusTab vehicleConnectivity={selectedConnection} />
      )}
      {}
    </Container>
  )
}

export default ServiceStatusDetails
