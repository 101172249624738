import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material"
import * as React from "react"
import { IoMdWarning } from "react-icons/io"
import StatusTab from "./StatusTab"

const HealthDropdown = ({ title, connectionStatus, components, Icon, services, vehicleStatus }) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded)
  }
  const iconStyles = (color, fontSize) => ({
    color,
    fontSize: fontSize || "1.3em",
    marginTop: "0.125em",
  })

  return (
    <Box>
      <Accordion
        className="health-accordion"
        expanded={expanded}
        onChange={handleAccordionChange}
        TransitionProps={{
          timeout: 500,
          easing: "cubic-bezier(0.4, 0, 0.2, 1)",
        }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          sx={{ borderBottom: expanded ? "1px solid #d7d7d7" : "" }}
        >
          <Grid container>
            {connectionStatus === "ERROR" ? (
              <Grid item className="health-accordion__grid-icon-error">
                <Icon />
              </Grid>
            ) : connectionStatus === "WARN" ? (
              <Grid item className="health-accordion__grid-icon-warn">
                <Icon />
              </Grid>
            ) : (
              <Grid item className="health-accordion__grid-icon-ok">
                <Icon />
              </Grid>
            )}
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: " #d7d7d7" }} />
            <Grid item xs className="health-accordion__grid-text" sx={{ px: 1 }}>
              <Typography>{title}</Typography>

              {connectionStatus === "ERROR" ? (
                <IoMdWarning data-testid="error-icon" style={{ color: "#fa3751", fontSize: "1.5em" }} />
              ) : connectionStatus === "WARN" ? (
                <ErrorIcon fontSize="1.5em" data-testid="warn-icon" sx={iconStyles("#fa9a37")} />
              ) : (
                <CheckCircleIcon data-testid="ok-icon" sx={{ color: "#71bf5e", fontSize: "1.5em" }} />
              )}
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: " #d7d7d7" }} />
          </Grid>
        </AccordionSummary>

        <Slide in={expanded} direction="up" mountOnEnter unmountOnExit>
          <AccordionDetails spacing={1}>
            <Stack spacing={1}>
              {components.map(
                (value) =>
                  services && (
                    <StatusTab
                      key={value}
                      value={value}
                      componentStatus={
                        vehicleStatus
                          ? !services.find((service) => service?.service === value.toLocaleLowerCase())?.connected
                            ? connectionStatus === "WARN"
                              ? "WARN"
                              : "ERROR"
                            : "OK"
                          : services
                      }
                    />
                  ),
              )}
            </Stack>
          </AccordionDetails>
        </Slide>
      </Accordion>
    </Box>
  )
}
export default HealthDropdown
