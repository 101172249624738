import { NotesApiV2 } from "@/framework/utils/api/NotesAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionNotesQuery = (missionUuid: string) => {
  const getMissionNotesQuery = useQuery({
    queryKey: ["mission", missionUuid, "notes"],
    queryFn: async () => await NotesApiV2.getNotesV2NotesMissionsMissionUuidGet({ missionUuid }),
    enabled: !isEmpty(missionUuid),
    refetchInterval: 10000,
  })

  return getMissionNotesQuery
}

export default useGetMissionNotesQuery
