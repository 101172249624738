import _ from "lodash"
import { MSM_STATE } from "../Utils"
import { isMissionDataValid } from "./MissionDataValidity"

export const MISSION_STATE = {
  PREPARING: "PREPARING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  UNKNOWN: "UNKNOWN",
}

const MSM_MAP = {
  [MSM_STATE.SCHEDULED]: "Preparation",
  [MSM_STATE.PREPARING]: "Preparation",
  [MSM_STATE.REVIEWING]: "Review",
  [MSM_STATE.CLEARED]: "Clearance",
  [MSM_STATE.WAIT_ACCEPT]: "ADS Acceptance",
  [MSM_STATE.PENDING]: "Pending",
  [MSM_STATE.IN_PROGRESS]: "In Transit",
  [MSM_STATE.COMPLETED]: "Completed",
  [MSM_STATE.CANCELED]: "Canceled",
}

export function getMissionState(missionData) {
  if (isMissionDataValid(missionData)) {
    return MSM_MAP[_.get(missionData, "state")]
  }
  return ""
}

export function getVehicleIconState(missions) {
  if (missions?.length == 1) {
    return missions[0]["state"]
  } else {
    let highestStateValue = -1
    for (let i = 0; i < missions?.length; i++) {
      const missionStateValue = Object.values(MISSION_MAP).indexOf(missions[i].state)
      if (missionStateValue > highestStateValue) {
        highestStateValue = missionStateValue
      }
    }
    return Object.values(MISSION_MAP)[highestStateValue]
  }
}

export const MISSION_MAP = {
  0: "NOT_SCHEDULED",
  1: "SCHEDULED",
  2: "PREPARING",
  3: "REVIEWING",
  4: "CLEARED",
  5: "WAIT_ACCEPT",
  6: "ACCEPTED",
  7: "PENDING",
  8: "IN_PROGRESS",
  9: "COMPLETED",
}
