import CloseIcon from "@mui/icons-material/Close"
import WarningAmberTwoToneIcon from "@mui/icons-material/WarningAmberTwoTone"
import { Box, Card, CardContent, CardHeader, Dialog, Grid, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import _ from "lodash"
import { getFormattedDate } from "../../framework/Utils"
import { getMissionState } from "../../framework/utils/MissionState"

const MissionConflictDetails = ({ mission, closeHandle, conflicts = null, open }) => {
  const getMessage = (mission) => {
    let message = ""

    switch (mission.arrivalDeparture) {
      case "arrivals":
        message = "Arriving at the hub at " + getFormattedDate(mission?.tms_schedule_arrival_time)
        break

      case "departures":
        message = "Departing from the hub at " + getFormattedDate(mission?.tms_schedule_departure_time)
        break
    }
    return message
  }

  return (
    <Dialog onClose={closeHandle} open={open}>
      <div data-testid="mission-conflict-details" className="mission-conflict-details">
        <div className="mission-conflict-details-content">
          <Card data-testid={`${mission.load_id}-card-header`} key={mission.uuid} variant="outlined">
            <CardHeader
              className="mission-conflict-header"
              sx={{ padding: "3px" }}
              action={
                <IconButton aria-label="close-conflicts" onClick={closeHandle} data-testid="details-close">
                  <CloseIcon />
                </IconButton>
              }
              title="MISSION CONFLICT"
              titleTypographyProps={{ variant: "subtitle1" }}
            />
            <Box data-testid={`${mission.load_id}-card-location`} sx={{ border: 1, margin: 1, padding: 1 }}>
              <Typography variant="caption">Conflict Location</Typography>
              <Typography className="mission-list-title">{mission.start_display} HUB</Typography>
            </Box>
            <Box data-testid={`${mission.load_id}-card-description`} sx={{ padding: "8px" }}>
              <Grid container alignItems="flex-end" spacing={1}>
                <Grid item>
                  <Typography variant="caption" align="left">
                    There are scheduling conflicts with the following mission:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography data-testid="conflict-message" align="right">
                    {mission.load_id} {getMessage(mission)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {!_.isEmpty(conflicts.departures) && (
              <Card
                data-testid={`${mission.load_id}-card--departing-header`}
                key={"departure-" + mission.uuid}
                sx={{ padding: "8px" }}
              >
                <CardHeader
                  className="mission-conflict-departing"
                  sx={{ padding: "3px" }}
                  title={"DEPARTING FROM THE HUB"}
                  titleTypographyProps={{ variant: "subtitle1" }}
                  avatar={<WarningAmberTwoToneIcon color={"warning"} />}
                />
                <CardContent sx={{ padding: 0 }}>
                  <Grid container direction="row" columns={12} alignItems="center">
                    <Grid container sx={{ borderBottom: 1 }}>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">Mission ID</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">Mission Status</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2">Trip Status</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2">Expected Departure Time</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {conflicts.departures.map((conflict) => {
                    const onTimeStatus = null // getOnTimeStatus(conflict)
                    return (
                      <Grid
                        container
                        direction="row"
                        columns={12}
                        alignItems="center"
                        key={_.get(conflict, "_id") + "_conflict_a_" + _.get(conflict, "load_id")}
                      >
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">{_.get(conflict, "load_id")}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">{getMissionState(conflict)}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              data-testid="on-time-status-id"
                              className={_.get(onTimeStatus, "formatClass", "time-status success-main")}
                            >
                              <Typography variant="subtitle2">
                                {_.get(onTimeStatus, "statusString", "On Time") || "On Time"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2">
                              {getFormattedDate(conflict.tms_schedule_departure_time)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </CardContent>
              </Card>
            )}
            {!_.isEmpty(conflicts.arrivals) && (
              <Card
                data-testid={`${mission.load_id}-card--departing-header`}
                key={"arrival-" + mission.uuid}
                sx={{ padding: "8px" }}
              >
                <CardHeader
                  className="mission-conflict-departing"
                  sx={{ padding: "3px" }}
                  title={"ARRIVING AT THE HUB"}
                  titleTypographyProps={{ variant: "subtitle1" }}
                  avatar={<WarningAmberTwoToneIcon color={"warning"} />}
                />
                <CardContent sx={{ padding: 0 }}>
                  <Grid container direction="row" columns={12} alignItems="center">
                    <Grid container sx={{ borderBottom: 1 }}>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">Mission ID</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">Mission Status</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2">Trip Status</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2">Expected Arrival Time</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {conflicts.arrivals.map((conflict) => {
                    const onTimeStatus = null // getOnTimeStatus(conflict)
                    return (
                      <Grid
                        container
                        direction="row"
                        columns={12}
                        alignItems="center"
                        key={_.get(conflict, "_id") + "_conflict_a_" + _.get(conflict, "load_id")}
                      >
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">{_.get(conflict, "load_id")}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2">{getMissionState(conflict)}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              data-testid="on-time-status-id"
                              className={_.get(onTimeStatus, "formatClass", "time-status success-main")}
                            >
                              <Typography variant="subtitle2">
                                {_.get(onTimeStatus, "statusString", "On Time") || "On Time"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2">
                              {getFormattedDate(conflict.tms_schedule_arrival_time)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </CardContent>
              </Card>
            )}
          </Card>
        </div>
      </div>
    </Dialog>
  )
}

export default MissionConflictDetails
